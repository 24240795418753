import React from 'react'

import { Container, Row, Col, Card, Alert } from 'react-bootstrap'
import SignIn from './SignIn'
import { const2Msg, ConstantEquals, SIGN } from '../utils/constants'

/**
 * /Authページ
 * - Sign InとSign OutとForgot Passwordは等コンポーネントで出し分けをしている
 * 
 */
class Authenticator extends React.Component {
  // ステータス
  state = {
    // エラーメッセージ
    errorMessage: null,
    // 表示するコンポーネント
    currentState: 'showSignIn'
  }

  /**
   * currentState（showSignIn,showSignUp,showForgotPassword）の切り替え？
   * 
   * @param {*} currentState 
   */
  switchState = currentState => {
    this.setState({
      currentState
    })
  }

  /**
   * 引数errorMessageをステータスに更新する
   * 
   * @param {*} errorName 
   */
  updateError = (errorName) => {
    let msg = null;
    // エラーメッセージはerrorCodeで判断して出力する
    // ttps://qiita.com/yukitaka13-1110/items/99197466c1ba88f0f8d3#notauthorizedexception

    if (ConstantEquals(SIGN.COGNITO.NOT_AUTHORIZED_EXCEPTION, errorName) ) {
      msg = const2Msg(SIGN.COGNITO.NOT_AUTHORIZED_EXCEPTION)
    }else if (ConstantEquals(SIGN.COGNITO.USER_LAMBDA_VALICATION_EXCEPTION, errorName) ) {
      msg = const2Msg(SIGN.COGNITO.USER_LAMBDA_VALICATION_EXCEPTION)
    }else if (ConstantEquals(SIGN.COGNITO.USER_NOT_CONFIRMED_EXCEPTION, errorName) ) {
      msg = const2Msg(SIGN.COGNITO.USER_NOT_CONFIRMED_EXCEPTION)
    }else if (ConstantEquals(SIGN.COGNITO.PASSWORD_RESET_EXCEPTION, errorName) ) {
      msg = const2Msg(SIGN.COGNITO.PASSWORD_RESET_EXCEPTION)
    }else if(errorName != null){
      msg = "認証エラー"
    }

    this.setState(
      { errorMessage: msg }
    )
  }

  /**
   * currentStateの持ち方で表示するコンポーネントを変更させる
   * 
   * @returns 
   */
  render() {
    // stateからcurrentStateのみ取り出し
    const { currentState } = this.state
    return (
      <Container>
        <Row>
          <Col className="mb-4 mt-3">
            <Card>
            <Card.Body>
              <Card.Title className="text-center mb-4 mt-1">Sign in</Card.Title>
              <hr />
              {
                // エラーメッセージの有無
                this.state.errorMessage && (
                  <Alert variant="danger" className="text-center" >
                    {this.state.errorMessage}
                  </Alert>
                )
              }
              <div>
                { 
                  currentState === 'showSignIn' && <SignIn {...this.props} updateError={this.updateError} />  
                }
              </div>
            </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container >
    )
  }
}

export default Authenticator