import React, { useState, useEffect, useContext } from 'react'
import { I18n } from "aws-amplify";

import { Row, Col, Card, Form, ListGroup, Button, Spinner } from 'react-bootstrap'

import { PencilSquare, XLg } from 'react-bootstrap-icons';
import './licence.css';

import { putLicence } from './api/Licence'
import { putSuccessLicence as ToastPutSuccess, putErrorLicence as ToastPutError} from './../utils/toast';
import { isObject, isUndefined } from './../utils/utils'

import { ValidMessage, ValidLicenceType, ValidUuid } from './utils/Validate'
import { ConstantEquals, const2Msg, LICENCE, LICENCE_AUTH } from './../utils/constants'

export const RelationUUID = (props) => {

  // 編集モード
  const [edit, setEdit] = useState(false)
  // 更新中
  const [load, setLoad] = useState(false)
  // ライセンス情報
  const [data, setData] = useState({})
  // エラー
  const [errors, setErrors] = useState({})

  // 初回マウント時
  useEffect(() => {
    // Stateのメモリリーク対策
    let isMounted = true;

    // 取得した情報をparseする
    const parseData = parseLicence(props.data, LICENCE_AUTH.AUTH)

    if(!parseData.done){
      if (isMounted) setData({});
      return;
    }

    // 描画
    if (isMounted){
      setData(parseData.licence)
      setErrors(parseData.errors)
    }

    return () => { 
      isMounted = false 
    };
  }, []);

  // data以外初期化
  const init = () =>{
    setLoad(false)
    setEdit(false)
  }

  // パース：取得データ
  const parseLicence = (argLicenceData, argCurrentAuth) => {
    const parseData = {
      done: false,
      errors: {},
      licence:{
      }
    };

    const resValid = validateLicenceData(argLicenceData, argCurrentAuth);

    if(!resValid.isValid){
      parseData.errors = resValid.errors;
      return parseData;
    }

    // parseする
    parseData.licence = {
      type: argLicenceData.type,
      updatedAt: argLicenceData.updatedAt,
      uuid: argLicenceData.uuid
    }

    // 異常なし
    parseData.done = true;
    return parseData;
  }

  // 取得した種別とUUIDのチェック
  const validateLicenceData = (arg, argAuth) => {

    let cInput = arg;

    const res = {
      isValid: true, 
      errors: {}
    }

    if(isUndefined(arg)) return res;

    // UUID
    const vUuid = ValidUuid(cInput.uuid, argAuth)
    if (!isUndefined(cInput.uuid) && !ConstantEquals(vUuid, LICENCE.VALIDATE.CLEAR) ) {
      res.isValid = false;
      res.errors["uuid"] = ValidMessage(vUuid);
    }

    // ライセンス種別
    const vLicenceType = ValidLicenceType(cInput.type)
    if (!isUndefined(cInput.type) && !ConstantEquals(vLicenceType, LICENCE.VALIDATE.CLEAR) ) {
      res.isValid = false;
      res.errors["type"] = ValidMessage(vLicenceType);
    }

    return res;
  }

  // 更新：UUIDを空にする
  const onClickUnBind = () => {

    let param = {}
    param['id'] = props.licenceId
    param['uuid'] = "";

    // 更新する
    changeLicence(param)
  }

  // Put：ライセンス詳細
  const changeLicence = async (argParam) => {
    setLoad(true)

    try {
      // 一括登録と更新
      const updateLicenceDetail = await putLicence(argParam);
      // 結果の画面反映
      changeSuffix(updateLicenceDetail)
    } 
    catch (error) {
      ToastPutError()
      init()
    }

    return;
  }

  // 追加・更新後処理
  const changeSuffix = (argRes) => {
    const parseData = parseLicence(argRes.data.updateLicence, LICENCE_AUTH.UN_AUTH)

    if(!parseData.done){
      ToastPutError()
      init()
      return;
    }

    setData(parseData.licence)
    ToastPutSuccess()
    // 初期状態にする
    init()

    // 親コンポーネントに最終更新日の更新値を通知する
    props.doneUpdate(parseData.licence.updatedAt)
  }

  // 編集モードの切り替え
  const toggle = (arg) => {
    setEdit(arg)
  }

  return (
    <>
      <Card>
        <Card.Header>
          <Row>
            <Col sm={12} md={8}>
            { I18n.get( edit ? 
              'sub_title_licence_bind_information' : 
              'sub_title_licence_bind_information' )}
            </Col>
            <Col sm={12} md={4} className="d-flex align-items-center justify-content-end">
            { isObject(data) && data.uuid ? (
              edit ? ( 
                <XLg style={{cursor: 'pointer'}} color="gray" size={16} onClick={evt => toggle(false)} />
              ):( 
                <PencilSquare style={{cursor: 'pointer'}} color="gray" size={16} onClick={evt => toggle(true)} />
              )
            ):(<></>)}

            </Col>
          </Row>
        </Card.Header>
        { isObject(data) && data.uuid ? (
          <>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <Row>
                <Col sm={3}>
                  {const2Msg(data.type)}
                </Col>
                <Col sm={9}>
                  {data.uuid}
                </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </>
        ) : (
          <>
          <Card.Body>
            <Row>
              <Col sm={12} md={12}>
                {I18n.get('help_licence_un_bind')}
              </Col>
            </Row>
          </Card.Body>
          </>
        )}
        { edit && (
          <Card.Body>
            <div className="d-flex justify-content-end" >
              {
                load ? ( 
                  <Button variant="danger" disabled>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{' '}
                    {I18n.get('general_update_loading')}
                  </Button>
                ) : (
                  <Button
                    variant="danger" 
                    onClick={evt => onClickUnBind(evt)}
                    disabled={props.disabled}
                    type="submit" >
                      {I18n.get('label_licence_bind_un_bind_submit')}
                  </Button>
                )
              }
            </div>
          </Card.Body>
        )}
        </Card>
    </>
  )

}