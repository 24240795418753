import React, { useEffect } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { LICENCE_LIMIT } from '../../../../utils/constants'

export const Num = props =>{

  useEffect(() => {
  }, [])

  // ライセンス管理数上限まで描画
  const createLicenceLimitSelectItems = (argKey = null) => {
    let items = [];         
    for (let i = 0; i <= LICENCE_LIMIT.NUM; i++) {             
      items.push(<option key={argKey + "" + i} value={i}>{i}</option>);
    }
    return items;
  }  

  return (
      <Form.Group className="mb-3" controlId="formLicenceAcs">
        <Row>
          <Form.Label column sm={6} >
            {props.label}
          </Form.Label>
          <Col sm={6}>
          { props.edit ? (
            <Form.Select 
              aria-label={props.controlKey}
              disabled={props.load}
              defaultValue={props.num}
              onChange={props.onChange} >
              {createLicenceLimitSelectItems(props.label)}
            </Form.Select>
          ) : (
            <div className="col-form-label d-flex align-items-center justify-content-end" >
              {props.num}
            </div>
          )}
          </Col>
        </Row>
      </Form.Group>

  )
}