import React from 'react'
import { Auth } from 'aws-amplify'

import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Button, Spinner } from 'react-bootstrap'

import UserContext from './../UserContext'
import { SIGN } from './../utils/constants'

/**
 * サインイン
 */
class SignIn extends React.Component {
  // ステータス
  state = {
    // 入力： username
    username: '',
    // 入力： password
    password: '',
    // 認証応答： 二段階認証の有無
    showConfirmation: false,
    // 認証応答： CognitoUser
    user: {},
    // 認証中
    isLoading: false
  }

  // コンテキスト（参照用）
  static contextType = UserContext

  /**
   * stateの引数keyを引数valueに更新する
   * 
   * @param {*} key 
   * @param {*} value 
   */
  onChange = (key, value) => {
    this.props.updateError(null)
    this.setState({
      [key]: value
    })
  }

  /**
   * サインイン
   */
  signIn = (event) => {

    // formデフォルトの挙動をキャンセル
    event.preventDefault();
    // イベントの伝播を止める
    event.stopPropagation();

    this.setState({isLoading: true});

    // Validate
    const form = event.currentTarget;

    // formの内容を精査する
    if (!form.checkValidity()) {
      // クラスを付与する
      event.target.className += " was-validated";

      this.setState({isLoading: false});

      return;
    }

    // 履歴管理
    const { history } = this.props
    // ユーザ情報
    const { updateCurrentUser } = this.context

    /**
     * 認証： サインイン
     */
    Auth.signIn(this.state.username, this.state.password)
      .then(user => {
        // セッションがない
        if (!user.signInUserSession) {
          this.setState({ 
            user, showConfirmation: true,
            isLoading: false
          })
        } else {

          // 認証したユーザ情報を保存
          updateCurrentUser(user)

          this.setState({isLoading: false});

          // ダッシュボードへ遷移
          history.push({ 
            pathname:  '/dashboard', 
            state: { 
              notice: SIGN.IN.SUCCESS
            }
          })
        }
      })
      .catch(err => {
        this.props.updateError(err.name)
        this.setState({isLoading: false});
      })

  }

  /**
   * 描画
   * @returns 
   */
  render() {
    return (
      <>
        {
          // サインイン画面
          !this.state.showConfirmation && (
            <Form noValidate className="needs-validation" onSubmit={this.signIn} >
              <Form.Group className="mb-3" controlId="formUserId">
                <Form.Label>ユーザID</Form.Label>
                <Form.Control 
                  required
                  type="name" 
                  placeholder="ユーザIDを入力" 
                  disabled={this.state.isLoading}
                  onChange={evt => this.onChange('username', evt.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formPassword">
                <Form.Label>パスワード</Form.Label>
                <Form.Control
                  required
                  type="password" 
                  placeholder="パスワードを入力"
                  disabled={this.state.isLoading}
                  onChange={evt => this.onChange('password', evt.target.value)}
                />
              </Form.Group>

              {
                this.state.isLoading ? (
                  <Button variant="primary" disabled>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{' '}
                    {`Loading...`}
                  </Button>
                ) : (
                  <Button 
                    variant="primary" 
                    disabled={!this.state.username || !this.state.password}
                    type="submit"
                  >
                    サインイン
                  </Button>
                )
              }
            </Form>
          )
        }
        {
          // 認証されていない場合
          // - 本システムでは二段階認証を行わない（管理ユーザは全て本部で操作を行う）
          // - そのため、AWS CLIから二段階認証の認証コマンドを実行し、正式なユーザとして通す
          this.state.showConfirmation && (
            <>
              <h3>不正なユーザです</h3>
              <Button 
                variant="primary"
                onClick={(evt) => this.setState({showConfirmation: false})} >
                  戻る
              </Button>
            </>
          )
        }
      </>
    )
  }
}

export default SignIn
