import React, { useContext } from 'react'
import { I18n } from 'aws-amplify'

import { parseAsMoment } from './../utils/date'
import { UserUpdatedAtContext } from './context/UserUpdatedAt'

export const UpdatedAt = (props) => {
  const userUpdatedAt= useContext(UserUpdatedAtContext)

  return (

      !userUpdatedAt ? (
        <>{I18n.get('general_loading')}</>
      ) : (
        <>
          <span>{I18n.get('general_latest_update')}：</span>
          <span>{parseAsMoment(userUpdatedAt).format('YYYY年MM月DD日 HH:mm')}</span>
        </>
      )

  );
}