import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { I18n } from "aws-amplify";

import { ClipboardCheck } from 'react-bootstrap-icons';

import { Overlay, Tooltip } from 'react-bootstrap'

import { isNull, isUndefined } from './../../../utils/utils'
import { const2Msg } from './../../../utils/constants'
import { parseAsMoment } from './../../../utils/date'

/**
 * レコード
 * 
 * @param {*} props 
 * @returns 
 */
 export const Record = (props) => {

  const [data, setData] = useState({})
  const [tooltips, setTooltips] = useState(false);
  const target = useRef(null);

  useEffect(()=>{
    // Stateのメモリリーク対策
    let isMounted = true;
    if(isMounted) setData(props.licence)
    return () => { 
      isMounted = false 
    };
  },[])

  // ライセンスキーのコピー
  const onCopy = (argLicenceKey) => {
    navigator.clipboard.writeText(argLicenceKey)
      .then(function() {
        setTooltips(true)
        setTimeout(() => {
          setTooltips(false)
        }, 3000);
      }, function(err) {
        
      });
  }

  // ライセンスページへ遷移
  const goLicencePage = (argLicence) => {
    return {
      pathname: `/licence/${argLicence.id}`,
      state: { userId: argLicence.userId }
    }
  }

  return (
    <tr>
      <td className="text-center">
      {
        props.licence.active ? (
          <span className="badge bg-success">{I18n.get('general_active')}</span>
        ) : (
          <span className="badge bg-danger">{I18n.get('general_inactive')}</span>
        )
      }
      </td>
      <td>
        <Link to={goLicencePage(props.licence)} >
          {props.licence.licenceKey}
        </Link>{' '}
        <ClipboardCheck 
          color="gray" 
          size={14} 
          ref={target} 
          onClick={() => onCopy(props.licence.licenceKey)} />
        <Overlay target={target.current} show={tooltips} placement="right">
          <Tooltip id={`tooltip-${props.index}`} >
            コピーしました
          </Tooltip>
        </Overlay>
      </td>
      <td>
        {props.licence.uuid}
      </td>
      <td>
        {const2Msg(props.licence.period)}
      </td>
        { props.licence.subscriptionExpiryDateAt ? (
          <td>
            {parseAsMoment(props.licence.subscriptionExpiryDateAt).format('YYYY年MM月DD日')}
          </td>
        ) : (
          <td className="text-center">
          {I18n.get('help_licence_period_perpetual')}
          </td>
        ) }
      <td>
        {parseAsMoment(props.licence.maintenancePeriod).format('YYYY年MM月DD日')}
      </td>
      <td>
        {parseAsMoment(props.licence.updatedAt).format('YYYY年MM月DD日 HH:mm')}
      </td>
      <td>
        {parseAsMoment(props.licence.createdAt).format('YYYY年MM月DD日 HH:mm')}
      </td>
    </tr>
   );
};