// Update: 連絡先
export const updateUserContact = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      owner
      username
      mailaddress
      companyName
      active
      updatedAt
    }
  }
`;

// Update: アクティブ
export const updateUserActive = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      owner
      active
      updatedAt
    }
  }
`;

// Update: ユーザ名
export const updateUserName = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      owner
      username
      updatedAt
    }
  }
`;
