import React, { useState, useEffect, useContext } from 'react'
import { I18n } from "aws-amplify";
import { LicenceLimitContext } from './../../context/LicenceLimit'

import { postSuccessLicence as ToastPostSuccess, putErrorLicence as ToastPostError} from './../../../utils/toast';
import { ConstantEquals, LICENCE, LICENCE_PERIOD, LICENCE_TYPE, SORT_DIRECTION, MAINTENANCE_PLUS } from './../../../utils/constants'
import { getDateNextY, getDateNextM, getDateNextD } from './../../../utils/date'
import { ValidMessage, ValidLicencePeriod, ValidSubscriptionExpiryDateAt, ValidLicenceKey, ValidAcsVersionId, ValidMaintenancePeriod } from './utils/Validate'

import { API } from 'aws-amplify'
import { postLicence } from './api/Licence'

import { Button, Spinner, Modal, Form, Alert } from 'react-bootstrap'
import { LicenceKey } from './form/LicenceKey'
import { LicencePeriod as LicencePeriodForm } from './form/LicencePeriod'
import { LicenceVersion as LicenceVersionForm } from './form/LicenceVersion'
import { SubscriptionExpiryDateAt } from './form/SubscriptionExpiryDateAt'
import { MaintenancePeriod } from './form/MaintenancePeriod'
import { MaintenancePeriodPlus } from './form/MaintenancePeriodPlus'

export const Add = (props) => {

  // デフォルト日付
  const defaultDate = getDateNextY(1)

  const [show, setShow] = useState(false)
  const [formLoad, setFormLoad] = useState(false)
  const [apiLoad, setApiLoad] = useState(false)
  const [apiError, setApiError] = useState(null)

  const [input, setInput] = useState({})
  const [errors, setErrors] = useState({})

  const [perpetual, setPerpetual] = useState(true)
  const [plus, setPlus] = useState(false)
  const [applyDate, setApplyDate] = useState(defaultDate)

  // []指定でマウント時1回のみ発火する
  useEffect(() => {
    setInput({
      'userId': props.userId,
      'type': LICENCE_TYPE.USE,
      'period': LICENCE_PERIOD.PERPETUAL,
      'subscriptionExpiryDateAt': defaultDate,
      'maintenancePeriod': defaultDate,
      'licenceKey': "",
      'uuid': "",
      'active': true
    })
  },[]);

  // 初期化
  const init = ()=>{
    setFormLoad(false)
    setApiLoad(false)
    setApiError(null)
    setInput({
      'userId': props.userId,
      'type': LICENCE_TYPE.USE,
      'period': LICENCE_PERIOD.PERPETUAL,
      'subscriptionExpiryDateAt': defaultDate,
      'maintenancePeriod': defaultDate,
      'licenceKey': "",
      'uuid': "",
      'active': true
    })
    setErrors({})
    setPerpetual(true)
    setApplyDate(defaultDate)
    setPlus(false)
    setShow(false)
  }

  const modalShow = (argShow) => {
    if(formLoad || apiLoad) return;
    setShow(argShow)
  }

  const userInputChange = (key, value) => {
    let cInput = input
    cInput[key] = value

    // エラーの一時解除
    let cErrors = errors
    if(typeof cErrors[key] !== "undefined"){
      delete cErrors[key]
    }

    setInput(cInput)
    setErrors(cErrors)
  }

  // Call API: ライセンスキー取得
  const createLicenceKey = async() => {
    setApiError("")

    let cInput = input
    if(typeof cInput['licenceKey'] !== "undefined")
      delete cInput['licenceKey']

    setInput(cInput)
    setApiLoad(true)

    const lKey = await API.get('apiLicenceKeyGene','/licencekeygene', {})

    if(lKey.data.responseStatus === 200){
      userInputChange('licenceKey', lKey.data.response.key)
    }
    // APIエラー
    else{
      const errMsg = I18n.get('error_api_licence_key')
      setApiError(errMsg)
      userInputChange('licenceKey', "")
    }

    setApiLoad(false)
  }

  // submit: ライセンス登録
  const submitInsertLicence = (event) => {
    // formデフォルトの挙動をキャンセル
    event.preventDefault();
    // イベントの伝播を止める
    event.stopPropagation();

    setApiError(null)
    setFormLoad(true)

    const resValid = inputLicenceValidate();
    if(!resValid.isValid){
      setErrors(resValid.errors)
      setFormLoad(false)
      return;
    }

    // 追加
    createLicence(input)
  }

  // Validate: ライセンス登録
  const inputLicenceValidate = () => {

    let cInput = input;

    const res = {
      isValid: true, 
      errors: {}
    }

    // ライセンス購入種別
    const vLicencePeriod = ValidLicencePeriod(cInput.period)
    if (!ConstantEquals(vLicencePeriod, LICENCE.VALIDATE.CLEAR) ) {
      res.isValid = false;
      res.errors["period"] = ValidMessage(vLicencePeriod);
    }

    // ライセンス期限
    const vSubscriptionExpiryDateAt = ValidSubscriptionExpiryDateAt(cInput.subscriptionExpiryDateAt, cInput.period)
    if (!ConstantEquals(vSubscriptionExpiryDateAt, LICENCE.VALIDATE.CLEAR) ) {
      res.isValid = false;
      res.errors["subscriptionExpiryDateAt"] = ValidMessage(vSubscriptionExpiryDateAt);
    }

    // 保守期限
    const vMaintenancePeriod = ValidMaintenancePeriod(cInput.maintenancePeriod, cInput.period, cInput.subscriptionExpiryDateAt)
    if (!ConstantEquals(vMaintenancePeriod, LICENCE.VALIDATE.CLEAR) ) {
      res.isValid = false;
      res.errors["maintenancePeriod"] = ValidMessage(vMaintenancePeriod);
    }

    // ライセンスキー
    const vLicenceKey = ValidLicenceKey(cInput.licenceKey)
    if (!ConstantEquals(vLicenceKey, LICENCE.VALIDATE.CLEAR) ) {
      res.isValid = false;
      res.errors["licenceKey"] = ValidMessage(vLicenceKey);
    }

    return res;
  }

  // 登録： ライセンス
  const createLicence = async(argInput) => {

    if(!props.limitCheck(argInput.type)){
      const errMsg = I18n.get('error_form_licence_limit_overflow');
      setApiError(errMsg)
      setFormLoad(false)
      return
    }

    // 『買い切り』の場合：
    if(ConstantEquals(LICENCE_PERIOD.PERPETUAL, argInput[LICENCE.LABEL.PERIOD])){
      // - subscriptionExpiryDateAtを空として設定する
      argInput[LICENCE.LABEL.SUBSCRIPTION_EXPIRY_DATE_AT] = '';
      // - 保守期限の加算チェックがオンの場合、加算する
      if(plus)
        argInput[LICENCE.LABEL.MAINTENANCE_PERIOD] = 
        getDateNextD(argInput[LICENCE.LABEL.MAINTENANCE_PERIOD],  MAINTENANCE_PLUS.PLUS)
    }

    // 最新のバージョンIDを適用する
    argInput[LICENCE.LABEL.ACS_VERSION_ID] = props.verId

    try{
      const result = await postLicence(argInput)

      init();
      ToastPostSuccess();

      // ライセンスの取得
      props.fetchLicences()

    }
    catch(err){
      console.log(err)
      const apiErrMsg = I18n.get('error_api_create_licence')
      setApiError(apiErrMsg)
      setFormLoad(false)
    }

  }

  return (
    <>
      <Button variant="primary" onClick={() => modalShow(true)}>
        {I18n.get('component_title_licence_add')}
      </Button>


        <LicenceAddModal
          show={show}
          formLoad={formLoad}
          apiLoad={apiLoad}
          apiError={apiError}
          input={input}
          errors={errors}
          perpetual={perpetual}
          setPerpetual={setPerpetual}
          plus={plus}
          setPlus={setPlus}
          applyDate={applyDate}
          setApplyDate={setApplyDate}
          count={props.count}
          onHide={() => modalShow(false)}
          onSubmit={(evt) => submitInsertLicence(evt)}
          onClick={() => createLicenceKey()}
          onChange={(key, value) => userInputChange(key, value)}
        />

    </>
  )
};

const LicenceAddModal = (props) => {

  const minDate = getDateNextY(0)
  const maxDate = getDateNextY(5)

  // 初期値セット
  useEffect(() => {
    
  }, []);

  // 入力変更
  const onChangeModal = (key, value) => {
    props.onChange(key, value);
  }

  // 入力変更：購入種別
  const onChangeModalByPeriod = (value) => {

    props.setApplyDate(
      getDateNextD(props.input.maintenancePeriod, 
        props.plus ? MAINTENANCE_PLUS.PLUS : MAINTENANCE_PLUS.DEFAULT))

    props.setPerpetual(ConstantEquals(LICENCE_PERIOD.PERPETUAL, value))
    props.onChange('period', value);
  }

  // 入力変更：保守期間
  const onChangeModalByMaintenancePeriod = (value) => {
    onChangeModal('subscriptionExpiryDateAt', value)
    onChangeModal('maintenancePeriod', value)
    props.setApplyDate(
      getDateNextD(value, props.plus ? 
        MAINTENANCE_PLUS.PLUS : MAINTENANCE_PLUS.DEFAULT))
  }

  // 入力変更：保守期限の加算
  const onChangeModalByPlus = (value) => {
    props.setPlus(value)

    // 表示を変更する
    props.setApplyDate(getDateNextD(props.input.maintenancePeriod, value ? 
      MAINTENANCE_PLUS.PLUS : MAINTENANCE_PLUS.DEFAULT))
  }

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {I18n.get('component_title_licence_add')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate> 
            { props.apiError ? 
              (
                <Alert variant="danger" >
                  {props.apiError}
                </Alert>
              ) : (
                <></>
              )
            }

            <LicenceKey 
                edit={true}
                required
                input={props.input.licenceKey} 
                error={props.errors.licenceKey}
                value={props.input.licenceKey}
                apiLoad={props.apiLoad}
                onClick={props.onClick} />

              <LicencePeriodForm 
                  edit={true}
                  load={props.formLoad}
                  help=""
                  required
                  controlKey={LICENCE.LABEL.PERIOD}
                  label={I18n.get('label_licence_period')}
                  value={props.input.period}
                  error={props.errors[LICENCE.LABEL.PERIOD]}
                  onChange={evt => onChangeModalByPeriod(evt.target.value)} /> 

            { props.perpetual ? (
              <></>
            ) : (
              <SubscriptionExpiryDateAt 
                edit={true}
                label={`${I18n.get('label_licence_subscription_expiry_date_at')}/${I18n.get('label_licence_maintenance_period')}`}
                required
                input={props.input.subscriptionExpiryDateAt} 
                error={props.errors.subscriptionExpiryDateAt} 
                load={props.formLoad}
                onChange={(value) => {
                  onChangeModal('subscriptionExpiryDateAt', value)
                  onChangeModal('maintenancePeriod', value) 
                  props.setApplyDate(value) }} 
                minDate={minDate} 
                maxDate={maxDate} />
            )}

            { !props.perpetual ? (
              <></>
            ) : (
              <>
                <MaintenancePeriod
                  edit={true}
                  load={props.formLoad}
                  label={I18n.get('label_licence_maintenance_period')}
                  required
                  input={props.input.maintenancePeriod} 
                  error={props.errors.maintenancePeriod} 
                  onChange={(value) => onChangeModalByMaintenancePeriod(value)} 
                  minDate={minDate} 
                  maxDate={maxDate} />

                <MaintenancePeriodPlus 
                  load={props.formLoad}
                  label={I18n.get('label_licence_maintenance_period_plus')}
                  input={props.plus}
                  applyMaintenanceDate={props.applyDate}
                  onChange={evt => onChangeModalByPlus(evt)} />
              </>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <>
            {
              props.formLoad ? ( 
                <>
                  <Button variant="primary" disabled>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true" />{' '}
                    {I18n.get('general_insert_loading')}
                  </Button>{' '}
                  <Button variant="secondary" disabled>
                    {I18n.get('general_cancel')}
                  </Button>
                </>
              ) : props.apiLoad ? (
                <>
                  <Button variant="primary" disabled>
                    {I18n.get('general_insert')}
                  </Button>{' '}
                  <Button variant="secondary" disabled>
                    {I18n.get('general_cancel')}
                  </Button>
                </>
              ) : (
                <>
                  <Button variant="primary" onClick={props.onSubmit}>
                    {I18n.get('general_insert')}
                  </Button>{' '}
                  <Button variant="secondary" onClick={props.onHide}>
                    {I18n.get('general_cancel')}
                  </Button>
                </>
              )
            }
          </>
        </Modal.Footer>
      </Modal>
    </>
  );
}