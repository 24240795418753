import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { I18n } from 'aws-amplify'
import { deleteSuccessUser as ToastDeleteSuccess, deleteErrorUser as ToastDeleteError} from '../utils/toast';

import { Button, Spinner, Modal, Form, Alert } from 'react-bootstrap'

import { 
  getListLicence, 
  getListLicenceLimit, 
  getListLicenceBind, 
  logicalDeleteLicenceBind, 
  logicalDeleteLicence,
  logicalDeleteLicenceLimit,
  physicalDeleteUser } from './api/DeleteUser'

import { const2Msg, ConstantEquals, LICENCE_TYPE } from './../utils/constants'
import { isNull, isUndefined } from './../utils/utils'

export const Delete = (props) => {
  const history = useHistory();

  // 入力値
  const CONFIRM_DELETE_WORD = "delete";

  const [show, setShow] = useState(false)
  const [load, setLoad] = useState(false)
  const [allow, setAllow] = useState(true)

  // 初回マウント時
  useEffect(() => {
    // Stateのメモリリーク対策
    let isMounted = true;
    // if(isMounted) 
    return () => { 
      isMounted = false 
    };
  }, []);

  // data以外初期化
  const init = ()=>{
    setShow(false)
    setLoad(false)
    setAllow(true)
  }

  /**
   * モーダルを閉じる
   * 
   * @param {*} argShow 
   * @returns 
   */
  const close = (argShow) => {
    if(load) return;
    setShow(argShow)
    setAllow(true)
  }

  // 更新： アクティブ
  const onClickDeleteUser = async (event) => {
    setLoad(true)

    // 段階的に削除していく
    // Note: これこそリゾルバーでやるべき
    try {
      // ライセンス上限取得
      const resLimits = await getListLicenceLimit(props.userId)
      const parseLimits = parseLicenceLimits(resLimits.data.listLicenceLimits.items)
      if(!parseLimits.done) throw('hoge')
      // ライセンス
      const resLicences = await getListLicence(props.userId)
      const parseLicenceIds = parseLicences(resLicences.data.listLicences.items)
      if(!parseLicenceIds.done) throw('hoge')
      // ライセンスのIDの分だけBindsを得る
      let bindIdsPromises = []
      for (let i = 0; i < parseLicenceIds.licenceIds.length; i++) {
        bindIdsPromises.push(getListLicenceBind(parseLicenceIds.licenceIds[i]))
      }
      const resBinds = await Promise.all(bindIdsPromises);
      const parseLicenceBindIds = parseLicenceBinds(resBinds)
      // BindsをすべてUpdate
      let bindUpdatePromises = []
      for (let i = 0; i < parseLicenceBindIds.licenceBindIds.length; i++) {
        bindUpdatePromises.push(logicalDeleteLicenceBind(parseLicenceBindIds.licenceBindIds[i]))
      }
      await Promise.all(bindUpdatePromises);
      // ラインセスをすべてUpdate
      let licenceUpdatePromises = []
      for (let i = 0; i < parseLicenceIds.licenceIds.length; i++) {
        licenceUpdatePromises.push(logicalDeleteLicence(parseLicenceIds.licenceIds[i]))
      }
      await Promise.all(licenceUpdatePromises);
      // ラインセスを上限Update
      let limitUpdatePromises = []
      for (let i = 0; i < parseLimits.licenceLimitIds.length; i++) {
        limitUpdatePromises.push(logicalDeleteLicenceLimit(parseLimits.licenceLimitIds[i]))
      }
      await Promise.all(limitUpdatePromises);
 
      // ユーザを物理削除
      await physicalDeleteUser({ id: props.userId });

      // 結果の画面反映
      resultSuffix()

    } 
    catch (error) {
      console.log(error)
      ToastDeleteError()
      init()
    }

  }

  // パース：ライセンス上限
  const parseLicenceLimits = (argLimits) =>{

    const parseData = {
      done: false,
      licenceLimitIds: []
    };

    // No Dataの場合
    if(argLimits.length === 0){
      parseData.done = true;
      return parseData;
    }

    parseData.licenceLimitIds = argLimits.map((val) => {
      return val.id
    })
    parseData.done = true;
    return parseData;
  }

  // パース：ライセンス
  const parseLicences = (argLicences) =>{

    const parseData = {
      done: false,
      licenceIds: []
    };

    // No Dataの場合
    if(argLicences.length === 0){
      parseData.done = true;
      return parseData;
    }

    parseData.licenceIds = argLicences.map((val) => {
      return val.id
    })
    parseData.done = true;
    return parseData;
  }

  // パース：ライセンス認証履歴
  const parseLicenceBinds = (argLicenceBindResponses) =>{
    
    const parseData = {
      done: false,
      licenceBindIds: []
    };

    // No Dataの場合
    if(argLicenceBindResponses.length === 0){
      parseData.done = true;
      return parseData;
    }

    parseData.licenceBindIds = argLicenceBindResponses.flatMap((val) => {
      if((val.data.LicenceBindByLicenceId.items).length === 0) return;
      return (val.data.LicenceBindByLicenceId.items).map((val) => {
        return val.id
      })
    }).filter((val) => {
      return !isUndefined(val)
    })

    parseData.done = true;
    return parseData;
  }

  // 更新後処理
  const resultSuffix = () => {
    // Toastを出して
    ToastDeleteSuccess()

    // ダッシュボード画面に遷移
    history.push(({ 
      pathname:  '/dashboard'
    }))
  }

  // ボタンの有効化
  const confirmInputChange = (arg) =>{
    setAllow(arg === CONFIRM_DELETE_WORD ? false : true)
  }

  return (
    <>
      <Button variant="danger" onClick={() => close(true)}>
        {I18n.get('sub_title_user_delete')}
      </Button>

      <UserDeleteModal
        show={show}
        allow={allow}
        load={load}
        word={CONFIRM_DELETE_WORD}
        onClick={() => onClickDeleteUser()}
        onHide={() => close(false)}
        onChange={(argWord) => confirmInputChange(argWord)}
      />

    </>
  )
}

/**
 * モーダル： ユーザ削除
 * @param {*} props 
 * @returns 
 */
const UserDeleteModal = (props) => {

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {I18n.get('sub_title_user_delete')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="warning" >
            {I18n.get('alert_user_delete_note')}
          </Alert>
          <p>
            {`${I18n.get('general_confirm_prefix')}
              ${props.word}
              ${I18n.get('general_confirm_suffix')}`}
          </p>
          <Form.Group className="mb-3" controlId="formConfirm">
            <Form.Control 
            type="text" 
            disabled={props.load}
            placeholder={`Enter ${props.word}`}
            onChange={(evt) => props.onChange(evt.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <>
            {
              !props.load ? ( 
                <>
                <Button variant="danger" 
                  onClick={props.onClick} 
                  disabled={props.allow}
                  >
                  {I18n.get('form_user_delete')}
                </Button>
                {' '}
                <Button variant="secondary" onClick={props.onHide}>
                  {I18n.get('general_cancel')}
                </Button>
                </>
              ) : (
                <>
                  <Button variant="danger" disabled>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true" />{' '}
                    {I18n.get('form_user_delete')}
                  </Button>
                  {' '}
                  <Button variant="secondary" disabled>
                    {I18n.get('general_cancel')}
                  </Button>
                </>
              )
            }
          </>
        </Modal.Footer>
      </Modal>
    </>
  );
}