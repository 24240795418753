import React, { useState, useEffect, useImperativeHandle } from 'react'
import { putSuccessUserDetail as ToastPutSuccess, putErrorUserDetail as ToastPutError} from '../utils/toast';

import { putUserName } from './api/User'

import { Row, Col, Form, Button, Spinner, Placeholder } from 'react-bootstrap'
import { PencilSquare } from 'react-bootstrap-icons';
import { ConstantEquals, USER } from '../utils/constants'


import { ValidMessage, ValidUsername } from './Validate'

export const Username = (props) => {

  // 編集モード
  const [edit, setEdit] = useState(false)
  // 更新中
  const [load, setLoad] = useState(false)
  // ユーザ名
  const [data, setData] = useState(null)
  // 入力：変更
  const [input, setInput] = useState({})
  // 入力：変更検知
  const [change, setChange] = useState(false)
  // 入力：エラー
  const [errors, setErrors] = useState({})

  // 初回マウント時
  useEffect(() => {
    // Stateのメモリリーク対策
    let isMounted = true;

    if (isMounted) setData(props.username)

    return () => { 
      // マウントされていない場合はフラグをクリーンにする（setStateさせない）
      isMounted = false 
    };
  }, []);

  // data以外初期化
  const init = ()=>{
    setEdit(false)
    setLoad(false)
    setInput({})
    setChange(false)
    setErrors({})
  }

  // パース：取得データ
  const parse = (argUser) =>{
    const parseData = {
      done: false,
      errors: {},
      updatedAt: "",
      username: null
    };

    // No Dataの場合
    if(argUser.length === 0){
      parseData.done = true;
      return parseData;
    }

    Object.entries(argUser).map(([key, value]) => {
      // ユーザ名
      if(ConstantEquals(key, USER.LABEL.USERNAME)){
        const vUsername = ValidUsername(value)
        if (!ConstantEquals(vUsername, USER.VALIDATE.CLEAR) ) {
          parseData.errors[USER.LABEL.USERNAME] = ValidMessage(vUsername);
        }else{
          parseData.username = argUser.username;
        }
      }

      // 更新日
      if(ConstantEquals(key, USER.LABEL.UPDATED_AT)){
        parseData.updatedAt = argUser.updatedAt;
      }

    })

    parseData.done = true;

    return parseData;
  }
  
  // 入力変更
  const inputChange = (key, value) => {
    let cInput = input
    cInput[key] = value

    // エラーの一時解除
    let cErrors = errors
    if(typeof cErrors[key] !== "undefined"){
      delete cErrors[key]
    }

    setInput(cInput)
    setErrors(cErrors)
    setChange(true)
  }

  // 更新
  const onSubmitClick = (event) => {

    if(!Object.keys(input).length){
      return;
    }

    const valid = inputValidate()
    if(!valid.isValid){
      setErrors(valid.errors)
      return;
    }

    let param = JSON.parse(JSON.stringify(input))
    param['id'] = props.userId

    // 更新する
    changeUser(param)
  }

  // 入力確認：ユーザ名
  const inputValidate = () =>{

    const res = {
      isValid: true,
      errors: {}
    };

    // ユーザ名
    if(input.hasOwnProperty(USER.LABEL.USERNAME)){
      const vUsername = ValidUsername(input.username)
      if (!ConstantEquals(vUsername, USER.VALIDATE.CLEAR) ) {
        res.isValid = false;
        res.errors[USER.LABEL.USERNAME] = ValidMessage(vUsername);
      }
    }

    return res;
  }

  // Put： ユーザ名
  const changeUser = async (argParam) => {
    setLoad(true)

    try {
      // 一括登録と更新
      const updateUserDetail = await putUserName(argParam);
      
      // 結果の画面反映
      changeSuffix(updateUserDetail)
    } 
    catch (error) {
      ToastPutError()
      init()
    }

    return;
  }

  // 追加・更新後処理
  const changeSuffix = (argRes) => {
    const parseData = parse(argRes.data.updateUser)
    if(!parseData.done){
      ToastPutError()
      init()
      return;
    }

    setData(parseData.username)
    ToastPutSuccess()
    // 初期状態にする
    init()

    // 親コンポーネントに最終更新日の更新値を通知する
    props.doneUpdate(parseData.updatedAt)
  }

  // 編集モードの切り替え
  const toggle = (arg) => {
    setInput({})
    setChange(false)
    setErrors({})
    setEdit(arg)
  }

  return (
    !data ? (
      <Row>
        <Col xs={12} md={12} className="mb-4 mt-3" >
          <Placeholder as="h3" animation="glow">
            <Placeholder.Button xs={10} md={10} variant="secondary" />
          </Placeholder>
        </Col>
      </Row>
    ) : (
      <>
        <Row>
          <Col xs={12} md={12} className="mt-3 mb-2">
            <h3 className="text-break">
              { 
                edit ? (
                  <>
                    <Row>
                      <Col xs={8} md={8} className="mt-3 mb-2">
                        <Form.Control size="lg" type="text" 
                          defaultValue={data} 
                          placeholder="ユーザ名を入力" 
                          disabled={load} 
                          onChange={evt => inputChange(USER.LABEL.USERNAME, evt.target.value)}
                        />
                        <div className="text-danger">
                          {errors.username}
                        </div>
                      </Col>
                      <Col xs={4} md={4} className="mt-3 mb-2">
                        {
                          load ? (
                            <Button variant="primary" disabled>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />{' '}Loading...
                            </Button>
                          ) : (
                            <Button variant="primary" 
                              disabled={!change} 
                              onClick={() => onSubmitClick()}>
                              変更
                            </Button>
                          )
                        }
                          {' '}
                          <Button variant="secondary" 
                            disabled={load} 
                            onClick={evt => toggle(false)} >
                              キャンセル
                          </Button>
                      </Col>
                    </Row>
                  </>
                  ) : (
                  <>
                    {data}{' '}
                    <PencilSquare style={{cursor: 'pointer'}} color="gray" size={16} onClick={evt => toggle(true)} />
                  </>
                ) 
              }
            </h3>
          </Col>
        </Row>
      </>
    )
  );
}