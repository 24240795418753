// 時刻
import 'moment/locale/ja'

import React, { useState, useEffect } from 'react'
import { I18n } from "aws-amplify";
import { Row, Form } from 'react-bootstrap'
import { parseAsMoment } from './../../utils/date'

export const MaintenancePeriod = props =>{

  const [startDate, setStartDate] = useState("");

  useEffect(() => {
    if(!props.input) return;
    setStartDate(props.input)
  }, [])

  const handleChange = (argDate) => {
    setStartDate(argDate)
    props.onChange(parseAsMoment(argDate).format('YYYY-MM-DD'))
  }

  return (
    <Form.Group as={Row} className="mb-3" controlId="formMaintenancePeriod">
      <Form.Label column sm={5}>
        {props.label}
        { (props.edit && props.required) && (
            <sup className="text-danger">*</sup>
        )}
      </Form.Label>
      <div className="col-sm-7">
      { props.edit ? (
          <>
            <Form.Control 
              type="date" 
              value={startDate}
              min={props.minDate}
              max={props.maxDate}
              disabled={props.load}
              placeholder={I18n.get('general_unselected')} 
              onChange={evt => handleChange(evt.target.value)}  />            
            <div className="text-danger">
              {props.error}
            </div>
          </>
      ) : (
        <div className="col-form-label d-flex align-items-center justify-content-start" >
          {parseAsMoment(props.input).format('YYYY年MM月DD日')}
        </div>
      )}
      </div>
    </Form.Group>
  )
}