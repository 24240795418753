import React, { useState, useEffect } from 'react'
import { I18n } from "aws-amplify";

import { putSuccessUserDetail as ToastPutSuccess, putErrorUserDetail as ToastPutError} from '../utils/toast';

import { ConstantEquals, USER } from './../utils/constants'
import { isObject } from './../utils/utils'
import { ValidMessage, ValidMailaddress, ValidPostalCode, ValidAddress, ValidTelephoneNumber, ValidCompanyName } from './Validate'
import { getSimpleUser, putUserContact } from './api/User'

import { Row, Col, Card, Form, Placeholder } from 'react-bootstrap'
import { PencilSquare, XLg } from 'react-bootstrap-icons';

import { InputItem } from './form/InputItem'
import { SubmitButton } from './form/SubmitButton'

export const Detail = (props) => {

  // 編集モード
  const [edit, setEdit] = useState(false)
  // 更新中
  const [load, setLoad] = useState(false)
  // ユーザ詳細
  const [data, setData] = useState({})
  // 入力：変更
  const [input, setInput] = useState({})
  // 入力：変更検知
  const [change, setChange] = useState(false)
  // 入力：エラー
  const [errors, setErrors] = useState({})

  // 初回マウント時
  useEffect(() => {
    // Stateのメモリリーク対策
    let isMounted = true;
    // 取得
    (async() => {

      try{

        const fetchUserDetail = await getSimpleUser(props.userId)

        // 取得した情報をparseする
        const parseData = parse(fetchUserDetail.data.getUser)

        if(!parseData.done){
          if (isMounted) setData({});
          return;
        }
        
        // 描画
        if (isMounted){
          setData(parseData.user)
          setErrors(parseData.errors)
        }
  
      }
      catch(err){
        console.log(err)
      }

    })()
    return () => { 
      // マウントされていない場合はフラグをクリーンにする（setStateさせない）
      isMounted = false 
    };
  }, []);

  // data以外初期化
  const init = ()=>{
    setEdit(false)
    setLoad(false)
    setInput({})
    setChange(false)
    setErrors({})
  }

  // パース：取得データ
  const parse = (argUser) =>{

    const parseData = {
      done: false,
      errors: {},
      updatedAt: "",
      user: {
        mailaddress: null,
        companyName: null
      }
    };

    // No Dataの場合
    if(argUser.length === 0){
      parseData.done = true;
      return parseData;
    }

    Object.entries(argUser).map(([key, value]) => {
      // 会社名
      if(ConstantEquals(key, USER.LABEL.COMPANY_NAME)){
        const vCompanyName = ValidCompanyName(value)
        if (!ConstantEquals(vCompanyName, USER.VALIDATE.CLEAR) ) {
          parseData.errors[USER.LABEL.COMPANY_NAME] = ValidMessage(vCompanyName);
        }else{
          parseData.user.companyName = argUser.companyName;
        }
      }

      // メールアドレス
      if(ConstantEquals(key, USER.LABEL.MAILADDRESS)){
        const vMailaddress = ValidMailaddress(value)
        if (!ConstantEquals(vMailaddress, USER.VALIDATE.CLEAR) ) {
          parseData.errors[USER.LABEL.MAILADDRESS] = ValidMessage(vMailaddress);
        }else{
          parseData.user.mailaddress = argUser.mailaddress;
        }
      }

      // 更新日
      if(ConstantEquals(key, USER.LABEL.UPDATED_AT)){
        parseData.updatedAt = argUser.updatedAt;
      }

    })

    parseData.done = true;
    return parseData;
  }

  // 入力変更
  const inputChange = (key, value) => {
    let cInput = input
    cInput[key] = value

    // エラーの一時解除
    let cErrors = errors
    if(typeof cErrors[key] !== "undefined"){
      delete cErrors[key]
    }

    setInput(cInput)
    setErrors(cErrors)
    setChange(true)
  }

  // 更新
  const onSubmitClick = (event) => {

    // formデフォルトの挙動をキャンセル
    event.preventDefault();
    // イベントの伝播を止める
    event.stopPropagation();

    if(!Object.keys(input).length){
      return;
    }

    const valid = inputValidate()
    if(!valid.isValid){
      setErrors(valid.errors)
      return;
    }

    let param = JSON.parse(JSON.stringify(input))
    param[USER.LABEL.ID] = props.userId

    // メールアドレスが空文字だった場合、nullにする
    if (typeof param[USER.LABEL.MAILADDRESS] !== "undefined" && !param[USER.LABEL.MAILADDRESS])
      param[USER.LABEL.MAILADDRESS] = null

    // 更新する
    changeUser(param)
  }

  // 入力確認：ユーザ連絡先
  const inputValidate = () =>{

    const res = {
      isValid: true,
      errors: {}
    };

    // 会社名
    if(input.hasOwnProperty(USER.LABEL.COMPANY_NAME)){
      const vCompanyName = ValidCompanyName(input.companyName)
      if (!ConstantEquals(vCompanyName, USER.VALIDATE.CLEAR) ) {
        res.isValid = false;
        res.errors[USER.LABEL.COMPANY_NAME] = ValidMessage(vCompanyName);
      }
    }

    // メールアドレス
    if(input.hasOwnProperty(USER.LABEL.MAILADDRESS)){
      const vMailaddress = ValidMailaddress(input.mailaddress)
      if (!ConstantEquals(vMailaddress, USER.VALIDATE.CLEAR) ) {
        res.isValid = false;
        res.errors[USER.LABEL.MAILADDRESS] = ValidMessage(vMailaddress);
      }
    }

    return res;
  }

  // Put： ユーザ連絡先
  const changeUser = async (argParam) => {
    setLoad(true)

    try {
      // 一括登録と更新
      const updateUserDetail = await putUserContact(argParam);
      
      // 結果の画面反映
      changeSuffix(updateUserDetail)
    } 
    catch (error) {
      ToastPutError()
      init()
    }

    return;
  }

  // 追加・更新後処理
  const changeSuffix = (argRes) => {
    const parseData = parse(argRes.data.updateUser)
    if(!parseData.done){
      ToastPutError()
      init()
      return;
    }

    setData(parseData.user)
    ToastPutSuccess()
    // 初期状態にする
    init()

    // 親コンポーネントに最終更新日の更新値を通知する
    props.doneUpdate(parseData.updatedAt)
  }

  // 編集モードの切り替え
  const toggle = (arg) => {
    setInput({})
    setChange(false)
    setErrors({})
    setEdit(arg)
  }

  return (
    <>
      <Card>
        <Card.Header>
          <Row>
            <Col sm={12} md={8}>
            {I18n.get(edit ? 
              ('sub_title_user_contact_information_change') : 
              ('sub_title_user_contact_information')
            )}
            </Col>
            <Col sm={12} md={4} className="d-flex align-items-center justify-content-end">
            { edit ? ( 
              <XLg style={{cursor: 'pointer'}} color="gray" size={16} onClick={evt => toggle(false)} />
            ):( 
              <PencilSquare style={{cursor: 'pointer'}} color="gray" size={16} onClick={evt => toggle(true)} />
            )}
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Form noValidate onSubmit={onSubmitClick}>
            { isObject(data) ? (
              <InputItem 
                edit={edit}
                load={load}
                formType="text"
                help={I18n.get('help_user_company_name')}
                controlKey={USER.LABEL.COMPANY_NAME}
                label={I18n.get('label_user_company_name')}
                value={data.companyName}
                error={errors[USER.LABEL.COMPANY_NAME]}
                onChange={evt => inputChange(USER.LABEL.COMPANY_NAME, evt.target.value)} />
            ) : (
              <Placeholder as="h3" animation="glow">
                <Placeholder.Button xs={12} md={12} variant="secondary" />
              </Placeholder>
            )}

            { isObject(data) ? (
              <InputItem 
                edit={edit}
                load={load}
                formType="email"
                help={I18n.get('help_user_mailaddress')}
                controlKey={USER.LABEL.MAILADDRESS}
                label={I18n.get('label_user_mailaddress')}
                value={data.mailaddress}
                error={errors[USER.LABEL.MAILADDRESS]}
                onChange={evt => inputChange(USER.LABEL.MAILADDRESS, evt.target.value)} />
            ) : (
              <Placeholder as="h3" animation="glow">
                <Placeholder.Button xs={12} md={12} variant="secondary" />
              </Placeholder>
            )}

            { edit && (
              <div className="d-flex justify-content-end" >
                <SubmitButton isLoading={load} disabled={!change} />
              </div>
            )}

          </Form>
        </Card.Body>
      </Card>
    </>
  )

}