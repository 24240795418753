import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { I18n } from "aws-amplify";

import { ConstantEquals, LICENCE, LICENCE_BIND } from './../utils/constants'
import { isUndefined, isObject } from './../utils/utils'
import { ValidMessage, ValidLicenceType, ValidLicencePeriod, ValidSubscriptionExpiryDateAt } from './utils/Validate'

import { Container, Row, Col, Breadcrumb, Button, Spinner, Modal, Table, Card, ListGroup } from 'react-bootstrap'
import { Header, Footer } from './../Panels'

import { LicenceVersionContext } from './context/LicenceVersion'
import { getLicence, getListForAcsVersion } from './api/Licence'
import { getLicenceBinds } from './api/LicenceBind'
import { Detail as LicenceDetail } from './Detail'
import { Record as LicenceBindRecord } from './components/LicenceBind/Record'
import { RelationUUID } from './RelationUUID'
import { UpdatedAt } from './UpdatedAt'
import { Active } from './Active'
import { Delete as LicenceDelete } from './Delete'

const Licence = (props) => {

  const history = useHistory();
  const location = useLocation();

  // ライセンスID
  const licenceId = props.match.params.id

  // ライセンス情報
  const [licenceLoad, setLicenceLoad] = useState(false)
  const [licenceData, setLicenceData] = useState({})
  const [licenceErrors, setLicenceErrors] = useState({})
  // アクティブ
  const [licenceActive, setLicenceActive] = useState(false)
  // 更新日
  const [licenceUpdatedAt, setLicenceUpdatedAt] = useState(null)

  const [bindListLoad, setBindListLoad] = useState(false)
  const [bindList, setBindList] = useState([])
  const [bindNextToken, setBindNextToken] = useState(null)
  const [bindNextRead,  setBindNextRead] = useState(false)

  // ACSバージョンリスト
  const [currentLicenceVersion, setCurrentLicenceVersion] = useState([])

  // 初期化
  const init = () =>{
    setLicenceErrors({})
    setLicenceData({})
    setLicenceLoad(false)

    setBindNextRead(false)
    setBindNextToken(null)
    setBindList([])
    setBindListLoad(false)

    setCurrentLicenceVersion([])
  }

  // ユーザ詳細ページへ遷移
  const goUserPage = () => {
   const path = isStateUserId() ? 
    `/user/${location.state.userId}` : `/dashboard`;
    return {
      pathname: path
    }
  }

  // []指定でマウント時1回のみ発火する
  useEffect(() => {
    // マウント対策する
    // Stateのメモリリーク対策
    let isMounted = true;

    // fetchLicence()
    (async() => {
      if (isMounted){
        setLicenceLoad(true)
        setLicenceActive(false)
        setLicenceData({});
        setBindListLoad(true)
        setBindList([])
        setCurrentLicenceVersion([])
      }
  
      // 複数のタスクを非同期で実行する
      const tasks = [];
  
      // ライセンス情報
      tasks.push(getLicence(licenceId))
  
      // ライセンス認証履歴
      tasks.push(getLicenceBinds(licenceId))
  
      // ACSバージョン
      tasks.push(getListForAcsVersion())
  
      try {
        // 一括取得
        const [resLicence, resLicenceBinds, resAcsVersion] = await Promise.all(tasks);
  
        // 取得した情報をparseする
        const parseData = parseLicence(resLicence.data.getLicence)
  
        if(!parseData.done){
          if (isMounted){
            setLicenceData({});
          }
          return;
        }
  
        // 描画
        if (isMounted){
          setLicenceData(parseData.licence)
          setLicenceErrors(parseData.errors)
          setLicenceLoad(false)
          setLicenceActive(parseData.licence.active)
          setLicenceUpdatedAt(parseData.licence.updatedAt)
    
          // bind
          setBindList(resLicenceBinds.data.listLicenceBindByCreatedAt.items)
          setBindNextToken(resLicenceBinds.data.listLicenceBindByCreatedAt.nextToken)
          
          setBindListLoad(false)
    
          // バージョンリスト
          setCurrentLicenceVersion(resAcsVersion.data.listAcsVersions.items)


        }
      } 
      catch (error) {
        console.log(error)
        // putErrorLicenceLimit()
        init()
        return;
      }

  })()
  return () => { 
    // マウントされていない場合はフラグをクリーンにする（setStateさせない）
    isMounted = false 
  };
}, []);

  useEffect(() => {
    if(!isObject(licenceData)) return;
  },[licenceData]);

  // 取得
  // - 取得したデータをDetailに渡す
  const fetchLicence = async() => {
    setLicenceLoad(true)
    setLicenceActive(false)
    setLicenceData({});
    setBindListLoad(true)
    setBindList([])
    setCurrentLicenceVersion([])

    // 複数のタスクを非同期で実行する
    const tasks = [];

    // ライセンス情報
    tasks.push(getLicence(licenceId))

    // ライセンス認証履歴
    tasks.push(getLicenceBinds(licenceId))

    tasks.push(getListForAcsVersion())

    try {
      // 一括取得
      const [resLicence, resLicenceBinds, resAcsVersion] = await Promise.all(tasks);

      // 取得した情報をparseする
      const parseData = parseLicence(resLicence.data.getLicence)

      if(!parseData.done){
        setLicenceData({});
        return;
      }

      // 描画
      setLicenceData(parseData.licence)
      setLicenceErrors(parseData.errors)
      setLicenceLoad(false)
      setLicenceActive(parseData.licence.active)
      setLicenceUpdatedAt(parseData.licence.updatedAt)

      // bind
      setBindList(resLicenceBinds.data.listLicenceBindByCreatedAt.items)
      setBindNextToken(resLicenceBinds.data.listLicenceBindByCreatedAt.nextToken)
      
      setBindListLoad(false)

      // バージョンリスト
      setCurrentLicenceVersion(resAcsVersion.data.listAcsVersions.items)

    } 
    catch (error) {
      console.log(error)
      // putErrorLicenceLimit()
      init()
      return;
    }

  }

  // 取得データをparse
  const parseLicence = (argLicenceData) => {
    const parseData = {
      done: false,
      errors: {},
      licence:{}
    };

    const resValid =  validateLicenceData(argLicenceData);
    if(!resValid.isValid){
      parseData.errors = resValid.errors;
      return parseData;
    }

    // parseする
    parseData.licence = {
      active: argLicenceData.active,
      licenceKey: argLicenceData.licenceKey,
      period: argLicenceData.period,
      subscriptionExpiryDateAt: argLicenceData.subscriptionExpiryDateAt,
      maintenancePeriod: argLicenceData.maintenancePeriod,
      type: argLicenceData.type,
      updatedAt: argLicenceData.updatedAt,
      uuid: argLicenceData.uuid,
      corp: argLicenceData.corp,
      active: argLicenceData.active,
      acsVersionId: argLicenceData.acsVersionId,
      acsVersion: argLicenceData.acsVersion
    }

    // 異常なし
    parseData.done = true;
    return parseData;
  }

  // 取得後と変更内容の整合性確認
  const validateLicenceData = (arg) => {

    let cInput = arg;

    const res = {
      isValid: true, 
      errors: {}
    }

    if(isUndefined(arg)) return res;

    // ライセンス種別
    const vLicenceType = ValidLicenceType(cInput.type)
    if (!isUndefined(cInput.type) && !ConstantEquals(vLicenceType, LICENCE.VALIDATE.CLEAR) ) {
      res.isValid = false;
      res.errors["type"] = ValidMessage(vLicenceType);
    }

    // ライセンス購入種別
    const vLicencePeriod = ValidLicencePeriod(cInput.period)
    if (!isUndefined(cInput.period) && !ConstantEquals(vLicencePeriod, LICENCE.VALIDATE.CLEAR) ) {
      res.isValid = false;
      res.errors["period"] = ValidMessage(vLicencePeriod);
    }

    // ライセンス期限
    const vSubscriptionExpiryDateAt = ValidSubscriptionExpiryDateAt(cInput.subscriptionExpiryDateAt, cInput.period)
    if (!isUndefined(cInput.subscriptionExpiryDateAt) && !ConstantEquals(vSubscriptionExpiryDateAt, LICENCE.VALIDATE.CLEAR) ) {
      res.isValid = false;
      res.errors["subscriptionExpiryDateAt"] = ValidMessage(vSubscriptionExpiryDateAt);
    }

    return res;
  }

  // 認証履歴を更に読み込む対応
  const addFetchLicenceBind = async() => {
    setBindNextRead(true)

    try{
      // ライセンスの他に認証履歴をfetchする可能性がある
      const fetchLicenceBind = await getLicenceBinds(licenceId, bindNextToken)

      // 現在のbindListに追加する
      setBindList([...bindList, ...fetchLicenceBind.data.listLicenceBindByCreatedAt.items])
      setBindNextToken(fetchLicenceBind.data.listLicenceBindByCreatedAt.nextToken)
            
      setBindNextRead(false)
    }
    catch(err){
      console.log(err)
      setBindNextRead(false)
    }

  }

  /**
   * Activeバッジの描画
   * @param {*} arg boolean
   */
   const applyLicenceActive = (arg) => {
    setLicenceActive(arg)
  }

  /**
   * 更新日の描画
   * @param {*} arg YYYY-MM-DDTHH:mm:00Z
   */
   const applyUpdatedAt = (arg) => {
    // Todo: validateするべき

    setLicenceUpdatedAt(arg)
  }

  /**
   * UserIDが存在するか確認する
   */
  const isStateUserId = () => {
    return !isUndefined(location.state) && !isUndefined(location.state.userId)
  }

  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col xs={12} md={12} className="mt-1">
            <Breadcrumb >
              <Breadcrumb.Item active>
                <Link to="/dashboard">
                  {I18n.get('title_dashboard')}
                </Link>
              </Breadcrumb.Item>

              {/* ユーザ画面以外から遷移した場合、これは表示させない */}
              { isStateUserId() ? (
                <Breadcrumb.Item active className="styleBreadcrumb">
                  <Link to={goUserPage}>
                  {`${I18n.get('title_user_detail')}`}
                  </Link>
                </Breadcrumb.Item>
              ) : (<></>) }

              <Breadcrumb.Item active>
                {`${I18n.get('title_licence_detail')}`}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
      </Container>
      <LicenceVersionContext.Provider value={currentLicenceVersion}>
        <Container>
        { isObject(licenceData) ? (
          <>
            <Row>
              <Col xs={12} md={12} className="mt-3 mb-2">
                <h3 className="text-break">
                  {licenceData.licenceKey}
                </h3>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col xs={12} md={6} >
                {
                  licenceActive ? (
                    <span className="badge bg-success">{I18n.get('general_active')}</span>
                  ) : (
                    <span className="badge bg-danger">{I18n.get('general_inactive')}</span>
                  )
                }{' '}
                <UpdatedAt licenceUpdatedAt={licenceUpdatedAt} />
              </Col>
              <Col xs={12} md={6} >
                <div className="text-end" >
                  <Active 
                    licenceId={licenceId}
                    active={licenceActive}
                    doneUpdateActive={applyLicenceActive}
                    doneUpdatedAt={applyUpdatedAt} />
                  {` `}
                  <LicenceDelete 
                    userId={location.state.userId}
                    licenceId={licenceId} />
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}
        <hr />
        <Row>
              <Col sm={12} md={6}>
                <Row>
                  <Col sm={12} md={12}>
                  { licenceLoad ? (
                    <div className="text-center">
                      <Spinner
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                      />
                    </div>
                    ):(
                      isObject(licenceData) ? (
                        <LicenceDetail 
                          licenceId={licenceId}
                          data={licenceData}
                          doneUpdate={applyUpdatedAt} />
                      ) : (
                        <></>
                      )
                    )
                  }
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={12} className="mt-1">
                    { licenceLoad ? (
                      <></>
                    ):(
                      isObject(licenceData) ? (
                        <RelationUUID 
                          licenceId={licenceId} 
                          data={licenceData} 
                          doneUpdate={applyUpdatedAt} />
                      ) : (
                        <></>
                      )
                    )}
                  </Col>
                </Row>

              </Col>
              {/* 認証履歴 */}
              <Col sm={12} md={6} className="mt-1" >
                <h5>{I18n.get('sub_title_licence_bind_history')}</h5>
                { bindListLoad ? (
                  <div className="text-center">
                    <Spinner
                      as="span"
                      animation="border"
                      role="status"
                      aria-hidden="true"
                    />
                  </div>
                ) : bindList.length === 0 ? (
                  <>{I18n.get('general_no_data')}</>
                ) : (
                  <>
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th>{I18n.get('table_column_licence_bind_created_at')}</th>
                          <th>{I18n.get('table_column_licence_bind_auth')}</th>
                          <th>{I18n.get('table_column_licence_bind_uuid')}</th>
                          <th>{I18n.get('table_column_licence_bind_device')}</th>
                          <th>{I18n.get('table_column_licence_bind_version')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <>
                        { isObject(bindList) ? (
                          bindList.map((cBind, index) => (
                          <LicenceBindRecord 
                            key={`lbr-${index}`} 
                            bind={cBind} 
                            index={index} />
                          ))
                        ):(<></>) }
                        </>
                      </tbody>
                      { bindNextToken ? (
                        <tfoot>
                          <tr>
                            { bindNextRead ? ( 
                              <td colSpan={4} className="text-center">
                                <Spinner
                                  as="span"
                                  animation="border"
                                  role="status"
                                  aria-hidden="true" />
                              </td>
                            ): (
                              <td colSpan={4} className="text-center styleLiceRecd"
                                onClick={() => addFetchLicenceBind()} >
                                {I18n.get('general_paging')}
                              </td>
                            ) }
                          </tr>
                        </tfoot>
                      ) : (<></>)}
                    </Table>
                  </>
                )}
              </Col>
            </Row>
        </Container>
      </LicenceVersionContext.Provider>
      <Footer />
    </>
   )
}

export default Licence
