import { API, graphqlOperation} from 'aws-amplify'
import { listLicenceBinds as LicenceBinds, listLicenceBindByCreatedAt } from './../../../graphql/queries'

/**
 * SELECT: ライセンス認証履歴リスト
 * 
 * @param {*} argId 
 */
export async function getLicenceBinds(argLicenceId, argNextToken = null){
  const queryArgument = Object.assign({},{ 
    licenceId: argLicenceId,
    sortDirection: 'DESC',
    limit : 10,
    nextToken: argNextToken
  })
  return API.graphql(graphqlOperation(listLicenceBindByCreatedAt, queryArgument))
}