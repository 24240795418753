import React, { useState, useEffect, useContext } from 'react'
import { I18n } from "aws-amplify";

import { putSuccessLicenceLimit, putErrorLicenceLimit, validErrorLicenceLimit } from '../../../utils/toast';

import { ConstantEquals, LICENCE_TYPE, LICENCE_LIMIT } from '../../../utils/constants'
import { isObject } from '../../../utils/utils'
import { LicenceNumContext } from './../../context/LicenceNum'

import { listLicenceLimit, postLicenceLimit, putLicenceLimit } from './api/LicenceLimit'

import { Row, Col, Card, Form, Placeholder } from 'react-bootstrap'
import { PencilSquare, XLg } from 'react-bootstrap-icons';
import { Num } from './form/Num'
import { SubmitButton } from './form/SubmitButton'

import { ValidMessage, ValidNum } from './Validate'

export const LicenceLimit = (props) => {
  // 現在のライセンス数
  const currentLicenceNumContext = useContext(LicenceNumContext)

  // 編集モード
  const [edit, setEdit] = useState(false)
  // 更新中
  const [load, setLoad] = useState(false)
  // 管理数
  const [data, setData] = useState({})
  // 入力：変更
  const [input, setInput] = useState({})
  // 入力：変更検知
  const [change, setChange] = useState(false)
  // 入力：エラー
  const [errors, setErrors] = useState({})

  // 初回マウント時
  useEffect(() => {
    // Stateのメモリリーク対策
    let isMounted = true;
    // 取得
    (async() => {

      try{

        const fetchLicenceLimit = await listLicenceLimit(props.userId)
  
        // 取得した情報をparseする
        const parseData = parse(fetchLicenceLimit.data.listLicenceLimits.items)
  
        if(!parseData.done){
          if (isMounted) setData({});
          return;
        }
        
        // 描画
        if (isMounted){
          setData(parseData.licenceLimit)
        }
  
      }
      catch(err){
        console.log(err)
      }

    })()
    return () => { 
      // マウントされていない場合はフラグをクリーンにする（setStateさせない）
      isMounted = false 
    };
  }, []);

  /**
   * data更新後、userDashboardに反映する。UserDashboardで取得する方法も考えたが
   * 初回と更新後にdata更新を行うので当コンポーネントで取得することとした
   */
  useEffect(()=>{
    if(!isObject(data)) return;
    props.applyLicenceLimitNum(parseForUserDashboard(data))
  }, [data])

  // data以外初期化
  const init = ()=>{
    setEdit(false)
    setLoad(false)
    setInput({})
    setChange(false)
    setErrors({})
  }

  // パース：取得データ
  const parse = (licenceLimitList) =>{

    const parseData = {
      done: false,
      errors: {},
      licenceLimit: {
        use:  { id: null, num: 0 }
      }
    };

    // No Dataの場合
    if(licenceLimitList.length === 0){
      parseData.done = true;
      return parseData;
    }

    parseData.licenceLimit = licenceLimitList.reduce((result, current) => {
      // use
      if(ConstantEquals(current.type, LICENCE_TYPE.USE)){
        result[LICENCE_TYPE.USE].id = current.id;
        result[LICENCE_TYPE.USE].num = current.num;
      }

      return result;
    }, parseData.licenceLimit)

    parseData.done = true;
    return parseData;
  }

  // UserDashboard用にparseする
  const parseForUserDashboard = (argParseData) => {

    const parseData = {
        use: 0
    };

    parseData.use = argParseData.use.num;

    return parseData;
  }

  // 入力
  const inputChange = (key, value) => {
    let cInput = input
    cInput[key] = value

    // エラーの一時解除
    let cErrors = errors
    if(typeof cErrors[key] !== "undefined"){
      delete cErrors[key]
    }

    setInput(cInput)
    setErrors(cErrors)
    setChange(true)
  }

  /**
   * 更新。エラーが表示された場合、
   * @param {*} event 
   * @returns 
   */
  const onSubmitClick = (event) => {

    // formデフォルトの挙動をキャンセル
    event.preventDefault();
    // イベントの伝播を止める
    event.stopPropagation();

    // 変更なし
    if(!Object.keys(input).length){
      return;
    }

    // deep copy
    let param = JSON.parse(JSON.stringify(data))

    Object.keys(input).map(key => {
      param[key].num = parseInt(input[key])
    })

    const valid = inputValidate(param)
    if(!valid.isValid){
      Object.keys(valid.errors).map(key => {
        // エラーはトースト表示
        validErrorLicenceLimit(valid.errors[key])
      });
      return;
    }

    // 更新する
    changeLicenceLimit(param)
  }

  // 入力確認：ライセンス上限数
  const inputValidate = (argParam) =>{

    const res = {
      isValid: true,
      errors: {}
    };

    Object.keys(argParam).map(key => {

      const vNum = ValidNum(argParam[key].num, currentLicenceNumContext[key])
      if (!ConstantEquals(vNum, LICENCE_LIMIT.VALIDATE.CLEAR)) {
        res.isValid = false;
        res.errors[key] = ValidMessage(vNum, key);
      }

    })

    return res;
  }

  /**
   * Put： ライセンス管理数
   * - 引数がundefinedだった場合、putを行わない
   * 
   * @param {*} event 
   */
  const changeLicenceLimit = async (argParam) => {
    setLoad(true)

    // 複数のタスクを非同期で実行する
    const tasks = [];
    let resAll;

    Object.keys(argParam).map(key => {
      // Insert
      if(argParam[key].id === null){
        tasks.push(postLicenceLimit(props.userId, key, argParam[key].num))
      }
      // Update
      else {
        tasks.push(putLicenceLimit(argParam[key].id, argParam[key].num))
      }
    })

    // Todo: ユーザ情報の更新日のみを更新するtaskを追加する

    try {
      // 一括登録と更新
      resAll = await Promise.all(tasks);
      // 結果の画面反映
      changeSuffix(resAll)
    } 
    catch (error) {
      putErrorLicenceLimit()
      init()
    }

    return;
  }

  // 追加・更新後処理
  const changeSuffix = (argResArr) => {
    let updData = JSON.parse(JSON.stringify(data))
    for (const elem of argResArr) {
      const item = elem.data.createLicenceLimit || elem.data.updateLicenceLimit;
      if(!Object.keys(item).length) break;
      updData[item.type].num = item.num;
    }
    setData(updData)

    putSuccessLicenceLimit()
    // 初期状態にする
    init()
  }

  // 編集モードの切り替え
  const toggle = (arg) => {
    setChange(false)
    setEdit(arg)
  }

  return (
    <>
      <Card>
        <Card.Header>
          <Row>
            <Col sm={12} md={8}>
            { I18n.get( edit ? 
              'sub_title_licence_limit_information_change' : 
              'sub_title_licence_limit_information' )}
            </Col>
            <Col sm={12} md={4} className="d-flex align-items-center justify-content-end">
            { edit ? ( 
              <XLg style={{cursor: 'pointer'}} color="gray" size={16} onClick={evt => toggle(false)} />
            ):( 
              <PencilSquare style={{cursor: 'pointer'}} color="gray" size={16} onClick={evt => toggle(true)} />
            )}
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Form noValidate onSubmit={onSubmitClick}>
          { isObject(data) ? (
            <Num 
              label={I18n.get('label_licence_limit_use')}
              controlKey={LICENCE_TYPE.USE}
              edit={edit}
              load={load}
              num={data.use.num}
              onChange={evt => inputChange(LICENCE_TYPE.USE, evt.target.value)} />
          ) : (
            <Placeholder as="h3" animation="glow">
              <Placeholder.Button xs={10} md={10} variant="secondary" />
            </Placeholder>
          )}

          { edit && (
            <div className="d-flex justify-content-end" >
              <SubmitButton isLoading={load} disabled={!change} submitText="更新" loadingText="Loading..." />
            </div>
          )}
          </Form>
        </Card.Body>
      </Card>
    </>
  )
};