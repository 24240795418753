import { I18n } from 'aws-amplify'

import { LICENCE_LIMIT } from './../../../utils/constants'
import { ConstantEquals, LICENCE_TYPE } from '../../../utils/constants'

/**
 * 引数に応じたmessageを返す
 * 
 * @param {*} argConstant 
 */
export function ValidMessage(argConstant, argType){

  let res = "";
  switch(argConstant) {
    // 存在しない
    case LICENCE_LIMIT.VALIDATE.LICENCE_TYPE.ILLEGAL:
      if (ConstantEquals(argType, LICENCE_TYPE.USE)) 
        res = I18n.get('validate_licence_limit_illegal');
      break;
    // 数字ではない
    case LICENCE_LIMIT.VALIDATE.LICENCE_TYPE.REGULAR:
      if (ConstantEquals(argType, LICENCE_TYPE.USE)) 
        res = I18n.get('validate_licence_limit_regular');
      break;
    // 下限を割る
    case LICENCE_LIMIT.VALIDATE.LICENCE_TYPE.OVERFLOW:
      if (ConstantEquals(argType, LICENCE_TYPE.USE)) 
        res = I18n.get('validate_licence_limit_overflow');
      break;

  }

  return res;
}

/**
 * Validation: 利用ライセンス数
 * 
 * @param {*} argNum 
 * @param {*} lowerLimit 
 * @returns 
 */
export function ValidNum(argNum, lowerLimit) {

  // 存在しなかった
  if (typeof argNum == "undefined") {
    return LICENCE_LIMIT.VALIDATE.LICENCE_TYPE.ILLEGAL;
  }

  // 数値でない
  if(!Number.isFinite(argNum)){
    return LICENCE_LIMIT.VALIDATE.LICENCE_TYPE.REGULAR;
  }

  // 下限を突破
  if(argNum < lowerLimit){
    return LICENCE_LIMIT.VALIDATE.LICENCE_TYPE.OVERFLOW;
  }

  // 異常なし
  return LICENCE_LIMIT.VALIDATE.CLEAR;
}