export const getLicenceSimple = /* GraphQL */ `
  query GetLicence($id: ID!) {
    getLicence(id: $id) {
      id
      userId
      type
      period
      subscriptionExpiryDateAt
      maintenancePeriod
      licenceKey
      uuid
      active
      updatedAt
      createdAt
      owner
      acsVersionId
      acsVersion {
        id
        no
        version
        releaseDate
        updatedAt
        createdAt
      }
    }
  }
`;

export const listLicencesSimple = /* GraphQL */ `
  query ListLicences(
    $id: ID
    $filter: ModelLicenceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLicences(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        type
        period
        subscriptionExpiryDateAt
        maintenancePeriod
        licenceKey
        uuid
        active
        updatedAt
        createdAt
        owner
        acsVersionId
        acsVersion {
          id
          no
          version
          releaseDate
          updatedAt
          createdAt
        }
      }
      nextToken
    }
  }
`;

export const listLicenceByUpdatedAtSimple = /* GraphQL */ `
query ListLicenceByUpdatedAt(
  $userId: ID
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLicenceFilterInput
  $limit: Int
  $nextToken: String
) {
  listLicenceByUpdatedAt(
    userId: $userId
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      type
      period
      subscriptionExpiryDateAt
      maintenancePeriod
      acsVersionId
      acsVersion {
        id
        no
        version
        releaseDate
        updatedAt
        createdAt
      }
      licenceKey
      uuid
      active
      updatedAt
      createdAt
      owner
    }
    nextToken
  }
}
`;
