import React from 'react'
import {
  withRouter,
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router
} from 'react-router-dom'
import UserContext from './../UserContext'
import { ToastContainer } from 'react-toastify';

import Authenticator from './../sign/Authenticator'
import Top from './../dashboard/Top'
import UserAdd from './../userAdd/UserAdd'
import UserDashboard from '../user/UserDashboard'
import Licence from '../licence/Licence'
// import LicenceAdd from '../licenceAdd/LicenceAdd'

/**
 * Private Routeページ
 */
class PrivateRoute extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false
  }
  // UserContext(AuthContext)から受け取れる
  // 以降のthis.contextが指すのはcontextType、
  // AuthContext（this.user）になるになる
  static contextType = UserContext

  // コンポーネントがマウントされた直後に呼び
  componentDidMount() {
    // ルーティングが変更されるたびにコールバック関数を呼ぶ
    this.unlisten = this.props.history.listen(() => {
      this.context.updateCurrentUser()
    })
  }
  // コンポーネントが DOM から削除されるときに呼び
  componentWillUnmount() {
    // マウント時に宣言していあるので問題なく実行できる（はず）
    this.unlisten()
  }

  /**
   * 
   * @returns 描画
   */
  render() {
    // ...: Spread Attributes。スプレッド演算子。
    const { component: Component, ...rest } = this.props
    // usernameが存在するならtrue
    const isAuthenticated = this.context.user && this.context.user.username ? true : false
    const isLoaded = this.context.isLoaded
    if (!isLoaded) return null

    return (
      <Route
        {...rest}
        render={props => {
          // サインイン済みだったら？
          return isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          )
        }}
      />
    )
  }
}

/**
 * 存在しないpathだった場合
 * 
 * @param {*} param0 
 * @returns 
 */
const NoMatch = ({ location }) => (
  <div>
    <h3>No match for <code>{location.pathname}</code></h3>
  </div>
)

// withRouter: withRouterによってPrivateRouteのPropsが利用できる
PrivateRoute = withRouter(PrivateRoute)

// コンポーネント：Headerと、pathに応じてメインビューの内容が切り替わるようになっている
// - Switch: routeのグループ化
// - exact: pathの文字列とpathNameが完全一致したときにcomponentを返す
// PrivateRouterのrenderにRouteが入っている。ログイン済みかそうではないかで表示するコンポーネントを変更している
const Routes = () => (
  <Router>
    <div>
      <Switch>
        <Route path='/' exact component={Authenticator} />
        <PrivateRoute path='/dashboard' exact component={Top} />
        <PrivateRoute path='/user' exact component={UserAdd} />
        <PrivateRoute path='/user/:id' exact component={UserDashboard} />
        <PrivateRoute path='/licence/:id' exact component={Licence} />
        <Route component={NoMatch} />
      </Switch>
      <ToastContainer newestOnTop={true} rtl={false} />
    </div>
  </Router>
)

export default Routes
