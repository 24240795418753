import { I18n } from 'aws-amplify'
import { USER } from '../utils/constants'

/**
 * 引数に応じたmessageを返す
 * 
 * @param {*} argConstant 
 */
export function ValidMessage(argConstant){

  let res = "";
  switch(argConstant) {
    // ユーザ名
    case USER.VALIDATE.USERNAME.ILLEGAL:
      res = I18n.get('validate_user_username_illegal');
      break;
    case USER.VALIDATE.USERNAME.ALLOW_SPACE:
      res = I18n.get('validate_user_username_allow_space');
      break;
    case USER.VALIDATE.USERNAME.REGULAR:
      res = I18n.get('validate_user_username_regular');
      break;
    // 会社名
    case USER.VALIDATE.COMPANY_NAME.ILLEGAL:
      res = I18n.get('validate_user_company_name_illegal');
      break;
    case USER.VALIDATE.COMPANY_NAME.REGULAR:
      res = I18n.get('validate_user_company_name_regular');
      break;
    // メールアドレス
    case USER.VALIDATE.MAILADDRESS.ILLEGAL:
      res = I18n.get('validate_user_mailaddress_illegal');
      break;
    case USER.VALIDATE.MAILADDRESS.ALLOW_SPACE:
      res = I18n.get('validate_user_mailaddress_allow_space');
      break;
    case USER.VALIDATE.MAILADDRESS.REGULAR:
      res = I18n.get('validate_user_mailaddress_regular');
      break;
    // 電話番号
    case USER.VALIDATE.TELEPHONE_NUMBER.ILLEGAL:
      res = I18n.get('validate_user_telephone_number_illegal');
      break;
    case USER.VALIDATE.TELEPHONE_NUMBER.ALLOW_SPACE:
      res = I18n.get('validate_user_telephone_number_allow_space');
      break;
    case USER.VALIDATE.TELEPHONE_NUMBER.REGULAR:
      res = I18n.get('validate_user_telephone_number_regular');
      break;
    // 郵便番号
    case USER.VALIDATE.POSTAL_CODE.ILLEGAL:
      res = I18n.get('validate_user_postal_code_illegal');
      break;
    case USER.VALIDATE.POSTAL_CODE.ALLOW_SPACE:
      res = I18n.get('validate_user_postal_code_allow_space');
      break;
    case USER.VALIDATE.POSTAL_CODE.REGULAR:
      res = I18n.get('validate_user_postal_code_regular');
      break;
    // 住所
    case USER.VALIDATE.ADDRESS.ILLEGAL:
      res = I18n.get('validate_user_address_illegal');
      break;
    case USER.VALIDATE.ADDRESS.ALLOW_SPACE:
      res = I18n.get('validate_user_address_allow_space');
      break;
    case USER.VALIDATE.ADDRESS.REGULAR:
      res = I18n.get('validate_user_address_regular');
      break;
    // アクティブ
    case USER.VALIDATE.ACTIVE.ILLEGAL:
      res = I18n.get('validate_user_active_illegal');
      break;
    default:
      res = I18n.get('general_validate_illegal_error');
      break;
  }

  return res;
}

/**
 * Validation: ユーザ名
 * 
 * @param {*} arg 
 * @returns 
 */
export function ValidUsername(arg) {

  // 存在しなかった
  if (typeof arg === "undefined") {
    return USER.VALIDATE.USERNAME.ILLEGAL;
  }

  // 未入力（数字の0がfalseになるため、少し書き換える必要がある）
  if (!arg) {
    return USER.VALIDATE.USERNAME.ALLOW_SPACE;
  }

  // 正規表現で特殊文字の確認
  const pattern = new RegExp(/^[ぁ-んァ-ヶー一-龠Ａ-Ｚ０-９ｱ-ﾝﾞﾟｧ-ｫｬ-ｮｰa-zA-Z0-9 　（）\(\)\&＆\=＝･・\'’\.．\-‐]+$/);
  if (!pattern.test(arg)) {
    return USER.VALIDATE.USERNAME.REGULAR;
  }

  // 異常なし
  return USER.VALIDATE.CLEAR;
}

/**
 * Validation: 会社名
 * - 必須ではない
 * 
 * @param {*} arg 
 * @returns 
 */
export function ValidCompanyName(arg) {

  // 存在しなかった
  if (typeof arg === "undefined") return USER.VALIDATE.CLEAR

  // 正規表現で特殊文字の確認
  const pattern = new RegExp(/^[ぁ-んァ-ヶー一-龠Ａ-Ｚ０-９ｱ-ﾝﾞﾟｧ-ｫｬ-ｮｰa-zA-Z0-9 　（）\(\)\&＆\=＝･・\'’\.．\-‐]+$/);
  if (arg && !pattern.test(arg))  return USER.VALIDATE.COMPANY_NAME.REGULAR

  // 異常なし
  return USER.VALIDATE.CLEAR;
}

/**
 * Validation: メールアドレス
 * - 必須ではない
 * 
 * @param {*} arg 
 * @returns 
 */
export function ValidMailaddress(arg) {

  // 存在しなかった
  if (typeof arg === "undefined") return USER.VALIDATE.CLEAR

  // 未入力
  if (!arg) return USER.VALIDATE.CLEAR

  // 正規表現
  const pattern = new RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
  if (!pattern.test(arg)) {
    return USER.VALIDATE.MAILADDRESS.REGULAR;
  }

  // 異常なし
  return USER.VALIDATE.CLEAR;
}

/**
 * Validation: 郵便番号
 * 
 * @param {*} arg 
 * @returns 
 */
export function ValidPostalCode(arg) {

  // 存在しなかった
  if (typeof arg === "undefined") {
    return USER.VALIDATE.POSTAL_CODE.ILLEGAL;
  }

  // 未入力
  if (!arg) {
    return USER.VALIDATE.POSTAL_CODE.ALLOW_SPACE;
  }

  // 正規表現で確認
  const pattern = new RegExp(/^[0-9]{3}\-?[0-9]{4}$/);
  if (!pattern.test(arg)) {
    return USER.VALIDATE.POSTAL_CODE.REGULAR;
  }

  // 異常なし
  return USER.VALIDATE.CLEAR;
}

/**
 * Validation: 住所
 * 
 * @param {*} arg 
 * @returns 
 */
export function ValidAddress(arg) {

  // 存在しなかった
  if (typeof arg === "undefined") {
    return USER.VALIDATE.ADDRESS.ILLEGAL;
  }

  // 未入力（数字の0がfalseになるため、少し書き換える必要がある）
  if (!arg) {
    return USER.VALIDATE.ADDRESS.ALLOW_SPACE;
  }

  // 正規表現で特殊文字の確認
  const pattern = new RegExp(/^[ぁ-んァ-ヶー一-龠Ａ-Ｚ０-９（）\(\)ｱ-ﾝﾞﾟｧ-ｫｬ-ｮｰa-zA-Z0-9 　\-－]+$/);
  if (!pattern.test(arg)) {
    return USER.VALIDATE.ADDRESS.REGULAR;
  }

  // 異常なし
  return USER.VALIDATE.CLEAR;
}

/**
 * Validation: 電話番号
 * 
 * @param {*} arg 
 * @returns 
 */
export function ValidTelephoneNumber(arg) {

  // 存在しなかった
  if (typeof arg == "undefined") {
    return USER.VALIDATE.TELEPHONE_NUMBER.ILLEGAL;
  }

  // 未入力
  if (!arg) {
    return USER.VALIDATE.TELEPHONE_NUMBER.ALLOW_SPACE;
  }

  // 正規表現で確認
  const pattern = new RegExp(/^[\d\-]*$/);
  if (!pattern.test(arg)) {
    return USER.VALIDATE.TELEPHONE_NUMBER.REGULAR;
  }

  // 異常なし
  return USER.VALIDATE.CLEAR;
}

/**
 * Validation: アクティブ
 * 
 * @param {*} arg 
 * @returns 
 */
 export function ValidActive(arg) {

  // 存在しなかった
  if (typeof arg == "undefined") {
    return USER.VALIDATE.ACTIVE.ILLEGAL;
  }

  // 異常なし
  return USER.VALIDATE.CLEAR;
}
