import { API, graphqlOperation} from 'aws-amplify'
import { EXTRA } from './../../utils/constants'

// limitは単体
const queryGetLicenceLimit = /* GraphQL */ `
query GetLicenceLimit($id: ID!) {
  getLicenceLimit(id: $id) {
    id
    userId
    updatedAt
    createdAt
    owner
  }
}
`;
const queryListLicenceLimit = /* GraphQL */ `
  query ListLicenceLimits(
    $id: ID
    $filter: ModelLicenceLimitFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLicenceLimits(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        updatedAt
        createdAt
        owner
      }
      nextToken
    }
  }
`;

// licenceは複数
const queryListLicence = /* GraphQL */ `
  query ListLicences(
    $id: ID
    $filter: ModelLicenceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLicences(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        updatedAt
        createdAt
        owner
      }
      nextToken
    }
  }
`;

// licenceBindはlicenceから複数
const queryListLicenceBind = /* GraphQL */ `
  query LicenceBindByLicenceId(
    $licenceId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLicenceBindFilterInput
    $limit: Int
    $nextToken: String
  ) {
    LicenceBindByLicenceId(
      licenceId: $licenceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        licenceId
        updatedAt
        owner
      }
      nextToken
    }
  }
`;

// userIdとactiveとownerを変更する
const updateLicenceLimit = /* GraphQL */ `
  mutation UpdateLicenceLimit(
    $input: UpdateLicenceLimitInput!
    $condition: ModelLicenceLimitConditionInput
  ) {
    updateLicenceLimit(input: $input, condition: $condition) {
      id
      userId
      active
      updatedAt
      createdAt
      owner
    }
  }
`;

// userIdとactiveとownerを変更する
const updateLicence = /* GraphQL */ `
  mutation UpdateLicence(
    $input: UpdateLicenceInput!
    $condition: ModelLicenceConditionInput
  ) {
    updateLicence(input: $input, condition: $condition) {
      id
      userId
      type
      period
      subscriptionExpiryDateAt
      maintenancePeriod
      acsVersionId
      licenceKey
      uuid
      active
      updatedAt
      createdAt
      owner
    }
  }
`;

// userIdとactiveとownerを変更する
const updateLicenceBind = /* GraphQL */ `
  mutation UpdateLicenceBind(
    $input: UpdateLicenceBindInput!
    $condition: ModelLicenceBindConditionInput
  ) {
    updateLicenceBind(input: $input, condition: $condition) {
      id
      licenceId
      uuid
      device
      auth
      updatedAt
      createdAt
      owner
    }
  }
`;

// Delete: ユーザ情報の物理削除
const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      owner
      username
      mailaddress
      telephoneNumber
      postalCode
      address
      companyName
      active
      updatedAt
      createdAt
    }
  }
`;

// ==================================================

/**
 * SELECT: ライセンス管理数取得
 * 
 * @param {*} argUserId 
 */
 export async function getListLicenceLimit(argUserId){
  let filter = {
    userId: {eq: argUserId}
  };
  return API.graphql({
      query: queryListLicenceLimit, 
      variables: { filter: filter }
  })
}

/**
 * SELECT: ライセンスリスト取得
 * 
 * @param {*} argUserId
 */
 export async function getListLicence(argUserId){

  let filter = {
    userId: {eq: argUserId}
  };
  return API.graphql({
      query: queryListLicence, 
      variables: { filter: filter }
  })

}

/**
 * SELECT: ライセンス認証履歴リスト取得
 * 
 * @param {*} argLicenceId
 */
 export async function getListLicenceBind(argLicenceId){
  return API.graphql(
    graphqlOperation(queryListLicenceBind, { 
      licenceId: argLicenceId
    })
  )
}

/**
 * Update: ライセンス管理数
 * - 何者でもないユーザに紐付ける
 * 
 * @param {*} argId 
 */
 export async function logicalDeleteLicenceLimit(argId){
  return API.graphql(
    graphqlOperation(updateLicenceLimit, { 
        input: {
          'id': argId,
          'userId': EXTRA.USER_ID,
          'active': false
        }
  }))
}

/**
 * Update: ライセンス
 * - 何者でもないユーザに紐付ける
 * 
 * @param {*} argId 
 */
export async function logicalDeleteLicence(argId){
  return API.graphql(
    graphqlOperation(updateLicence, { 
        input: {
          'id': argId,
          'userId': EXTRA.USER_ID,
          'active': false
        }
  }))
}

/**
 * Update: ライセンス認証履歴
 * - 何者でもないユーザに紐付ける
 * 
 * @param {*} argId 
 * @param {*} argNum 
 */
 export async function logicalDeleteLicenceBind(argId){
  return API.graphql(
    graphqlOperation(updateLicenceBind, { 
        input: {
          'id': argId,
          'licenceId': EXTRA.LICENCE_ID
        }
  }))
}

/**
 * Delete: ユーザ情報
 * 
 * @param {*} argInput
 */
 export const physicalDeleteUser = async(argInput) =>{
  return API.graphql(
    graphqlOperation(deleteUser, { 
        input: argInput
  }))
}
