export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        licenceLimit {
          nextToken
        }
        licence {
          nextToken
          items {
            active
            id
            owner
            type
            userId
          }
        }
        username
        mailaddress
        companyName
        corp
        active
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;

export const listUserByUpdatedAt = /* GraphQL */ `
  query ListUserByUpdatedAt(
    $owner: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserByUpdatedAt(
      owner: $owner
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        licenceLimit {
          nextToken
        }
        licence {
          nextToken
          items {
            active
            id
            owner
            type
            userId
          }
        }
        username
        mailaddress
        companyName
        corp
        active
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;