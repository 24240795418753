import { API, graphqlOperation } from 'aws-amplify'
import { getSimpleUser as User } from './queries'
import { updateUserContact, updateUserName, updateUserActive } from './mutations'

/**
 * SELECT: ユーザ取得
 * 
 * @param {*} argId 
 */
export const getSimpleUser = async(argUserId) => {
  return API.graphql(
    graphqlOperation(User, { 
      id: argUserId
    })
  )
}

/**
 * Update: ユーザ連絡先
 * 
 * @param {*} argInput
 */
export const putUserContact = async(argInput) =>{
  return API.graphql(
    graphqlOperation(updateUserContact, { 
        input: argInput
  }))
}

/**
 * Update: ユーザアクティブ
 * 
 * @param {*} argInput
 */
 export const putUserActive = async(argInput) =>{
  return API.graphql(
    graphqlOperation(updateUserActive, { 
        input: argInput
  }))
}

/**
 * Update: ユーザ名
 * 
 * @param {*} argInput
 */
 export const putUserName = async(argInput) =>{
  return API.graphql(
    graphqlOperation(updateUserName, { 
        input: argInput
  }))
}