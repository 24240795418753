import React, { useState, useEffect, useRef, useContext } from 'react'
import { I18n } from 'aws-amplify'
import { LicenceLimitContext } from './../../../user/context/LicenceLimit'
import { LicenceVersionContext } from './context/LicenceVersion'

import { ConstantEquals, LICENCE, LICENCE_TYPE, SORT_DIRECTION } from './../../../utils/constants'
import { isObject, isNull, isUndefined } from './../../../utils/utils'

import { getLicences, getListForAcsVersion } from './api/Licence'
import { Row, Col, Table, Spinner } from 'react-bootstrap'
import { SortDown, SortUp } from 'react-bootstrap-icons';
import { LicenceFilter } from './LicenceFilter'
import { Add as LicenceAdd } from './Add'
import { Record as LicenceRecord } from './Record'

export const Licence = (props) => {
  const { userId } = props
  const limitNum = useContext(LicenceLimitContext)

  const liRef = useRef()
  const [load, setLoad] = useState(false)
  const [licenceData, setLicenceData] = useState([])
  const [count, setCount] = useState({
    use: 0
  })
  // filter: 入力ワードではなく、入力ワードを精査したものを格納する
  // 描画に関わらないのでRefでも良い気がする
  const [filter, setFilter] = useState({'active': true})
  const [sort, setSort] = useState({
    'key':LICENCE.LABEL.UPDATED_AT,
    'direction': SORT_DIRECTION.DESC
  })
  const [nextToken, setNextToken] = useState(null)
  const [nextRead,  setNextRead] = useState(false)

  // ACSバージョンリスト
  const [currentLicenceVersion, setCurrentLicenceVersion] = useState([])
  // 最新のACSバージョンID
  const [latestLicenceVersionId, setLatestLicenceVersionId] = useState(null)

  // 初期化
  const init = () =>{

    setNextRead(false)
    setNextToken(null)
    setCount({
      use: 0
    })
    setLicenceData([])
    setCurrentLicenceVersion([])
  }

  // 初回マウント
  useEffect(()=> {
    // Stateのメモリリーク対策
    let isMounted = true;
    // 取得
    (async() => {
      // Todo: Promiseでライセンス（検索・追加・詳細）に使うライセンスリストとバージョンリストを取得する

      // 複数のタスクを非同期で実行する
      const tasks = [];

      // ライセンス情報
      tasks.push(getLicences(userId, filter))

      // ACSバージョンリスト
      tasks.push(getListForAcsVersion())

      try{
        if (isMounted) setLoad(true)

        // 一括取得
        const [response1, response2] = await Promise.all(tasks);

        // 取得した情報をparseする
        const parseLicenceData = parseLicences(response1.data.listLicenceByUpdatedAt)

        if(!parseLicenceData.done){
          if (isMounted){
            setLicenceData([]);
            setLoad(false)
          }
          return;
        }

        // 取得した情報をparseする
        if (isMounted){
          // ライセンス
          setLicenceData(parseLicenceData.items)
          setNextToken(parseLicenceData.nextToken)
          setNextRead(false)

          // バージョンリスト
          setCurrentLicenceVersion(response2.data.listAcsVersions.items)

          // ロード解除
          setLoad(false)
        }

      }
      catch(err){
        console.log(err)
      }
    })()
    return () => { 
      // マウントされていない場合はフラグをクリーンにする（setStateさせない）
      isMounted = false 
    };
  }, [])

  useEffect(() => {
    if(!isObject(licenceData)) return;
    typeCount(licenceData)
  }, [licenceData]);

  // userdashboardに通知
  useEffect(() => {
    props.applyLicenceNum(count)
  }, [count]);

  // 検索実行
  useEffect(() => {
      // 取得
      fetchLicences();
  }, [filter])

  // LicenceOpti =====
  // 現在のライセンス数カウント
  const typeCount = (argData) =>{
    // 初期化
    let cCount = { use: 0 }
    if(!isObject(argData)) return;
    argData.forEach(licence => {
      if(ConstantEquals(licence.type, LICENCE_TYPE.USE)) cCount.use++;
    })
    setCount(cCount)
  }

  // 
  useEffect(() => {
    if(!isObject(currentLicenceVersion)) return;

    // アクティブ
    const activeList = currentLicenceVersion.filter(i => {
      return i.active
    })
    const late = activeList.find((val) => {
      return val.no === Math.max.apply(null, activeList.map(function(o){
        return o.no;
      }))
    })

    if(isUndefined(late)) return;
    setLatestLicenceVersionId(late.id)
  },[currentLicenceVersion]);

  // 上限のみチェック
  // - 追加・更新共有
  const limitCheck = (argType) => {
    let res = true;
    const cType = argType;
    // 変更の場合は、タイプがない場合がある
    if(typeof cType === 'undefined') return res;

    if(ConstantEquals(cType, LICENCE_TYPE.USE) && count.use >= limitNum.use){
      res = false;
    }

    return res
  }

  // API =====
  // 取得： ライセンスリスト。追加・更新後に実行
  const fetchLicences = async() => {
    setLoad(true)
    setLicenceData([]);

    try{
      const fetchLicences = await getLicences(userId, filter, 'DESC', null)

      // 取得した情報をparseする
      const parseLicenceData = parseLicences(fetchLicences.data.listLicenceByUpdatedAt)

      if(!parseLicenceData.done){
        setLicenceData([]);
        setLoad(false)
        return;
      }

      setLicenceData(parseLicenceData.items)
      setNextToken(parseLicenceData.nextToken)
      setNextRead(false)
      setLoad(false)
    }
    catch(err){
      console.log(err)
      setLoad(false)
    }
  }

  // 取得したユーザデータのパース
  const parseLicences = (argLicences) =>{

    const parseData = {
      done: false,
      errors: {},
      items: [],
      nextToken: null
    };

    // No Dataの場合
    if(isNull(argLicences) || argLicences.length === 0){
      parseData.done = true;
      return parseData;
    }

    parseData.items = argLicences.items.map((value, index) => {

      let model = {}
      Object.entries(value).map(([key, value]) => {
        // ID
        if(ConstantEquals(key, LICENCE.LABEL.ID)){
          model[LICENCE.TABLE_COLUMN.ID] = value;
        }

        // USER_ID
        if(ConstantEquals(key, LICENCE.LABEL.USER_ID)){
          model[LICENCE.TABLE_COLUMN.USER_ID] = value;
        }

        // 種別
        if(ConstantEquals(key, LICENCE.LABEL.TYPE)){
          model[LICENCE.TABLE_COLUMN.TYPE] = value;
        }

        // ライセンスキー
        if(ConstantEquals(key, LICENCE.LABEL.LICENCE_KEY)){
          model[LICENCE.TABLE_COLUMN.LICENCE_KEY] = value;
        }

        // UUID
        if(ConstantEquals(key, LICENCE.LABEL.UUID)){
          model[LICENCE.TABLE_COLUMN.UUID] = value;
        }

        // 購入種別
        if(ConstantEquals(key, LICENCE.LABEL.PERIOD)){
          model[LICENCE.TABLE_COLUMN.PERIOD] = value;
        }

        // 有効期限
        if(ConstantEquals(key, LICENCE.LABEL.SUBSCRIPTION_EXPIRY_DATE_AT)){
          model[LICENCE.TABLE_COLUMN.SUBSCRIPTION_EXPIRY_DATE_AT] = value;
        }

        // 保証期限
        if(ConstantEquals(key, LICENCE.LABEL.MAINTENANCE_PERIOD)){
          model[LICENCE.TABLE_COLUMN.MAINTENANCE_PERIOD] = value;
        }

        // バージョン
        if(ConstantEquals(key, LICENCE.LABEL.ACS_VERSION)){
          model[LICENCE.TABLE_COLUMN.ACS_VERSION_ID] = value.id;
          model[LICENCE.TABLE_COLUMN.ACS_VERSION_NO] = value.no;
          model[LICENCE.TABLE_COLUMN.ACS_VERSION_NAME] = value.version;
          model[LICENCE.TABLE_COLUMN.ACS_VERSION_RELEASE_DATE] = value.releaseDate;
        }

        // アクティブ
        if(ConstantEquals(key, LICENCE.LABEL.ACTIVE)){
          model[LICENCE.TABLE_COLUMN.ACTIVE] = value;
        }

        // 更新日
        if(ConstantEquals(key, LICENCE.LABEL.UPDATED_AT)){
          model[LICENCE.TABLE_COLUMN.UPDATED_AT] = value;
        }

        // 作成日
        if(ConstantEquals(key, LICENCE.LABEL.CREATED_AT)){
          model[LICENCE.TABLE_COLUMN.CREATED_AT] = value;
        }

        // ACSバージョン
        if(ConstantEquals(key, LICENCE.LABEL.LICENCE)){
          model[LICENCE.TABLE_COLUMN.LICENCE_NUM] = value.items.length;
        }

      })

      return model;
    }, parseData.items)

    parseData.nextToken = argLicences.nextToken
    parseData.done = true;
    return parseData;
  }

  // 認証履歴を更に読み込む対応
  const addFetchLicences= async() => {
    setNextRead(true)

    try{
      const fetchLicences = await getLicences(userId, filter, 'DESC', nextToken)

      // 取得した情報をparseする
      const parseLicenceData = parseLicences(fetchLicences.data.listLicenceByUpdatedAt)

      if(!parseLicenceData.done){
        setLicenceData([]);
        setLoad(false)
        return;
      }

      // 現在のbindListに追加する
      setLicenceData([...licenceData, ...parseLicenceData.items])
      setNextToken(parseLicenceData.nextToken)
      setNextRead(false)
    }
    catch(err){
      console.log(err)
      setNextRead(false)
    }

  }

  const licenceDetailModalShow = (argShow = false, argLicenceId = null) => {
    liRef.current.modalShow(argShow, argLicenceId)
  }

  // ソートを切り替える
  const changeSort = (argKey, argDir) => {
    setSort({
      'key': argKey,
      'direction': argDir
    })

    // -> sort実行
  }

  // ソートアイコンを返す
  const resSortIcon = (argKey) => {
    // カレントソートとそれ以外で分ける
    // 引数keyと現在のsort.keyが一致した場合
    if(sort.key === argKey){
      // 現在昇順
      if(sort.direction === SORT_DIRECTION.ASC){
        return <SortUp style={{cursor: 'pointer'}} color="black" size={12} onClick={evt => changeSort(argKey, SORT_DIRECTION.DESC)} />
      }
      // 降順
      else if(sort.direction === SORT_DIRECTION.DESC){
        return <SortDown style={{cursor: 'pointer'}} color="black" size={12} onClick={evt => changeSort(argKey, SORT_DIRECTION.ASC)} />
      }
    }
    // keyが一致しなかった場合
    else{
      return <SortDown style={{cursor: 'pointer'}} color="gray" size={12} onClick={evt => changeSort(argKey, SORT_DIRECTION.DESC)} />
    }

  }

  // ソート
  const sortable = (a, b) =>  {

    // 昇順
    if( sort.direction === SORT_DIRECTION.ASC){
      if (a[sort.key] < b[sort.key]) return SORT_DIRECTION.DESC;
      if (a[sort.key] > b[sort.key]) return SORT_DIRECTION.ASC;
      return 0;
    }
    // 降順
    else if(sort.direction === SORT_DIRECTION.DESC){
      if (a[sort.key] > b[sort.key]) return SORT_DIRECTION.DESC;
      if (a[sort.key] < b[sort.key]) return SORT_DIRECTION.ASC;
      return 0;
    }

  }

  /**
   * ライセンスバージョンリストの更新
   * 
   * @param {*} argObj 
   */
   const applyLicenceVersion = (argObj) => {
    setCurrentLicenceVersion(argObj)
  }

  return (
    <>
      <LicenceVersionContext.Provider value={currentLicenceVersion}>
        <Row>
          <Col xs={8} md={8} >
            <h2>{I18n.get('component_title_licence_list')}</h2>
          </Col>
          <Col xs={4} md={4} >
            <div className="d-flex justify-content-end">
              <LicenceAdd 
                userId={userId}
                count={count}
                limitCheck={limitCheck}
                fetchLicences={fetchLicences}
                verId={latestLicenceVersionId} />
            </div>
          </Col>
        </Row>

        {/* 検索 */}
        <LicenceFilter 
          filter={setFilter} 
          nextToken={setNextToken}
          nextRead={setNextRead}
          load={load}
           />
           {/* versionData={versionData} */}

        {/* 一覧 */}
        <Row>
          <Col xs={12} md={12} className="mt-3">
            {load ? (
              <div className="text-center">
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            ) : 
            !isObject(licenceData) ? (
              <>{I18n.get('general_no_data')}</>
            ):(
              <Table striped bordered hover responsive className="text-nowrap">
                <thead>
                  <tr>
                    <th></th>
                    <th>{I18n.get('table_column_licence_licence_key')}{` `}{resSortIcon(LICENCE.TABLE_COLUMN.LICENCE_KEY)}</th>
                    <th>{I18n.get('table_column_licence_uuid')}{` `}{resSortIcon(LICENCE.TABLE_COLUMN.UUID)}</th>
                    <th>{I18n.get('table_column_licence_period')}{` `}{resSortIcon(LICENCE.TABLE_COLUMN.PERIOD)}</th>
                    <th>{I18n.get('table_column_licence_subscription_expiry_date_at')}{` `}{resSortIcon(LICENCE.TABLE_COLUMN.SUBSCRIPTION_EXPIRY_DATE_AT)}</th>
                    <th>{I18n.get('table_column_licence_maintenance_period')}{` `}{resSortIcon(LICENCE.TABLE_COLUMN.MAINTENANCE_PERIOD)}</th>
                    <th>{I18n.get('general_latest_update')}{` `}{resSortIcon(LICENCE.TABLE_COLUMN.UPDATED_AT)}</th>
                    <th>{I18n.get('general_create_date')}{` `}{resSortIcon(LICENCE.TABLE_COLUMN.CREATED_AT)}</th>
                  </tr>
                </thead>
                <tbody>
                  { licenceData
                  // 日付の降順
                  .sort((a,b) => {
                    if (a[LICENCE.TABLE_COLUMN.UPDATED_AT] > b[LICENCE.TABLE_COLUMN.UPDATED_AT]) return SORT_DIRECTION.DESC;
                    if (a[LICENCE.TABLE_COLUMN.UPDATED_AT] < b[LICENCE.TABLE_COLUMN.UPDATED_AT]) return SORT_DIRECTION.ASC;
                    return 0;
                  })
                  // 適用しているソート
                  .sort((a,b) => {
                    return sortable(a,b)
                  }).map((cLicence, index) => (

                    <LicenceRecord 
                      key={`lr-${index}`} 
                      licence={cLicence} 
                      index={index}
                      modalShow={licenceDetailModalShow} />

                  ))}
                </tbody>
                { nextToken ? (
                  <tfoot>
                    <tr>
                      { nextRead ? ( 
                        <td colSpan={8} className="text-center">
                          <Spinner
                            as="span"
                            animation="border"
                            role="status"
                            aria-hidden="true" />
                        </td>
                      ): (
                        <td colSpan={8} className="text-center styleLiceRecd"
                          onClick={() => addFetchLicences()} >
                          {I18n.get('general_paging')}
                        </td>
                      ) }
                    </tr>
                  </tfoot>
                ) : (<></>)}
              </Table>
            )}
          </Col>
        </Row>
      </LicenceVersionContext.Provider>
    </>
   )
}