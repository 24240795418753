import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'

export const InputItem = props =>{

  return (
     <Form.Group as={Row} className="mb-3" controlId={`form-${props.controlKey}`}>
        <Form.Label column sm={3}>
          {props.label}
          { (props.edit && props.required) && (
            <sup className="text-danger">*</sup>
          )}
        </Form.Label>
        <Col sm={9}>
          { props.edit ? (
            <>
              <Form.Control 
                type={props.formType} 
                disabled={props.load}
                placeholder={`${props.label}を入力`}
                defaultValue={props.value}
                onChange={props.onChange} />
              <Form.Text className="text-muted">
                {props.help}
              </Form.Text>
              <div className="text-danger">
                {props.error}
              </div>
            </>
          ) : (
          <div className="col-form-label d-flex align-items-center justify-content-start" >
            {props.value}
          </div>
          )}
        </Col>
      </Form.Group>

  )
}