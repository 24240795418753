import React, { useState, useEffect, useContext } from 'react'
import { I18n } from "aws-amplify";

import { Row, Col, Card  } from 'react-bootstrap'
import { isNull } from './../utils/utils'

export const Corp = (props) => {

  return (
    <>
      { !isNull(props.corp) ? (
        <Card>
          <Card.Header>
            <Row>
              <Col sm={12} md={12}>
                {I18n.get('sub_title_user_corp')}
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col sm={12} md={12}>
                {props.corp}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ):(
        <></>
      )}
    </>
  )

}