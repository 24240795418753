import { API, graphqlOperation} from 'aws-amplify'
import { createUser } from './../../../graphql/mutations'

/**
 * Insert: ユーザ情報
 * 
 * @param {*} argInput
 */
 export const postUser = async(argInput) =>{
  return API.graphql(
    graphqlOperation(createUser, { 
        input: argInput
  }))
}
