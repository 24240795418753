import React, { useContext } from 'react';
import { Link } from 'react-router-dom'
import { Auth } from 'aws-amplify'

import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Navbar, Nav } from 'react-bootstrap'

import UserContext from './UserContext'
import { SIGN } from './utils/constants'

/**
 * サインアウト
 */
 const signOut = () => {
  Auth.signOut()
    .then(() => {
      this.props.history.push({ 
        pathname:  '/', 
        state: { 
          notice: SIGN.OUT.SUCCESS  
        }
      })
    })
    .catch(() => console.log('error signing out...'))
}

export const Header = () => {
  const { user } = useContext(UserContext);

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Nav>
            <Link className="nav-link" to='/dashboard'>
              {user.username}
            </Link>
          </Nav>
          <Nav>
            <Nav.Link onClick={signOut}>
              サインアウト
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </>  
  );
}

export const Footer = () => {
  return (
    <>
      <Navbar fixed="bottom" bg="dark" variant="dark" expand="lg" >
        <Container className="justify-content-md-center">
          <Navbar.Text>
              © 2021 Copyright: NSi moka
          </Navbar.Text>
        </Container>
      </Navbar>
    </>  
  );
}