
// 時刻
import 'moment/locale/ja'
import moment from 'moment';

/**
 * JST基準に変換して返す
 * 
 * @param {string} dateTimeStr YYYY-MM-DDTHH:mm:00Z
 * @returns {moment.Moment}
 */
 export const parseAsMoment = (dateTimeStr) => {
  return moment.utc(dateTimeStr, 'YYYY-MM-DDTHH:mm:00Z', 'ja').utcOffset(9)
}

/**
 * 日付形式に変換して返す
 * 
 * @param {moment.Moment} momentInstance
 * @returns {string}
 */
 export const toUtcIso8601str = (momentInstance) => {
  return momentInstance
    .clone()
    .utc()
    .format('YYYY-MM-DDTHH:mm:00Z')
}

/**
 * 引数：argYear年後の日付を返す
 */
export const getDateNextY = (argYear) => {
  let date = new Date();
  date.setFullYear(date.getFullYear() + argYear)
  return parseAsMoment(date).format('YYYY-MM-DD')
}

/**
 * 引数：argDateに引数：argMonth月後の日付を返す
 */
 export const getDateNextM = (argDate, argMonth) => {
  let date = new Date(argDate);
  date.setMonth(date.getMonth() + argMonth)
  return parseAsMoment(date).format('YYYY-MM-DD')
}

/**
 * 引数：argDateに引数：argDay日後の日付を返す
 */
 export const getDateNextD = (argDate, argDay) => {
  let date = new Date(argDate);
  date.setDate(date.getDate() + argDay)
  return parseAsMoment(date).format('YYYY-MM-DD')
}



