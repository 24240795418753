import { I18n } from "aws-amplify";

export function ConstantEquals(argConst, target){
  return argConst === target;
}

export const const2Msg = (argConst) => {
  let res = argConst

  switch(argConst){
    // サインイン・サインアウト
    case SIGN.COGNITO.NOT_AUTHORIZED_EXCEPTION: res = "ユーザ名もしくはパスワードが間違っています"; break;
    case SIGN.COGNITO.USER_LAMBDA_VALICATION_EXCEPTION: res = "該当ユーザはシステムから無効化されています"; break;
    case SIGN.COGNITO.USER_NOT_CONFIRMED_EXCEPTION: res = "ユーザ登録処理中です"; break;
    case SIGN.COGNITO.PASSWORD_RESET_EXCEPTION: res = "該当ユーザのパスワードは無効化されています"; break;

        // 定数変換"ライセンス使用"
    case LICENCE_TYPE.USE: res = I18n.get('constant_licence_use'); break;
    case LICENCE_PERIOD.PERPETUAL: res = I18n.get('constant_licence_period_perpetual'); break;
    case LICENCE_PERIOD.SUBSCRIPTION: res = I18n.get('constant_licence_period_subscription'); break;
    case LICENCE_AUTH.AUTH: res = I18n.get('constant_licence_auth_auth'); break;
    case LICENCE_AUTH.UN_AUTH: res = I18n.get('constant_licence_auth_un_auth'); break;

    default: res = argConst; break;
  }
  return res
}

export const EXTRA = {
  USER_ID: 'USER-XXXXX-99999',
  LICENCE_ID: 'LICENCE-XXXXX-99999'
}

export const SIGN = {
  COGNITO: {
    // ユーザ名、パスワードが間違っている
    NOT_AUTHORIZED_EXCEPTION: 'NotAuthorizedException',
    // ユーザ検証例外
    USER_LAMBDA_VALICATION_EXCEPTION: 'UserLambdaValidationException',
    // 検証コードを入力する作業が完了していないユーザ
    USER_NOT_CONFIRMED_EXCEPTION: 'UserNotConfirmedException',
    // パスワードのリセットが必要
    PASSWORD_RESET_EXCEPTION: 'PasswordResetException'
  },
  IN: {
    SUCCESS: 'SIGN_IN_SUCCESS',
    FAILED: 'SIGN_IN_FAILED'
  },
  OUT: {
    SUCCESS: 'SIGN_OUT_SUCCESS',
    FAILED: 'SIGN_OUT_FAILED'
  }
}

export const USER = {
  LABEL: {
    ID: 'id',
    USERNAME: 'username',
    MAILADDRESS: 'mailaddress',
    TELEPHONE_NUMBER: 'telephoneNumber',
    POSTAL_CODE: 'postalCode',
    ADDRESS: 'address',
    COMPANY_NAME: 'companyName',
    ACTIVE: 'active',
    LICENCE: 'licence',
    CORP: 'corp',
    UPDATED_AT: 'updatedAt',
    CREATED_AT: 'createdAt'
  },
  TABLE_COLUMN: {
    ID: 'id',
    USERNAME: 'username',
    TELEPHONE_NUMBER: 'telephoneNumber',
    ADDRESS: 'address',
    COMPANY_NAME: 'companyName',
    MAILADDRESS: 'mailaddress',
    LICENCE_NUM: 'licenceNum',
    ACTIVE: 'active',
    CORP: 'corp',
    UPDATED_AT: 'updatedAt',
    CREATED_AT: 'createdAt'
  },
  ADD: {
    SUCCESS: 'USER_ADD_SUCCESS',
    FAILED: 'USER_ADD_FAILED'
  },
  UPDATE: {
    SUCCESS: 'USER_UPDATE_SUCCESS',
    FAILED: 'USER_UPDATE_FAILED'
  },
  VALIDATE: {
    CLEAR: 'USER_VALIDATE_CLEAR',
    USERNAME: {
      ILLEGAL: 'USER_VALIDATE_USERNAME_ILLEGAL',
      ALLOW_SPACE: 'USER_VALIDATE_USERNAME_ALLOW_SPACE',
      REGULAR: 'USER_VALIDATE_USERNAME_REGULAR',
      SPECIAL_CHARACTER: 'USER_VALIDATE_USERNAME_SPECIAL_CHARACTER',
    },
    MAILADDRESS: {
      ALLOW_SPACE: 'USER_VALIDATE_MAILADDRESS_ALLOW_SPACE',
      ILLEGAL: 'USER_VALIDATE_MAILADDRESS_ILLEGAL',
      REGULAR: 'USER_VALIDATE_MAILADDRESS_REGULAR'
    },
    TELEPHONE_NUMBER: {
      ALLOW_SPACE: 'USER_VALIDATE_TELEPHONE_NUMBER_ALLOW_SPACE',
      ILLEGAL: 'USER_VALIDATE_TELEPHONE_NUMBER_ILLEGAL',
      REGULAR: 'USER_VALIDATE_TELEPHONE_NUMBER_REGULAR'
    },
    POSTAL_CODE: {
      ALLOW_SPACE: 'USER_VALIDATE_POSTAL_CODE_ALLOW_SPACE',
      ILLEGAL: 'USER_VALIDATE_POSTAL_CODE_ILLEGAL',
      REGULAR: 'USER_VALIDATE_POSTAL_CODE_REGULAR'      
    },
    ADDRESS: {
      ALLOW_SPACE: 'USER_VALIDATE_ADDRESS_ALLOW_SPACE',
      ILLEGAL: 'USER_VALIDATE_ADDRESS_ILLEGAL',
      REGULAR: 'USER_VALIDATE_ADDRESS_REGULAR'
    },
    COMPANY_NAME: {
      ILLEGAL: 'USER_VALIDATE_COMPANY_NAME_ILLEGAL',
      REGULAR: 'USER_VALIDATE_COMPANY_NAME_REGULAR'
    },
    ACTIVE: {
      ILLEGAL: 'USER_VALIDATE_ACTIVE_ILLEGAL',
    }
  }
}

export const LICENCE = {
  LABEL: {
    ID: 'id',
    USER_ID: 'userId',
    TYPE: 'type',
    PERIOD: 'period',
    SUBSCRIPTION_EXPIRY_DATE_AT: 'subscriptionExpiryDateAt',
    MAINTENANCE_PERIOD: 'maintenancePeriod',
    LICENCE_KEY: 'licenceKey',
    UUID: 'uuid',
    ACS_VERSION_ID: 'acsVersionId',
    ACS_VERSION: 'acsVersion',
    ACTIVE: 'active',
    UPDATED_AT: 'updatedAt',
    CREATED_AT: 'createdAt'
  },
  TABLE_COLUMN: {
    ID: 'id',
    USER_ID: 'userId',
    TYPE: 'type',
    PERIOD: 'period',
    SUBSCRIPTION_EXPIRY_DATE_AT: 'subscriptionExpiryDateAt',
    MAINTENANCE_PERIOD: 'maintenancePeriod',
    LICENCE_KEY: 'licenceKey',
    UUID: 'uuid',
    ACS_VERSION_ID: 'acsVersionId',
    ACS_VERSION_NO: 'acsVersionNo',
    ACS_VERSION_NAME: 'acsVersionName',
    ACS_VERSION_RELEASE_DATE: 'acsVersionReleaseDate',
    ACS_VERSION_ACTIVE: 'acsVersionActive',
    ACTIVE: 'active',
    UPDATED_AT: 'updatedAt',
    CREATED_AT: 'createdAt'

  },
  ADD: {
    SUCCESS: 'LICENCE_ADD_SUCCESS',
    FAILED: 'LICENCE_ADD_FAILED'
  },
  UPDATE: {
    SUCCESS: 'LICENCE_UPDATE_SUCCESS',
    FAILED: 'LICENCE_UPDATE_FAILED'
  },
  VALIDATE: {
    CLEAR: 'LICENCE_VALIDATE_CLEAR',
    LICENCE_TYPE: {
      NO_SELECTED: 'LICENCE_VALIDATE_LICENCE_TYPE_NO_SELECTED',
      ILLEGAL: 'LICENCE_VALIDATE_LICENCE_TYPE_ILLEGAL'
    },
    LICENCE_PERIOD: {
      NO_SELECTED: 'LICENCE_VALIDATE_LICENCE_PERIOD_NO_SELECTED',
      ILLEGAL: 'LICENCE_VALIDATE_LICENCE_PERIOD_ILLEGAL'
    },
    SUBSCRIPTION_EXPIRY_DATE_AT: {
      ALLOW_SPACE: 'LICENCE_VALIDATE_SUBSCRIPTION_EXPIRY_DATE_AT_ALLOW_SPACE',
      ILLEGAL: 'LICENCE_VALIDATE_SUBSCRIPTION_EXPIRY_DATE_AT_ILLEGAL',
      REGULAR: 'LICENCE_VALIDATE_SUBSCRIPTION_EXPIRY_DATE_AT_REGULAR'
    },
    LICENCE_KEY: {
      ALLOW_SPACE: 'LICENCE_VALIDATE_LICENCE_KEY_ALLOW_SPACE',
      ILLEGAL: 'LICENCE_VALIDATE_LICENCE_KEY_ILLEGAL',
      REGULAR: 'LICENCE_VALIDATE_LICENCE_KEY_REGULAR',
      BIND: 'LICENCE_VALIDATE_LICENCE_KEY_BIND'
    },
    UUID: {
      ILLEGAL: 'LICENCE_VALIDATE_UUID_ILLEGAL',
      REGULAR: 'LICENCE_VALIDATE_UUID_REGULAR',
      UN_BIND: 'LICENCE_VALIDATE_UUID_UN_BIND',
      BIND: 'LICENCE_VALIDATE_UUID_BIND'
    },
    ACTIVE: {
      ILLEGAL: 'LICENCE_VALIDATE_ACTIVE_ILLEGAL',
    },
    ACS_VERSION: {
      NO_SELECTED: 'LICENCE_VALIDATE_ACS_VERSION_NO_SELECTED',
      ILLEGAL: 'LICENCE_VALIDATE_ACS_VERSION_ILLEGAL'
    },
    MAINTENANCE_PERIOD: {
      ALLOW_SPACE: 'LICENCE_VALIDATE_MAINTENANCE_PERIOD_ALLOW_SPACE',
      ILLEGAL: 'LICENCE_VALIDATE_MAINTENANCE_PERIOD_ILLEGAL',
      REGULAR: 'LICENCE_VALIDATE_MAINTENANCE_PERIOD_REGULAR',
      NOT_EQUALS_SUBSCRIPTION_EXPIRY_DATE_AT: 'LICENCE_VALIDATE_MAINTENANCE_PERIOD_NOT_EQUALS_SUBSCRIPTION_EXPIRY_DATE_AT',
      NO_EXISTS_SUBSCRIPTION_EXPIRY_DATE_AT: 'LICENCE_VALIDATE_MAINTENANCE_PERIOD_NO_EXISTS_SUBSCRIPTION_EXPIRY_DATE_AT',
    },
  }
}

export const LICENCE_LIMIT = {
  NUM: 30,
  LABEL: {
    ACTIVE: 'active',
    UPDATED_AT: 'updatedAt',
    CREATED_AT: 'createdAt'
  },
  VALIDATE: {
    CLEAR: 'LICENCE_VALIDATE_CLEAR',
    LICENCE_TYPE: {
      ILLEGAL: 'LICENCE_LIMIT_VALIDATE_LICENCE_TYPE_ILLEGAL',
      REGULAR: 'LICENCE_LIMIT_VALIDATE_LICENCE_TYPE_REGULAR',
      OVERFLOW: 'LICENCE_LIMIT_VALIDATE_LICENCE_TYPE_OVERFLOW',
    }
  }
}

export const LICENCE_BIND = {
  LABEL: {
    AUTH: 'auth',
    DEVICE: 'device',
    UUID: 'uuid',
    UPDATED_AT: 'updatedAt',
    CREATED_AT: 'createdAt'
  },
  VALUE: {
    AUTH: 'LICENCE_VALUE_AUTH',
    UN_AUTH: 'LICENCE_VALUE_UN_AUTH'
  }
}

export const ACS_VERSION = {
  LABEL: {
    ID: 'id',
    VERSION: 'version',
    RELEASE_DATE: 'releaseDate',
    ACTIVE: 'active',
    UPDATED_AT: 'updatedAt',
    CREATED_AT: 'createdAt'
  },
}

export const LICENCE_TYPE = {
  USE: 'use'
}

export const LICENCE_PERIOD = {
  PERPETUAL: 'perpetual',
  SUBSCRIPTION: 'subscription'
}

export const LICENCE_AUTH = {
  AUTH: 'auth',
  UN_AUTH: 'unAuth'
}

export const SORT_DIRECTION = {
  ASC: 1,
  DESC: -1,
}

export const DATE_CONJUNCTION = {
  START_SUFFIX: 'T00:00:00.000Z',
  END_SUFFIX: 'T23:59:59.999Z'
}

export const ACTIVE = {
  NONE: '0',
  ON: '1',
  OFF: '2'
}

export const MAINTENANCE_PLUS = {
  DEFAULT: 0,
  PLUS: 30
}