import React from 'react'
import { I18n } from "aws-amplify";
import { Button, Spinner } from 'react-bootstrap'

export const SubmitButton = props =>{

  return (
    <>
      {
        props.isLoading ? ( 
          <Button variant="primary" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />{' '}
            {I18n.get('general_update_loading')}
          </Button>
        ) : (
          <Button
            variant="primary" 
            disabled={props.disabled}
            type="submit" >
              {I18n.get('general_update')}
          </Button>
        )
      }
    </>
  )
}