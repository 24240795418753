import React from 'react'
import { Link } from 'react-router-dom'
import { I18n } from "aws-amplify";

import { isNull, isUndefined } from './../../utils/utils'
import { parseAsMoment } from './../../utils/date'

/**
 * レコード
 * 
 * @param {*} props 
 * @returns 
 */
export const UserRecord = (props) => {

  // 拡張機能の有無
  const isCorp = () => {
    return !isUndefined(props.user.corp) && props.user.corp && !isNull(props.user.corp)
  }

  return (

      <tr>
        <td className="text-center">
        { props.user.active ? (
            <span className="badge bg-success">{I18n.get('general_active')}</span>
        ) : (
            <span className="badge bg-danger">{I18n.get('general_inactive')}</span>
        )}
        </td>
        <td>
          <Link to={'/user/' + props.user.id} >
            {props.user.username}
          </Link>
          { isCorp() ? ( 
            <>
              {' '}<span className="badge bg-success">{I18n.get('table_column_user_corp')}</span> 
            </> ) : ( <></> ) }
        </td>
        <td>
          {props.user.companyName}
        </td>
        <td>
          {props.user.mailaddress}
        </td>
        <td className="text-end">
          {props.user.licenceNum}
        </td>
        <td>
          {parseAsMoment(props.user.updatedAt).format('YYYY年MM月DD日 HH:mm')}
        </td>
        <td>
          {parseAsMoment(props.user.createdAt).format('YYYY年MM月DD日 HH:mm')}
        </td>
      </tr>

   );
}
