/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      owner
      licenceLimit {
        items {
          id
          userId
          type
          num
          active
          updatedAt
          createdAt
          owner
        }
        nextToken
      }
      licence {
        items {
          id
          userId
          type
          period
          subscriptionExpiryDateAt
          maintenancePeriod
          acsVersionId
          licenceKey
          uuid
          active
          updatedAt
          createdAt
          owner
        }
        nextToken
      }
      username
      mailaddress
      telephoneNumber
      postalCode
      address
      companyName
      corp
      active
      updatedAt
      createdAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      owner
      licenceLimit {
        items {
          id
          userId
          type
          num
          active
          updatedAt
          createdAt
          owner
        }
        nextToken
      }
      licence {
        items {
          id
          userId
          type
          period
          subscriptionExpiryDateAt
          maintenancePeriod
          acsVersionId
          licenceKey
          uuid
          active
          updatedAt
          createdAt
          owner
        }
        nextToken
      }
      username
      mailaddress
      telephoneNumber
      postalCode
      address
      companyName
      corp
      active
      updatedAt
      createdAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      owner
      licenceLimit {
        items {
          id
          userId
          type
          num
          active
          updatedAt
          createdAt
          owner
        }
        nextToken
      }
      licence {
        items {
          id
          userId
          type
          period
          subscriptionExpiryDateAt
          maintenancePeriod
          acsVersionId
          licenceKey
          uuid
          active
          updatedAt
          createdAt
          owner
        }
        nextToken
      }
      username
      mailaddress
      telephoneNumber
      postalCode
      address
      companyName
      corp
      active
      updatedAt
      createdAt
    }
  }
`;
export const createLicence = /* GraphQL */ `
  mutation CreateLicence(
    $input: CreateLicenceInput!
    $condition: ModelLicenceConditionInput
  ) {
    createLicence(input: $input, condition: $condition) {
      id
      userId
      licenceBind {
        items {
          id
          licenceId
          uuid
          device
          auth
          version
          updatedAt
          createdAt
          owner
        }
        nextToken
      }
      type
      period
      subscriptionExpiryDateAt
      maintenancePeriod
      acsVersionId
      acsVersion {
        id
        no
        version
        releaseDate
        active
        updatedAt
        createdAt
      }
      licenceKey
      uuid
      active
      updatedAt
      createdAt
      owner
    }
  }
`;
export const updateLicence = /* GraphQL */ `
  mutation UpdateLicence(
    $input: UpdateLicenceInput!
    $condition: ModelLicenceConditionInput
  ) {
    updateLicence(input: $input, condition: $condition) {
      id
      userId
      licenceBind {
        items {
          id
          licenceId
          uuid
          device
          auth
          version
          updatedAt
          createdAt
          owner
        }
        nextToken
      }
      type
      period
      subscriptionExpiryDateAt
      maintenancePeriod
      acsVersionId
      acsVersion {
        id
        no
        version
        releaseDate
        active
        updatedAt
        createdAt
      }
      licenceKey
      uuid
      active
      updatedAt
      createdAt
      owner
    }
  }
`;
export const deleteLicence = /* GraphQL */ `
  mutation DeleteLicence(
    $input: DeleteLicenceInput!
    $condition: ModelLicenceConditionInput
  ) {
    deleteLicence(input: $input, condition: $condition) {
      id
      userId
      licenceBind {
        items {
          id
          licenceId
          uuid
          device
          auth
          version
          updatedAt
          createdAt
          owner
        }
        nextToken
      }
      type
      period
      subscriptionExpiryDateAt
      maintenancePeriod
      acsVersionId
      acsVersion {
        id
        no
        version
        releaseDate
        active
        updatedAt
        createdAt
      }
      licenceKey
      uuid
      active
      updatedAt
      createdAt
      owner
    }
  }
`;
export const createLicenceLimit = /* GraphQL */ `
  mutation CreateLicenceLimit(
    $input: CreateLicenceLimitInput!
    $condition: ModelLicenceLimitConditionInput
  ) {
    createLicenceLimit(input: $input, condition: $condition) {
      id
      userId
      type
      num
      active
      updatedAt
      createdAt
      owner
    }
  }
`;
export const updateLicenceLimit = /* GraphQL */ `
  mutation UpdateLicenceLimit(
    $input: UpdateLicenceLimitInput!
    $condition: ModelLicenceLimitConditionInput
  ) {
    updateLicenceLimit(input: $input, condition: $condition) {
      id
      userId
      type
      num
      active
      updatedAt
      createdAt
      owner
    }
  }
`;
export const deleteLicenceLimit = /* GraphQL */ `
  mutation DeleteLicenceLimit(
    $input: DeleteLicenceLimitInput!
    $condition: ModelLicenceLimitConditionInput
  ) {
    deleteLicenceLimit(input: $input, condition: $condition) {
      id
      userId
      type
      num
      active
      updatedAt
      createdAt
      owner
    }
  }
`;
export const createLicenceBind = /* GraphQL */ `
  mutation CreateLicenceBind(
    $input: CreateLicenceBindInput!
    $condition: ModelLicenceBindConditionInput
  ) {
    createLicenceBind(input: $input, condition: $condition) {
      id
      licenceId
      uuid
      device
      auth
      version
      updatedAt
      createdAt
      owner
    }
  }
`;
export const updateLicenceBind = /* GraphQL */ `
  mutation UpdateLicenceBind(
    $input: UpdateLicenceBindInput!
    $condition: ModelLicenceBindConditionInput
  ) {
    updateLicenceBind(input: $input, condition: $condition) {
      id
      licenceId
      uuid
      device
      auth
      version
      updatedAt
      createdAt
      owner
    }
  }
`;
export const deleteLicenceBind = /* GraphQL */ `
  mutation DeleteLicenceBind(
    $input: DeleteLicenceBindInput!
    $condition: ModelLicenceBindConditionInput
  ) {
    deleteLicenceBind(input: $input, condition: $condition) {
      id
      licenceId
      uuid
      device
      auth
      version
      updatedAt
      createdAt
      owner
    }
  }
`;
export const createAcsVersion = /* GraphQL */ `
  mutation CreateAcsVersion(
    $input: CreateAcsVersionInput!
    $condition: ModelAcsVersionConditionInput
  ) {
    createAcsVersion(input: $input, condition: $condition) {
      id
      no
      version
      releaseDate
      active
      updatedAt
      createdAt
    }
  }
`;
export const updateAcsVersion = /* GraphQL */ `
  mutation UpdateAcsVersion(
    $input: UpdateAcsVersionInput!
    $condition: ModelAcsVersionConditionInput
  ) {
    updateAcsVersion(input: $input, condition: $condition) {
      id
      no
      version
      releaseDate
      active
      updatedAt
      createdAt
    }
  }
`;
export const deleteAcsVersion = /* GraphQL */ `
  mutation DeleteAcsVersion(
    $input: DeleteAcsVersionInput!
    $condition: ModelAcsVersionConditionInput
  ) {
    deleteAcsVersion(input: $input, condition: $condition) {
      id
      no
      version
      releaseDate
      active
      updatedAt
      createdAt
    }
  }
`;
export const createNotice = /* GraphQL */ `
  mutation CreateNotice(
    $input: CreateNoticeInput!
    $condition: ModelNoticeConditionInput
  ) {
    createNotice(input: $input, condition: $condition) {
      id
      title
      content
      active
      updatedAt
      createdAt
    }
  }
`;
export const updateNotice = /* GraphQL */ `
  mutation UpdateNotice(
    $input: UpdateNoticeInput!
    $condition: ModelNoticeConditionInput
  ) {
    updateNotice(input: $input, condition: $condition) {
      id
      title
      content
      active
      updatedAt
      createdAt
    }
  }
`;
export const deleteNotice = /* GraphQL */ `
  mutation DeleteNotice(
    $input: DeleteNoticeInput!
    $condition: ModelNoticeConditionInput
  ) {
    deleteNotice(input: $input, condition: $condition) {
      id
      title
      content
      active
      updatedAt
      createdAt
    }
  }
`;
