import { API, graphqlOperation} from 'aws-amplify'
import { EXTRA } from '../../utils/constants'


// licenceBindはlicenceから複数
const queryListLicenceBind = /* GraphQL */ `
  query LicenceBindByLicenceId(
    $licenceId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLicenceBindFilterInput
    $limit: Int
    $nextToken: String
  ) {
    LicenceBindByLicenceId(
      licenceId: $licenceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        licenceId
        updatedAt
        owner
      }
      nextToken
    }
  }
`;

// userIdとactiveとownerを変更する
const updateLicence = /* GraphQL */ `
  mutation UpdateLicence(
    $input: UpdateLicenceInput!
    $condition: ModelLicenceConditionInput
  ) {
    updateLicence(input: $input, condition: $condition) {
      id
      userId
      type
      period
      subscriptionExpiryDateAt
      maintenancePeriod
      acsVersionId
      licenceKey
      uuid
      active
      updatedAt
      createdAt
      owner
    }
  }
`;

// userIdとactiveとownerを変更する
const updateLicenceBind = /* GraphQL */ `
  mutation UpdateLicenceBind(
    $input: UpdateLicenceBindInput!
    $condition: ModelLicenceBindConditionInput
  ) {
    updateLicenceBind(input: $input, condition: $condition) {
      id
      licenceId
      uuid
      device
      auth
      updatedAt
      createdAt
      owner
    }
  }
`;

// ==================================================

/**
 * SELECT: ライセンス認証履歴リスト取得
 * 
 * @param {*} argLicenceId
 */
 export async function getListLicenceBind(argLicenceId){
  return API.graphql(
    graphqlOperation(queryListLicenceBind, { 
      licenceId: argLicenceId
    })
  )
}

/**
 * Update: ライセンス
 * - 何者でもないユーザに紐付ける
 * 
 * @param {*} argId 
 */
export async function logicalDeleteLicence(argId){
  return API.graphql(
    graphqlOperation(updateLicence, { 
        input: {
          'id': argId,
          'userId': EXTRA.USER_ID,
          'active': false
        }
  }))
}

/**
 * Update: ライセンス認証履歴
 * - 何者でもないユーザに紐付ける
 * 
 * @param {*} argId 
 * @param {*} argNum 
 */
 export async function logicalDeleteLicenceBind(argId){
  return API.graphql(
    graphqlOperation(updateLicenceBind, { 
        input: {
          'id': argId,
          'licenceId': EXTRA.LICENCE_ID
        }
  }))
}