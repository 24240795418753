import React, { useState, useEffect } from 'react'
import { I18n } from 'aws-amplify'
import { putSuccessLicence as ToastPutSuccess, putErrorLicence as ToastPutError} from './../utils/toast';

import {Button, Spinner, Modal, Form, Alert} from 'react-bootstrap'

import { ConstantEquals, LICENCE } from './../utils/constants'
import { ValidMessage, ValidActive } from './utils/Validate'
import { putLicenceActive } from './api/Licence'

export const Active = (props) => {

  const active = props.active

  // 入力値
  const CONFIRM_ACTIVE_WORD = "active";
  const CONFIRM_INACTIVE_WORD = "inactive";

  const [show, setShow] = useState(false)
  const [load, setLoad] = useState(false)
  const [allow, setAllow] = useState(true)

  // data以外初期化
  const init = ()=>{
    setShow(false)
    setLoad(false)
    setAllow(true)
  }

  /**
   * モーダルを閉じる
   * 
   * @param {*} argShow 
   * @returns 
   */
  const close = (argShow) => {
    if(load) return;
    setShow(argShow)
    setAllow(true)
  }

  // 更新： アクティブ
  const changeActive = async (event) => {
    setLoad(true)

    const param = {
      id: props.licenceId,
      active: event
    }

    try {
      // 更新
      const updateLicenceActive = await putLicenceActive(param);

      // 結果の画面反映
      changeSuffix(updateLicenceActive)
    } 
    catch (error) {
      ToastPutError()
      init()
    }

  }

  // 更新後処理
  const changeSuffix = (argRes) => {
    const parseData = parse(argRes.data.updateLicence)
    if(!parseData.done){
      ToastPutError()
      setLoad(false)
      return;
    }

    ToastPutSuccess()
    // 初期状態にする
    init()

    // 親コンポーネントに最終更新日の更新値を通知する
    props.doneUpdatedAt(parseData.updatedAt)
    props.doneUpdateActive(parseData.active)
  }

  // パース：取得データ
  const parse = (argLicence) =>{

    const parseData = {
      done: false,
      errors: "",
      updatedAt: "",
      active: true
    };

    // No Dataの場合
    if(argLicence === null){
      parseData.done = true;
      return parseData;
    }

    Object.entries(argLicence).map(([key, value]) => {

      // アクティブ
      if(ConstantEquals(key, LICENCE.LABEL.ACTIVE)){
        const vActive = ValidActive(value)
        if (!ConstantEquals(vActive, LICENCE.VALIDATE.CLEAR) ) {
          parseData.errors[LICENCE.LABEL.ACTIVE] = ValidMessage(vActive);
        }else{
          parseData.active = argLicence.active;
        }
      }

      // 更新日
      if(ConstantEquals(key, LICENCE.LABEL.UPDATED_AT)){
        parseData.updatedAt = argLicence.updatedAt;
      }
    })

    parseData.done = true;
    return parseData;
  }

  // ボタンの有効化
  const confirmInputChange = (arg) =>{
    setAllow(arg === (active ? CONFIRM_INACTIVE_WORD : CONFIRM_ACTIVE_WORD) ? false : true)
  }

  return (
    <>
      <Button variant={active ? "danger" : "success"} onClick={() => close(true)}>
        { active ? 
          I18n.get('sub_title_licence_active_disabled') : 
          I18n.get('sub_title_licence_active_enabled') }
      </Button>

      <InactiveModal
        active={active}
        show={show}
        allow={allow}
        load={load}
        word={(active ? CONFIRM_INACTIVE_WORD : CONFIRM_ACTIVE_WORD)}
        onClick={() => changeActive(!active)}
        onHide={() => close(false)}
        onChange={(argWord) => confirmInputChange(argWord)}
      />

    </>
  )
}

/**
 * モーダル： Active
 * @param {*} props 
 * @returns 
 */
const InactiveModal = (props) => {

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.active ? 
              I18n.get('sub_title_licence_active_disabled') : 
              I18n.get('sub_title_licence_active_enabled') }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {`${I18n.get('general_confirm_prefix')}
              ${props.word}
              ${I18n.get('general_confirm_suffix')}`}
          </p>
          <Form.Group className="mb-3" controlId="formConfirm">
            <Form.Control 
            type="text" 
            disabled={props.load}
            placeholder={props.active ? "Enter inactive" : "Enter active"}
            onChange={(evt) => props.onChange(evt.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <>
            {
              !props.load ? ( 
                <>
                <Button variant={props.active ? "danger" : "success"} 
                  onClick={props.onClick} 
                  disabled={props.allow}
                  >
                  {props.active ? 
                    I18n.get('form_licence_active_disabled') : 
                    I18n.get('form_licence_active_enabled') }
                </Button>
                {' '}
                <Button variant="secondary" onClick={props.onHide}>
                  {I18n.get('general_cancel')}
                </Button>
                </>
              ) : (
                <>
                  <Button variant={props.active ? "danger" : "success"} disabled>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true" />{' '}
                    {props.active ? 
                      I18n.get('form_licence_active_disabled') : 
                      I18n.get('form_licence_active_enabled') }
                  </Button>
                  {' '}
                  <Button variant="secondary" disabled>
                    {I18n.get('general_cancel')}
                  </Button>
                </>
              )
            }
          </>
        </Modal.Footer>
      </Modal>
    </>
  );
}