import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { I18n } from 'aws-amplify'

const property = {
  position: toast.POSITION.BOTTOM_RIGHT,
  autoClose: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: false,
  hideProgressBar: true,
  draggable: true,
  pauseOnHover: true,
}

// サインイン：成功
export const signInSuccess = () => toast.success(I18n.get('toast_sign_in_success'), property);
// サインイン：失敗
export const signInError = () => toast.error(I18n.get('toast_sign_in_error'), property);

// サインアウト：成功
export const signOutSuccess = () => toast.success(I18n.get('toast_sign_out_success'), property);
// サインアウト：失敗
export const signOutError = () => toast.error(I18n.get('toast_sign_out_error'), property);

// ユーザ情報：登録成功
export const postSuccessUserDetail = () => toast.success(I18n.get('toast_user_detail_post_success'), property);
// ユーザ情報：登録失敗
export const postErrorUserDetail = () => toast.error(I18n.get('toast_user_detail_post_error'), property);

// ユーザ情報：更新成功
export const putSuccessUserDetail = () => toast.success(I18n.get('toast_user_detail_put_success'), property);
// ユーザ情報：更新失敗
export const putErrorUserDetail = () => toast.error(I18n.get('toast_user_detail_put_error'), property);

// ユーザ情報：削除成功
export const deleteSuccessUser = () => toast.success(I18n.get('toast_user_delete_success'), property);
// ユーザ情報：削除失敗
export const deleteErrorUser = () => toast.error(I18n.get('toast_user_delete_error'), property);

// ライセンス管理数：更新成功
export const putSuccessLicenceLimit = () => toast.success(I18n.get('toast_licence_limit_put_success'), property);
// ライセンス管理数：更新失敗
export const putErrorLicenceLimit = () => toast.error(I18n.get('toast_licence_limit_put_error'), property);
// 利用ライセンス：バリデーションエラー
export const validErrorLicenceLimit = (argMsg) => toast.warn(argMsg, property);

// ライセンス：登録成功
export const postSuccessLicence = () => toast.success(I18n.get('toast_licence_post_success'), property);
// ライセンス：登録失敗
export const postErrorLicence = () => toast.error(I18n.get('toast_licence_post_error'), property);

// ライセンス：更新成功
export const putSuccessLicence = () => toast.success(I18n.get('toast_licence_put_success'), property);
// ライセンス：更新失敗
export const putErrorLicence = () => toast.error(I18n.get('toast_licence_put_error'), property);

// ライセンス：削除成功
export const deleteSuccessLicence = () => toast.success(I18n.get('toast_licence_delete_success'), property);
// ライセンス：削除失敗
export const deleteErrorLicence = () => toast.error(I18n.get('toast_licence_delete_error'), property);
