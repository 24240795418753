import { API, graphqlOperation} from 'aws-amplify'
import { getLicence as Licence, listAcsVersions } from './../../../../../graphql/queries'
import { getLicenceSimple as LicenceSimple, listLicenceByUpdatedAtSimple as Licences } from './queries'
import { createLicence, updateLicence } from './../../../../../graphql/mutations'

import { isUndefined, isStringType } from './../../../../utils/utils'

/**
 * Insert: ライセンス登録
 * 
 * @param {*} argInput 
 * @returns 
 */
export async function postLicence(argInput) {
  return API.graphql(
    graphqlOperation(createLicence,
      { 
          input: argInput
      })
  )
}

/**
 * Update: ライセンス更新
 * 
 * @param {*} argInput 
 * @returns 
 */
export async function putLicence(argInput) {
  return API.graphql(
    graphqlOperation(updateLicence,
      { 
          input: argInput
      })
  )
}

/**
 * get: ACSバージョン（保守期間）
 */
export async function getListForAcsVersion(){
  return API.graphql(graphqlOperation(listAcsVersions))
}


/**
 * Update: ライセンス認証解除
 * 
 * @param {*} argInput 
 * @returns 
 */
 export async function putLicenceBind(argInput) {
  return API.graphql(
    graphqlOperation(updateLicence,
      { 
          input: argInput
      })
  )
}

/**
 * SELECT: ライセンス取得
 * 
 * @param {*} argId 
 */
export async function getLicence(argId){
  return API.graphql(
    graphqlOperation(Licence, { 
      id: argId
    })
  )
}

/**
 * SELECT: ライセンス取得
 * - 認証履歴を除く
 * 
 * @param {*} argId 
 */
export async function getLicenceSimple(argId){
  return API.graphql(
    graphqlOperation(LicenceSimple, { 
      id: argId
    })
  )
}

/**
 * SELECT: ライセンスリスト取得
 * 
 * @param {*} argId 
 */
export async function getLicences(argUserId, {...argFilter} = {}, argSort = 'DESC', argNextToken = null){

  const queryArgument = Object.assign({},{ 
    userId: argUserId,
    filter: {},
    sortDirection: argSort,
    //limit : 30,
    nextToken: argNextToken
  })

  let queryFilter = {
    and:[]
  }

  // active
  if(!isUndefined(argFilter.active)){
    queryFilter['and'].push({'active': {
      eq: argFilter.active
    }})
  }

  // licenceKey
  if(!isUndefined(argFilter.licenceKey)){
    queryFilter['and'].push({'licenceKey': {
      contains: argFilter.licenceKey
    }})
  }

  // uuid
  if(!isUndefined(argFilter.uuid)){
    queryFilter['and'].push({'uuid': {
      contains: argFilter.uuid
    }})
  }

  // uuid:bind。UUIDがあれば認証済み判断とする
  // -> UUID:認証なし -> eq: ""でOK。containsに値があってもeqが優先される
  // -> UUID:認証あり -> ne: ""（not equals）でOK。notContainsもあるけどneを使う
  // -> UUID：認証ありでUUIDの検索を可能にする
  if(!isUndefined(argFilter.bind)){
    if(argFilter.bind === '1'){
      queryFilter['and'].push({'uuid': {
        ne: ""
      }})
    }
    else if(argFilter.bind === '2'){
      queryFilter['and'].push({'uuid': {
        eq: ""
      }})
    }
  }

  // type
  if(!isUndefined(argFilter.type)){
    queryFilter['and'].push({'type': {
      eq: argFilter.type
    }})
  }

  // period
  if(!isUndefined(argFilter.period)){
    queryFilter['and'].push({'period': {
      eq: argFilter.period
    }})
  }

  // acsVersionId（空のときは扱わない）
  if(!isUndefined(argFilter.acsVersionId) && argFilter.acsVersionId !== ""){
    queryFilter['and'].push({'acsVersionId': {
      eq: argFilter.acsVersionId
    }})
  }

  //updatedAt - gt | lt
  if(!isUndefined(argFilter.updatedAtGt) || !isUndefined(argFilter.updatedAtLt)){
    let qsu = {}
    if(!isUndefined(argFilter.updatedAtGt)) qsu['gt'] = argFilter.updatedAtGt
    if(!isUndefined(argFilter.updatedAtLt)) qsu['lt'] = argFilter.updatedAtLt
    // ModelStringKeyConditionInputで指定しているため、filterではなくArgumentで指定する
    queryArgument['updatedAt'] = qsu
  }

  // // createdAt - gt | lt
  if(!isUndefined(argFilter.createdAtGt) || !isUndefined(argFilter.createdAtLt)){
    let qsc = {}
    if(!isUndefined(argFilter.createdAtGt)) qsc['gt'] = argFilter.createdAtGt
    if(!isUndefined(argFilter.createdAtLt)) qsc['lt'] = argFilter.createdAtLt
    queryFilter['and'].push({'createdAt': qsc})
  }

  // filter条件
  if(queryFilter['and'].length === 0) queryFilter = null

  queryArgument['filter'] = queryFilter

  return API.graphql(
    graphqlOperation(Licences, queryArgument)
  )
}