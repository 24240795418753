import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { I18n } from 'aws-amplify'
import UserContext from './../../UserContext'
import { getUsers } from './api/User'

import { ConstantEquals, USER, SORT_DIRECTION } from './../../utils/constants'
import { isObject, isNull } from './../../utils/utils'

import { Container, Row, Col, Table, Button, Spinner } from 'react-bootstrap'
import { SortDown, SortUp } from 'react-bootstrap-icons';

import { UserFilter } from './UserFilter'
import { UserRecord } from './UserRecord'

export const Users = props => {
  const { user } = useContext(UserContext);

  // 非同期制御：ユーザ取得
  const [userLoad, setUserLoad] = useState(false)
  // Data: ユーザリスト
  const [userData, setUserData] = useState([])

  const [userFilter, setUserFilter] = useState({'active': true})
  const [userSort, setUserSort] = useState({
    'key': USER.TABLE_COLUMN.UPDATED_AT,
    'direction': SORT_DIRECTION.DESC
  })
  const [userNextToken, setUserNextToken] = useState(null)
  // UI: 非同期制御
  const [userNextRead,  setUserNextRead] = useState(false)

  // 初回マウント時
  useEffect(() => {
    // Stateのメモリリーク対策
    let isMounted = true;
    // 取得
    (async() => {

      try{
        if (isMounted) setUserLoad(true)

        const fetchUsers = await getUsers(user.username, userFilter, null)

        // 取得した情報をparseする
        const parseData = parseUser(fetchUsers.data.listUserByUpdatedAt)

        if(!parseData.done){
          if (isMounted){
            setUserData([]);
            setUserLoad(false)
          }
          return;
        }

        // 描画
        if (isMounted){
          setUserData(parseData.items)
          setUserNextToken(parseData.nextToken)
          setUserNextRead(false)
          setUserLoad(false)
        }

      }
      catch(err){
        console.log(err)
      }

    })()
    return () => { 
      // マウントされていない場合はフラグをクリーンにする（setStateさせない）
      isMounted = false 
    };
  }, []);

  // 検索実行
  useEffect(() => {
    // 検索用取得
    fetchUsers();
  }, [userFilter])

  // 件数の取得
  const fetchUsers = async() => {
    setUserLoad(true)
    setUserData([]);

    try{
      const fetchUsers = await getUsers(user.username, userFilter, null)

      // 取得した情報をparseする
      const parseData = parseUser(fetchUsers.data.listUserByUpdatedAt)

      if(!parseData.done){
        setUserData([]);
        setUserLoad(false)
        return;
      }

      setUserData(parseData.items)
      setUserNextToken(parseData.nextToken)
      setUserNextRead(false)
      setUserLoad(false)
    }
    catch(err){
      console.log(err)
      setUserLoad(false)
    }
  }

  // 認証履歴を更に読み込む対応
  const addFetchUsers = async() => {
    setUserNextRead(true)

    try{
      const fetchUsers = await getUsers(user.username, userFilter, userNextToken)

      // 取得した情報をparseする
      const parseData = parseUser(fetchUsers.data.listUserByUpdatedAt)

      // 現在のbindListに追加する
      setUserData([...userData, ...parseData.items])
      setUserNextToken(parseData.nextToken)
      setUserNextRead(false)
    }
    catch(err){
      console.log(err)
      setUserNextRead(false)
    }

  }

  // 取得したユーザデータのパース
  const parseUser = (users) =>{

    const parseData = {
      done: false,
      errors: {},
      items: [],
      nextToken: null
    };

    // No Dataの場合
    if(isNull(users) || users.length === 0){
      parseData.done = true;
      return parseData;
    }

    parseData.items = users.items.map((value, index) => {

      let model = {}
      Object.entries(value).map(([key, value]) => {
        // ID
        if(ConstantEquals(key, USER.LABEL.ID)){
          model[USER.TABLE_COLUMN.ID] = value;
        }

        // ユーザ名
        if(ConstantEquals(key, USER.LABEL.USERNAME)){
          model[USER.TABLE_COLUMN.USERNAME] = value;
        }

        // 会社名
        if(ConstantEquals(key, USER.LABEL.COMPANY_NAME)){
          model[USER.TABLE_COLUMN.COMPANY_NAME] = value;
        }

        // メールアドレス
        if(ConstantEquals(key, USER.LABEL.MAILADDRESS)){
          model[USER.TABLE_COLUMN.MAILADDRESS] = value;
        }

        // アクティブ
        if(ConstantEquals(key, USER.LABEL.ACTIVE)){
          model[USER.TABLE_COLUMN.ACTIVE] = value;
        }

        // 更新日
        if(ConstantEquals(key, USER.LABEL.UPDATED_AT)){
          model[USER.TABLE_COLUMN.UPDATED_AT] = value;
        }

        // 作成日
        if(ConstantEquals(key, USER.LABEL.CREATED_AT)){
          model[USER.TABLE_COLUMN.CREATED_AT] = value;
        }

        // 管理ライセンス数
        if(ConstantEquals(key, USER.LABEL.LICENCE)){
          model[USER.TABLE_COLUMN.LICENCE_NUM] = value.items.length;
        }

        // 管理ライセンス数
        if(ConstantEquals(key, USER.LABEL.CORP)){
          model[USER.TABLE_COLUMN.CORP] = value;
        }

      })

      return model;
    }, parseData.items)

    parseData.nextToken = users.nextToken
    parseData.done = true;
    return parseData;
  }

  // ソートを切り替える
  const changeUserSort = (argKey, argDir) => {
    setUserSort({
      'key': argKey,
      'direction': argDir
    })
  }

  // ソートアイコンを返す
  const resSortIcon = (argKey) => {
    // カレントソートとそれ以外で分ける
    // 引数keyと現在のsort.keyが一致した場合
    if(userSort.key === argKey){
      // 現在昇順
      if(userSort.direction === SORT_DIRECTION.ASC){
        return <SortUp style={{cursor: 'pointer'}} color="black" size={12} onClick={evt => changeUserSort(argKey, SORT_DIRECTION.DESC)} />
      }
      // 降順
      else if(userSort.direction === SORT_DIRECTION.DESC){
        return <SortDown style={{cursor: 'pointer'}} color="black" size={12} onClick={evt => changeUserSort(argKey, SORT_DIRECTION.ASC)} />
      }
    }
    // keyが一致しなかった場合
    else{
      return <SortDown style={{cursor: 'pointer'}} color="gray" size={12} onClick={evt => changeUserSort(argKey, SORT_DIRECTION.DESC)} />
    }

  }

  // ソート
  const userSortable = (a, b) =>  {
    // 昇順
    if( userSort.direction === SORT_DIRECTION.ASC){
      if (a[userSort.key] < b[userSort.key]) return SORT_DIRECTION.DESC;
      if (a[userSort.key] > b[userSort.key]) return SORT_DIRECTION.ASC;
      return 0;
    }
    // 降順
    else if(userSort.direction === SORT_DIRECTION.DESC){
      if (a[userSort.key] > b[userSort.key]) return SORT_DIRECTION.DESC;
      if (a[userSort.key] < b[userSort.key]) return SORT_DIRECTION.ASC;
      return 0;
    }
  }

  return(
    <>
      {/* User List */}
      <Container>

        <Row >
          <Col xs={8} md={8} >
            <h2>{I18n.get('title_user_list')}</h2>
          </Col>
          <Col xs={4} md={4} >
            <div className="text-end" >
              <Link to='/user' >
                <Button variant="primary" type="submit">
                  {I18n.get('title_user_add')}
                </Button>
              </Link>
            </div>
          </Col>
        </Row>

        <UserFilter
          filter={setUserFilter} 
          nextToken={setUserNextToken}
          nextRead={setUserNextRead}
          load={userLoad} />

        <Row >
          <Col xs={12} md={12} className="mt-3">
          {userLoad ? (
            <div className="text-center">
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            </div>
          ) : 
          !isObject(userData) ? (
            <>{I18n.get('general_no_data')}</>
          ):(
            <Table striped bordered hover responsive className="text-nowrap">
              <thead>
                <tr>
                  <th>{I18n.get('general_status')}{` `}{resSortIcon(USER.TABLE_COLUMN.ACTIVE)}</th>
                  <th>{I18n.get('table_column_user_username')}{` `}{resSortIcon(USER.TABLE_COLUMN.USERNAME)}</th>
                  <th>{I18n.get('table_column_user_company_name')}{` `}{resSortIcon(USER.TABLE_COLUMN.COMPANY_NAME)}</th>
                  <th>{I18n.get('table_column_user_mailaddress')}{` `}{resSortIcon(USER.TABLE_COLUMN.MAILADDRESS)}</th>
                  <th>{I18n.get('table_column_user_licence_num')}{` `}{resSortIcon(USER.TABLE_COLUMN.LICENCE_NUM)}</th>
                  <th>{I18n.get('general_latest_update')}{` `}{resSortIcon(USER.TABLE_COLUMN.UPDATED_AT)}</th>
                  <th>{I18n.get('general_create_date')}{` `}{resSortIcon(USER.TABLE_COLUMN.CREATED_AT)}</th>
                </tr>
              </thead>
              <tbody>
                <>
                  { userData
                    // 日付の降順
                    .sort((a,b) => {
                      if (a[USER.TABLE_COLUMN.UPDATED_AT] > b[USER.TABLE_COLUMN.UPDATED_AT]) return SORT_DIRECTION.DESC;
                      if (a[USER.TABLE_COLUMN.UPDATED_AT] < b[USER.TABLE_COLUMN.UPDATED_AT]) return SORT_DIRECTION.ASC;
                      return 0;
                    })
                    // 適用しているソート
                    .sort((a,b) => {
                      return userSortable(a,b)
                    })
                    .map((cUser, index) => (
                      <UserRecord
                        key={`ur-${index}`}
                        user={cUser} 
                        index={index} />
                  ))}
                </>
              </tbody>
              { userNextToken ? (
                <tfoot>
                  <tr>
                    { userNextRead ? ( 
                      <td colSpan={8} className="text-center">
                        <Spinner
                          as="span"
                          animation="border"
                          role="status"
                          aria-hidden="true" />
                      </td>
                    ): (
                      <td colSpan={8} className="text-center styleLiceRecd"
                        onClick={() => addFetchUsers()} >
                        {I18n.get('general_paging')}
                      </td>
                    ) }
                  </tr>
                </tfoot>
              ) : (<></>)}
            </Table>
          )}
          </Col>
        </Row>
      </Container>
    </>
  );
}