// 時刻
import 'moment/locale/ja'

import React, { useState, useEffect } from 'react'
import { I18n } from "aws-amplify";
import { Row, Col, Form } from 'react-bootstrap'
import { parseAsMoment } from './../../../../utils/date'

export const MaintenancePeriodPlus = props =>{

  useEffect(() => {
    if(!props.input) return;
  }, [])

  const handleChange = (argFlg) => {
    props.onChange(argFlg)
  }

  return (
    <>
      <Row >
        <Col xs={{ span: 7, offset: 5 }} md={{ span:7, offset: 5 }}>
          <Form.Group  className="mb-3" controlId="formMaintenancePeriodPlus">
            <Form.Check
              type="checkbox" 
              label={props.label}
              disabled={props.load}
              checked={props.input}
              onChange={evt => handleChange(evt.target.checked)}  />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={5} md={5} className="text-end">
          <strong>{I18n.get('label_licence_maintenance_period_apply')}</strong>
        </Col>
        <Col xs={7} md={7}>
          <strong>
            <ins>
              {parseAsMoment(props.applyMaintenanceDate).format('YYYY年MM月DD日')}
            </ins>
          </strong>
        </Col>
      </Row>
    </>
  )
}