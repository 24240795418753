import React from 'react'
import { I18n } from "aws-amplify";
import { LICENCE } from './../../../../utils/constants'

import { Row, Col, Spinner, Button, Form, FormControl, InputGroup } from 'react-bootstrap'
import { Key } from 'react-bootstrap-icons';

export const LicenceKey = props =>{

  return (
    <Form.Group as={Row} className="mb-3" controlId="formLicenceKey">
        <Form.Label column sm={5}>
          {I18n.get('label_licence_key')}
          { (props.edit && props.required) && (
            <sup className="text-danger">*</sup>
          )}
        </Form.Label>
        <Col sm={7}>
        { props.edit ? (
            <>
              <div className="col-form-label d-flex align-items-center justify-content-start">
                { props.value ? ( 
                  <>{props.value}</> 
                ) : props.apiLoad ?(
                  <div className="text-muted">{I18n.get('general_loading')}</div>
                ) : (
                  <div className="text-muted">{I18n.get('help_licence_licence_key')}</div>
                )}
              </div>
              { props.apiLoad ? (
                  <Button 
                    variant="outline-secondary" 
                    disabled >
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true" />
                  </Button>
                ) : (
                  <Button 
                    variant="outline-secondary" 
                    id="button-addon2"
                    onClick={(evt) => props.onClick()} >
                    <Key color="gray" size={14} />{' '}
                    {I18n.get('general_generator')}
                  </Button>
                )
              }
              <div className="text-danger">
                {props.error}
              </div>
            </>
          ) : (
            <div className="col-form-label d-flex align-items-center justify-content-start" >
              {props.value}
            </div>
          )}

        </Col>
    </Form.Group>
  )
}