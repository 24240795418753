import { I18n } from 'aws-amplify'
import { ConstantEquals, LICENCE, LICENCE_PERIOD, LICENCE_TYPE, LICENCE_AUTH } from './../../utils/constants'

/**
 * 引数に応じたmessageを返す
 * 
 * @param {*} argConstant 
 */
export function ValidMessage(argConstant){

  let res = "";
  switch(argConstant) {
    // ライセンス種別
    case LICENCE.VALIDATE.LICENCE_TYPE.NO_SELECTED:
      res = I18n.get('validate_licence_type_no_selected');
      break;
    case LICENCE.VALIDATE.LICENCE_TYPE.ILLEGAL:
      res = I18n.get('validate_licence_type_illegal');
      break;
    // ライセンス購入種別
    case LICENCE.VALIDATE.LICENCE_PERIOD.NO_SELECTED:
      res = I18n.get('validate_licence_period_no_selected');
      break;
    case LICENCE.VALIDATE.LICENCE_PERIOD.ILLEGAL:
      res = I18n.get('validate_licence_period_illegal');
      break;
    // ライセンス期限
    case LICENCE.VALIDATE.SUBSCRIPTION_EXPIRY_DATE_AT.ILLEGAL:
      res = I18n.get('validate_licence_subscription_expiry_date_at_illegal');
      break;
    case LICENCE.VALIDATE.SUBSCRIPTION_EXPIRY_DATE_AT.ALLOW_SPACE:
      res = I18n.get('validate_licence_subscription_expiry_date_at_allow_space');
      break;
    case LICENCE.VALIDATE.SUBSCRIPTION_EXPIRY_DATE_AT.REGULAR:
      res = I18n.get('validate_licence_subscription_expiry_date_at_regular');
      break;
    // ライセンスキー
    case LICENCE.VALIDATE.LICENCE_KEY.ILLEGAL:
      res = I18n.get('validate_licence_licence_key_illegal');
      break;
    case LICENCE.VALIDATE.LICENCE_KEY.ALLOW_SPACE:
      res = I18n.get('validate_licence_licence_key_allow_space');
      break;
    case LICENCE.VALIDATE.LICENCE_KEY.REGULAR:
      res = I18n.get('validate_licence_licence_key_regular');
      break;
    case LICENCE.VALIDATE.LICENCE_KEY.BIND:
      res = I18n.get('validate_licence_licence_key_bind');
      break;
    // UUID
    case LICENCE.VALIDATE.UUID.ILLEGAL:
      res = I18n.get('validate_licence_uuid_illegal');
      break;
    case LICENCE.VALIDATE.UUID.REGULAR:
      res = I18n.get('validate_licence_uuid_regular');
      break;
    // - 解除前
    case LICENCE.VALIDATE.UUID.UN_BIND:
      res = I18n.get('validate_licence_uuid_un_bind');
      break;
    // - 解除後
    case LICENCE.VALIDATE.UUID.BIND:
      res = I18n.get('validate_licence_uuid_bind');
      break;
    // アクティブ
    case LICENCE.VALIDATE.ACTIVE.ILLEGAL:
      res = I18n.get('validate_licence_active_illegal');
      break;
    // 保守期限
    // - 空
    case LICENCE.VALIDATE.MAINTENANCE_PERIOD.ALLOW_SPACE:
      res = I18n.get('validate_licence_maintenance_period_allow_space');
      break;
    // - 不正
    case LICENCE.VALIDATE.MAINTENANCE_PERIOD.ILLEGAL:
      res = I18n.get('validate_licence_maintenance_period_illegal');
      break;
    // - 正規表現
    case LICENCE.VALIDATE.MAINTENANCE_PERIOD.REGULAR:
      res = I18n.get('validate_licence_maintenance_period_regular');
      break;
    // 有効期限と保守期限が同じではない（サブスクの場合。同時に設定はできないので、設定されていた場合不正とする）
    case LICENCE.VALIDATE.MAINTENANCE_PERIOD.NOT_EQUALS_SUBSCRIPTION_EXPIRY_DATE_AT:
      res = I18n.get('validate_licence_maintenance_period_not_equals_subscription_expiry_date_at');
      break;
    // 有効期限が空文字ではない（ワンショットの場合。買い切りの場合、有効期限は設定できないので、設定されていた場合不正とする）
    case LICENCE.VALIDATE.MAINTENANCE_PERIOD.NO_EXISTS_SUBSCRIPTION_EXPIRY_DATE_AT:
      res = I18n.get('validate_licence_maintenance_period_no_exists_subscription_expiry_date_at');
      break;
    // バージョン
    case LICENCE.VALIDATE.ACS_VERSION.ILLEGAL:
      res = I18n.get('validate_licence_version_illegal');
      break;
    case LICENCE.VALIDATE.ACS_VERSION.NO_SELECTED:
      res = I18n.get('validate_licence_version_no_selected');
      break;
    default:
      res = I18n.get('general_validate_illegal_error');
      break;
  }

  return res;
}

/**
 * Validation: ライセンス種別
 * 
 * @param {*} arg 
 * @returns 
 */
export function ValidLicenceType(arg) {

  // 未選択
  if (!arg) {
    return LICENCE.VALIDATE.LICENCE_TYPE.NO_SELECTED;
  }

  // 引数argが登録されていないLicenceTypeだった
  const someType = Object.keys(LICENCE_TYPE).some(key => {
    return LICENCE_TYPE[key] === arg
  })
  if(!someType) return LICENCE.VALIDATE.LICENCE_TYPE.ILLEGAL;

  // 異常なし
  return LICENCE.VALIDATE.CLEAR;
}

/**
 * Validation: ライセンス購入種別
 * 
 * @param {*} arg 
 * @returns 
 */
export function ValidLicencePeriod(arg) {

  // 未選択
  if (!arg) {
    return LICENCE.VALIDATE.LICENCE_PERIOD.NO_SELECTED;
  }

  // 引数argが登録されていないLicenceTypeだった
  const somePeriod = Object.keys(LICENCE_PERIOD).some(key => {
    return LICENCE_PERIOD[key] === arg
  })
  if(!somePeriod) return LICENCE.VALIDATE.LICENCE_PERIOD.ILLEGAL;

  // 異常なし
  return LICENCE.VALIDATE.CLEAR;
}

/**
 * Validation: ライセンス有効期限
 * 
 * @param {*} argSubscriptionExpiryDateAt 
 * @param {*} argLicencePeriod 
 * @returns 
 */
export function ValidSubscriptionExpiryDateAt(argSubscriptionExpiryDateAt, argLicencePeriod) {

  // PERPETUALだった場合、なにもない
  if(ConstantEquals(LICENCE_PERIOD.PERPETUAL , argLicencePeriod)) return LICENCE.VALIDATE.CLEAR;

  // 存在しなかった
  if (typeof argSubscriptionExpiryDateAt === "undefined") {
    return LICENCE.VALIDATE.SUBSCRIPTION_EXPIRY_DATE_AT.ILLEGAL;
  }

  // 未入力
  if (!argSubscriptionExpiryDateAt) {
    return LICENCE.VALIDATE.SUBSCRIPTION_EXPIRY_DATE_AT.ALLOW_SPACE;
  }

  // 正規表現
  const pattern = new RegExp(/^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/);
  if (!pattern.test(argSubscriptionExpiryDateAt)) {
    return LICENCE.VALIDATE.SUBSCRIPTION_EXPIRY_DATE_AT.REGULAR;
  }

  // 異常なし
  return LICENCE.VALIDATE.CLEAR;
}

/**
 * Validation: ライセンスキ－
 * 
 * @param {*} argLicenceKey 
 * @param {*} argUuid 
 * @returns 
 */
 export function ValidLicenceKey(argLicenceKey, argUuid = undefined) {

  // UUIDが存在した場合、認証済みなので変更ができない
  if (typeof argUuid !== "undefined") {
    return LICENCE.VALIDATE.LICENCE_KEY.BIND;
  }

  // 存在しなかった
  if (typeof argLicenceKey === "undefined") {
    return LICENCE.VALIDATE.LICENCE_KEY.ILLEGAL;
  }

  // 未入力（全て0だった場合、引っかかることがある）
  if (!argLicenceKey) {
    return LICENCE.VALIDATE.LICENCE_KEY.ALLOW_SPACE;
  }

  // 正規表現
  const pattern = new RegExp(/^[ABCDEFGHJKLMNPQRSTUVWXYZ0123456789]{16}$/);
  if (!pattern.test(argLicenceKey)) {
    return LICENCE.VALIDATE.LICENCE_KEY.REGULAR;
  }

  // 異常なし
  return LICENCE.VALIDATE.CLEAR;
}

/**
 * Validation: ライセンスUUID
 * 
 * @param {*} argargUuid UUID
 * @returns 
 */
 export function ValidUuid(argUuid, argCurrentAuth) {

  // 存在しなかった
  if (typeof argUuid === "undefined") {
    return LICENCE.VALIDATE.UUID.ILLEGAL;
  }

  // 紐付いている場合、UUIDは存在している
  if(ConstantEquals(LICENCE_AUTH.AUTH, argCurrentAuth) && !argUuid){
    return LICENCE.VALIDATE.UUID.UN_BIND;
  }
  // 紐付いていない場合、UUIDが空である
  else if(ConstantEquals(LICENCE_AUTH.UN_AUTH, argCurrentAuth) && argUuid){
    return LICENCE.VALIDATE.UUID.BIND;
  }

  // 異常なし
  return LICENCE.VALIDATE.CLEAR;
}

/**
 * Validation: アクティブ
 * 
 * @param {*} arg 
 * @returns 
 */
export function ValidActive(arg) {

  // 存在しなかった
  if (typeof arg == "undefined") {
    return LICENCE.VALIDATE.ACTIVE.ILLEGAL;
  }

  // 異常なし
  return LICENCE.VALIDATE.CLEAR;
}

/**
 * Validation: バージョン
 * 
 * @param {*} arg 
 * @returns 
 */
 export function ValidAcsVersionId(arg) {

  // 未選択
  if (!arg) {
    return LICENCE.VALIDATE.ACS_VERSION.NO_SELECTED;
  }

  // 異常なし
  return LICENCE.VALIDATE.CLEAR;
}

/**
 * Validation: 保守期限
 * 
 * @param {*} argMaintenancePeriod 保守期限
 * @param {*} argLicencePeriod ライセンス購入種別
 * @param {*} argSubscriptionExpiryDateAt 有効期限
 * @returns 
 */
export function ValidMaintenancePeriod(argMaintenancePeriod, argLicencePeriod, argSubscriptionExpiryDateAt) {

  // 存在しなかった
  if (typeof argMaintenancePeriod === "undefined") {
    return LICENCE.VALIDATE.MAINTENANCE_PERIOD.ILLEGAL;
  }

  // 未入力
  if (!argMaintenancePeriod) {
    return LICENCE.VALIDATE.MAINTENANCE_PERIOD.ALLOW_SPACE;
  }

  // 正規表現
  const pattern = new RegExp(/^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/);
  if (!pattern.test(argMaintenancePeriod)) {
    return LICENCE.VALIDATE.MAINTENANCE_PERIOD.REGULAR;
  }

  // SUBSCRIPTIONだった場合、保守期限と有効期限が同じ値であることを確認する
  if(ConstantEquals(LICENCE_PERIOD.SUBSCRIPTION, argLicencePeriod) && argMaintenancePeriod !== argSubscriptionExpiryDateAt){
    return LICENCE.VALIDATE.MAINTENANCE_PERIOD.NOT_EQUALS_SUBSCRIPTION_EXPIRY_DATE_AT;
  }
  // PERPETUALの場合、有効期限が空であることを確認する
  else if(ConstantEquals(LICENCE_PERIOD.PERPETUAL, argLicencePeriod) && argSubscriptionExpiryDateAt !== ''){
    return LICENCE.VALIDATE.MAINTENANCE_PERIOD.NO_EXISTS_SUBSCRIPTION_EXPIRY_DATE_AT;
  }

  // 異常なし
  return LICENCE.VALIDATE.CLEAR;
}