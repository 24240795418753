export const vocabularies = {
  'ja': {
    // General:
    'general_cancel': 'キャンセル',
    'general_insert': '追加',
    'general_insert_loading': '追加中',
    'general_update': '更新',
    'general_update_loading': '更新中',
    'general_delete': '削除',
    'general_delete_loading': '削除中',
    'general_filter': '絞り込み',
    'general_filter_loading': '絞り込み中',
    'general_apply' : '実行',
    'general_clear': 'クリア',
    'general_select': '選択してください',
    'general_unselected': '指定なし',
    'general_active': '有効',
    'general_inactive': '無効',
    'general_enabled': '有効化',
    'general_disabled': '無効化',
    'general_support': '対応',
    'general_non_support': '非対応',
    'general_no_data': 'データなし',
    'general_status': 'ステータス',
    'general_latest_update': '最終更新日',
    'general_create_date': '作成日',
    'general_paging': '更に読み込む',
    'general_loading': '読込中...',
    'general_confirm_prefix': '確認のため、『',
    'general_confirm_suffix': ' 』を入力してください。',
    'general_generator': '生成',
    'general_date_between': '～',

    // - Validate
    'general_validate_illegal_error': '不明なエラー',

    // - Title:
    'title_dashboard': 'ダッシュボード',
    'title_user_list': 'ユーザ一覧',
    'title_user_add': 'ユーザ追加',
    'title_user_detail': 'ユーザ詳細',
    'title_licence_detail': 'ライセンス詳細',

    // User：
    // - Sub-title
    'sub_title_user_contact_information': '連絡先',
    'sub_title_user_contact_information_change': '連絡先の変更',
    'sub_title_user_active_enabled': 'ユーザを有効化',
    'sub_title_user_active_disabled': 'ユーザを無効化',
    'sub_title_user_delete': 'ユーザを削除',
    'sub_title_user_corp': '拡張機能',
    // - Label
    'label_user_username': 'ユーザ名',
    'label_user_company_name': '会社名',
    'label_user_mailaddress' : 'メールアドレス',
    'label_user_postal_code': '郵便番号',
    'label_user_address': '住所',
    'label_user_telephone_number': '電話番号',
    'label_user_corp': '拡張機能',
    // - Help
    'help_user_username': '漢字・かな・カナ・英数字・スペース・特殊文字【（）().&=\'‐・】',
    'help_user_company_name': '漢字・かな・カナ・英数字・スペース・特殊文字【（）().&=\'‐・】',
    'help_user_mailaddress': '半角英数字・特殊文字（.!#$%&\'*+/=?^_`{|}~-）',
    'help_user_postal_code': '半角数字・半角ハイフン（-）',
    'help_user_address': '漢字・かな・カナ・英数字・スペース・ハイフン（-・－）',
    'help_user_telephone_number': '半角数字・半角ハイフン（-）',
    'help_user_corp': '半角英数字',
    // - Table column
    'table_column_user_username': 'ユーザ名',
    'table_column_user_company_name': '会社名',
    'table_column_user_address': '住所',
    'table_column_user_telephone_number': '電話番号',
    'table_column_user_licence_num': '管理ライセンス数',
    'table_column_user_corp': '拡張機能',
    'table_column_user_mailaddress': 'メールアドレス',
    // - Validate
    'validate_user_username_illegal': 'ユーザ名が不正です',
    'validate_user_username_allow_space': 'ユーザ名を入力してください',
    'validate_user_username_regular': '適切なユーザ名ではありません',
    'validate_user_username_special_character': '',
    'validate_user_mailaddress_illegal': 'メールアドレスが不正です',
    'validate_user_mailaddress_allow_space': 'メールアドレスを入力してください',
    'validate_user_mailaddress_regular': '適切なメールアドレスではありません',
    'validate_user_telephone_number_illegal': '電話番号が不正です',
    'validate_user_telephone_number_allow_space': '電話番号を入力してください',
    'validate_user_telephone_number_regular': '適切な電話番号ではありません',
    'validate_user_postal_code_illegal': '郵便番号が不正です',
    'validate_user_postal_code_allow_space': '郵便番号を入力してください',
    'validate_user_postal_code_regular': '適切な郵便番号ではありません',
    'validate_user_address_illegal': '住所が不正です',
    'validate_user_address_allow_space': '住所を入力してください',
    'validate_user_address_regular': '適切な住所ではありません',
    'validate_user_company_name_illegal': '会社名が不正です',
    'validate_user_company_name_regular': '適切な会社名ではありません',
    'validate_user_active_illegal': '不正なユーザです',
    // - User Active:
    'alert_user_active_note': '※ ユーザを無効化した場合、紐づくライセンスも無効になります。',
    'form_user_active_enabled': 'ユーザを有効にする',
    'form_user_active_disabled': 'ユーザを無効にする',
    // - User Delete:
    'alert_user_delete_note': '※ ユーザを削除した場合、紐づくライセンスも無効になります。',
    'form_user_delete': 'ユーザを削除にする',
    // LicenceLimit:
    // - Title
    'sub_title_licence_limit_information': 'ライセンス登録上限',
    'sub_title_licence_limit_information_change': 'ライセンス登録上限の変更',
    // - Label
    'label_licence_limit_use': '登録上限数',
    // - Validate
    'validate_licence_limit_illegal': '登録上限数が不正です',
    'validate_licence_limit_regular': '登録上限数が不正です',
    'validate_licence_limit_overflow': '現在利用しているライセンス数より少ない数を設定することはできません',

    // Licence
    'component_title_licence_list': 'ライセンス一覧',
    'component_title_licence_add': 'ライセンス追加',
    // - Sub-title
    'sub_title_licence_information': 'ライセンス情報',
    'sub_title_licence_information_change': 'ライセンス情報の変更',
    'sub_title_licence_active_enabled': 'ライセンスを有効にする',
    'sub_title_licence_active_disabled': 'ライセンスを無効にする',
    'sub_title_licence_delete': 'ライセンスを削除',
    // - Label
    'label_licence_key': 'ライセンスキー',
    'label_licence_type': '種別',
    'label_licence_period': '購入種別',
    'label_licence_subscription_expiry_date_at': '有効期限',
    'label_licence_maintenance_period': '保守期限',
    'label_licence_acs_version': '提供時バージョン（リリース日）',
    'label_licence_uuid': 'UUID',
    'label_licence_maintenance_period_plus': '保守期限を1ヶ月分（30日）追加する',
    'label_licence_maintenance_period_apply': '登録される保守期限',

    // - Help
    'help_licence_licence_key': 'ライセンスキーを生成してください',
    'help_licence_un_bind': 'このライセンスは端末と紐付いていません',
    'help_licence_period_perpetual': '-',
    // - Table column
    'table_column_licence_licence_key': 'ライセンスキー',
    'table_column_licence_uuid': 'UUID',
    'table_column_licence_period': '購入種別',
    'table_column_licence_subscription_expiry_date_at': '有効期限',
    'table_column_licence_maintenance_period': '保守期限',
    'table_column_licence_acs_version': 'バージョン',
    'table_column_label_licence_is_auth': '認証',

    // - Validate
    'validate_licence_type_no_selected': '種別を選択してください',
    'validate_licence_type_illegal': '種別が不正です',
    'validate_licence_period_no_selected': '購入種別を選択してください',
    'validate_licence_period_illegal': '購入種別が不正です',
    'validate_licence_subscription_expiry_date_at_illegal': '有効期限が不正です',
    'validate_licence_subscription_expiry_date_at_allow_space': '有効期限を選択してください',
    'validate_licence_subscription_expiry_date_at_regular': '適切な有効期限ではありません',
    'validate_licence_licence_key_illegal': 'ライセンスキーが不正です',
    'validate_licence_licence_key_allow_space': 'ライセンスキーを生成してください',
    'validate_licence_licence_key_regular': '適切なライセンスキーではありません',
    'validate_licence_licence_key_bind': '認証済みのため、ライセンスキーの変更ができません',
    'validate_licence_uuid_illegal': 'UUIDが不正です',
    'validate_licence_uuid_regular': '適切なUUIDではありません',
    'validate_licence_uuid_un_bind': 'UUIDが存在しません',
    'validate_licence_uuid_bind': 'UUIDが存在しています',
    'validate_licence_active_illegal': '不正なライセンスです',
    'validate_licence_maintenance_period_illegal': '保守期限が不正です',
    'validate_licence_maintenance_period_allow_space': '保守期限を選択してください',
    'validate_licence_maintenance_period_regular': '適切な保守期限ではありません',
    'validate_licence_maintenance_period_not_equals_subscription_expiry_date_at': '保守期限が不正に設定されています。画面を更新してやり直してください',
    'validate_licence_maintenance_period_no_exists_subscription_expiry_date_at': '保守期限が不正に設定されています。画面を更新してやり直してください',
    'validate_licence_version_no_selected': 'バージョンを選択してください',
    'validate_licence_version_illegal': 'バージョンが不正です',

    // - Licence Active:
    'form_licence_active_enabled': 'ライセンスを有効にする',
    'form_licence_active_disabled': 'ライセンスを無効にする',

    // - Licence Delete:
    'form_licence_delete': 'ライセンスを削除にする',

    // - Error
    'error_form_licence_limit_overflow': 'ライセンス登録上限を超過しています',
    'error_api_licence_key': 'ライセンスキーの取得に失敗しました',
    'error_api_create_licence': 'ライセンス情報の登録に失敗しました',

    // Toast:
    'toast_sign_in_success': 'サインインに成功しました',
    'toast_sign_in_error': 'サインインに失敗しました',
    'toast_sign_out_success': 'サインアウトに成功しました',
    'toast_sign_out_error': 'サインアウトに失敗しました',
    'toast_user_detail_post_success': 'ユーザ情報を登録しました',
    'toast_user_detail_post_error': 'ユーザ情報の登録に失敗しました',
    'toast_user_detail_put_success': 'ユーザ情報を更新しました',
    'toast_user_detail_put_error': 'ユーザ情報の更新に失敗しました',
    'toast_user_delete_success': 'ユーザを削除しました',
    'toast_user_delete_error': 'ユーザの削除に失敗しました',
    'toast_licence_limit_put_success': 'ライセンス管理数を更新しました',
    'toast_licence_limit_put_error': 'ライセンス管理数の更新に失敗しました',
    'toast_licence_post_success': 'ライセンス情報を登録しました',
    'toast_licence_post_error': 'ライセンス情報の登録に失敗しました',
    'toast_licence_put_success': 'ライセンス情報を更新しました',
    'toast_licence_put_error': 'ライセンス情報の更新に失敗しました',
    'toast_licence_delete_success': 'ライセンスを削除しました',
    'toast_licence_delete_error': 'ライセンスの削除に失敗しました',

    // Constant:
    // - Type
    'constant_licence_use': '利用ライセンス',
    // - Period
    'constant_licence_period_perpetual': '買い切り',
    'constant_licence_period_subscription': '継続購入',
    // - Auth
    'constant_licence_auth_auth': '認証',
    'constant_licence_auth_un_auth': '認証解除',

    // LicenceBind:
    // - Sub-title
    'sub_title_licence_bind_information': '認証情報',
    'sub_title_licence_bind_history': 'ライセンス認証履歴',
    // - Label
    'label_licence_bind_un_bind_submit': '端末との紐付けを解除する',
    'label_licence_bind_bind': '認証されている（UUIDがある）',
    'label_licence_bind_un_bind': '認証されていない（UUIDがない）',
    // - Label
    'table_column_licence_bind_created_at': '応答日付',
    'table_column_licence_bind_auth': 'リクエスト',
    'table_column_licence_bind_device': '端末名',
    'table_column_licence_bind_uuid': 'UUID',
    'table_column_licence_bind_version': 'バージョン',

  }
};