import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import Amplify, { I18n } from "aws-amplify";
import { vocabularies } from "./components/utils/vocabularies";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);
I18n.putVocabularies(vocabularies);
I18n.setLanguage("ja");

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// Web Vitals計測
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
