import React, { useState, useEffect, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { I18n } from "aws-amplify";
import UserContext from './../UserContext'

import { postUser } from './api/User'

import { postSuccessUserDetail as ToastPostSuccess, postErrorUserDetail as ToastPostError} from '../utils/toast';
import { ConstantEquals, USER } from './../utils/constants'

import { Container, Row, Col, Table, Button, Modal,Breadcrumb,Form } from 'react-bootstrap'
import { Header, Footer } from './../Panels'
import { InputItem } from './form/InputItem'
import { SubmitButton } from './form/SubmitButton'

import { ValidMessage, ValidUsername, ValidMailaddress, ValidPostalCode, ValidAddress, ValidTelephoneNumber, ValidCompanyName } from './../user/Validate'

/**
 * - メールアドレスのValidationメッセージ：適切なメールアドレスではありません
 - 郵便番号のバリデーション：~~ハイフンなしを記述したほうが良い~~
- 郵便番号と電話番号のValidation：ハイフンを許容する
 */
const UserAdd = (props) => {
  const { user } = useContext(UserContext);

  const history = useHistory();

  const [apiLoad, setApiLoad] = useState(false)
  const [apiError, setApiError] = useState(null)

  const [formLoad, setFormLoad] = useState(false)
  const [input, setInput] = useState({
    'username': null,
    'owner': user.username,
    'address': "",
    'postalCode': "",
    'mailaddress': null,
    'telephoneNumber': "",
    'companyName': "",
    'active': true,
    'updatedAt': null,
    'createdAt': null
  })
  const [errors, setErrors] = useState({})

  // []指定でマウント時1回のみ発火する
  useEffect(() => {
    setInput({
      'username': null,
      'owner': user.username,
      'address': "",
      'postalCode': "",
      'mailaddress': null,
      'telephoneNumber': "",
      'companyName': "",
      'active': true,
      'updatedAt': null,
      'createdAt': null
    })
  },[]);

  // 初期化
  const init = ()=>{

    setApiLoad(false)
    setApiError(null)

    setFormLoad(false)
    setInput({
      'username': null,
      'owner': user.username,
      'address': "",
      'postalCode': "",
      'mailaddress': null,
      'telephoneNumber': "",
      'companyName': "",
      'active': true,
      'updatedAt': null,
      'createdAt': null
    })
    setErrors({})
  }

  // onChange: 入力変更
  const userInputChange = (key, value) => {
    let cInput = input
    cInput[key] = value

    // エラーの一時解除
    let cErrors = errors
    if(typeof cErrors[key] !== "undefined"){
      delete cErrors[key]
    }

    setInput(cInput)
    setErrors(cErrors)
  }

  // onSubmit: 追加前処理
  const submitInsertUser = (event) => {

    // formデフォルトの挙動をキャンセル
    event.preventDefault();
    // イベントの伝播を止める
    event.stopPropagation();

    setApiError(null)
    setFormLoad(true)

    // Validate
    const resValid = inputUserValidate();
    if(!resValid.isValid){
      setErrors(resValid.errors)
      setFormLoad(false)
      return;
    }

    // 追加
    createUser(input)

  }

  // 入力確認：ユーザ登録
  const inputUserValidate = () => {

    const res = {
      isValid: true, 
      errors: {}
    }
    
    // ユーザ名
    if(input.hasOwnProperty(USER.LABEL.USERNAME)){
      const vUsername = ValidUsername(input.username)
      if (!ConstantEquals(vUsername, USER.VALIDATE.CLEAR) ) {
        res.isValid = false;
        res.errors[USER.LABEL.USERNAME] = ValidMessage(vUsername);
      }
    }

    // 会社名
    if(input.hasOwnProperty(USER.LABEL.COMPANY_NAME)){
      const vCompanyName = ValidCompanyName(input.companyName)
      if (!ConstantEquals(vCompanyName, USER.VALIDATE.CLEAR) ) {
        res.isValid = false;
        res.errors[USER.LABEL.COMPANY_NAME] = ValidMessage(vCompanyName);
      }
    }

    // メールアドレス
    if(input.hasOwnProperty(USER.LABEL.MAILADDRESS)){
      const vMailaddress = ValidMailaddress(input.mailaddress)
      if (!ConstantEquals(vMailaddress, USER.VALIDATE.CLEAR) ) {
        res.isValid = false;
        res.errors[USER.LABEL.MAILADDRESS] = ValidMessage(vMailaddress);
      }
    }

    return res;
  }

  // Post： ユーザ
  const createUser = async (argParam) => {
    setFormLoad(true)

    try {
      // 一括登録と更新
      const insertUserDetail = await postUser(argParam);

      // 結果の画面反映
      changeSuffix(insertUserDetail)
    } 
    catch (error) {
      ToastPostError()

      setApiLoad(false)
      setApiError(null)
      setFormLoad(false)
    }

    return;
  }

  // 追加・更新後処理
  const changeSuffix = (argRes) => {

    ToastPostSuccess()
    // ダッシュボード画面に遷移
    history.push(({ 
      pathname:  '/dashboard'
    }))

  }

  return (
    <>
      <Header />
      <Container>
          <Row>
            <Col xs={12} md={12} className="mt-1">
              <Breadcrumb >
                <Breadcrumb.Item active>
                  <Link to="/dashboard">
                    {I18n.get('title_dashboard')}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {I18n.get('title_user_add')}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={12} className="mt-3 mb-2">
              <h3 className="text-break">
                {I18n.get('title_user_add')}
              </h3>
            </Col>
          </Row>

          <hr />

          <Form noValidate onSubmit={evt => submitInsertUser(evt)} > 

            <InputItem 
              edit={true}
              load={formLoad}
              formType="text"
              help={I18n.get('help_user_username')}
              required
              controlKey={USER.LABEL.USERNAME}
              label={I18n.get('label_user_username')}
              error={errors[USER.LABEL.USERNAME]}
              onChange={evt => userInputChange(USER.LABEL.USERNAME, evt.target.value)} />

            <InputItem 
              edit={true}
              load={formLoad}
              formType="text"
              help={I18n.get('help_user_company_name')}
              controlKey={USER.LABEL.COMPANY_NAME}
              label={I18n.get('label_user_company_name')}
              error={errors[USER.LABEL.COMPANY_NAME]}
              onChange={evt => userInputChange(USER.LABEL.COMPANY_NAME, evt.target.value)} />

            <InputItem 
              edit={true}
              load={formLoad}
              formType="text"
              help={I18n.get('help_user_mailaddress')}
              controlKey={USER.LABEL.MAILADDRESS}
              label={I18n.get('label_user_mailaddress')}
              error={errors[USER.LABEL.MAILADDRESS]}
              onChange={evt => userInputChange(USER.LABEL.MAILADDRESS, evt.target.value)} />

            <hr />
            <div className="text-end" >
              <SubmitButton isLoading={formLoad} disabled={false} />
            </div>
          </Form>
        </Container>
      <Footer />
    </>
  );
}

export default UserAdd