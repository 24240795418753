export const updateLicenceBind = /* GraphQL */ `
  mutation UpdateLicence(
    $input: UpdateLicenceInput!
    $condition: ModelLicenceConditionInput
  ) {
    updateLicence(input: $input, condition: $condition) {
      id
      userId
      licenceBind {
        items {
          id
          licenceId
          uuid
          device
          auth
          updatedAt
          createdAt
          owner
        }
        nextToken
      }
      uuid
      updatedAt
      owner
    }
  }
`;

// Update: アクティブ
export const updateLicenceActive = /* GraphQL */ `
  mutation UpdateLicence(
    $input: UpdateLicenceInput!
    $condition: ModelLicenceConditionInput
  ) {
    updateLicence(input: $input, condition: $condition) {
      id
      active
      updatedAt
      owner
    }
  }
`;