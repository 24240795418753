import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { const2Msg, ConstantEquals, LICENCE, LICENCE_TYPE, LICENCE_PERIOD } from './../../utils/constants'

/**
 * ライセンス種別
 * 
 * @param {*} props 
 * @returns 
 */
export const LicencePeriod = props =>{

  // 購入種別
  const createLicencePeriodSelectItems = () => {
    let items = [];
    Object.keys(LICENCE_PERIOD).map((key,index) => {
      items.push(<option key={'lp-' + index} value={LICENCE_PERIOD[key]} >{const2Msg(LICENCE_PERIOD[key])}</option>);
    });
    return items;
  }

  return (
     <Form.Group as={Row} className="mb-3" controlId={`form-${props.controlKey}`}>
        <Form.Label column sm={5}>
          {props.label}
          { (props.edit && props.required) && (
            <sup className="text-danger">*</sup>
          )}
        </Form.Label>
        <Col sm={7}>
          { props.edit ? (
            <>
              <Form.Select 
                aria-label={props.controlKey}
                disabled={props.load}
                defaultValue={props.value}
                onChange={props.onChange} >
                {createLicencePeriodSelectItems()}
              </Form.Select>

              <div className="text-danger">
                {props.error}
              </div>
            </>
          ) : (
          <div className="col-form-label d-flex align-items-center justify-content-start" >
            {const2Msg(props.value)}
          </div>
          )}
        </Col>
      </Form.Group>
  )
}