import React, { useState, useEffect, useRef } from 'react'
import { I18n } from "aws-amplify";

import { Placeholder } from 'react-bootstrap'

import { isObject } from './../../../utils/utils'
import { ConstantEquals, LICENCE_AUTH, LICENCE_BIND } from './../../../utils/constants'
import { parseAsMoment } from './../../../utils/date'

/**
 * 認証履歴レコード
 * 
 * @param {*} props 
 * @returns 
 */
 export const Record = (props) => {

  const [data, setData] = useState({})

  useEffect(()=>{
    // Stateのメモリリーク対策
    let isMounted = true;
    if(isMounted) setData(props.bind)
    return () => { 
      isMounted = false 
    };
  },[])

  const Lp = () => {
    return (
    <Placeholder as="h3" animation="glow">
      <Placeholder.Button xs={12} md={12} variant="secondary" />
    </Placeholder> );
  }

  return (
    <tr >
      <td>
        { isObject(data) ? (
          parseAsMoment(data.createdAt).format('YYYY年MM月DD日 HH:mm')
        ):( <><Lp /></> )}
      </td>
      <td className="text-center">
        { isObject(data) ? (
          I18n.get(ConstantEquals(data.auth, LICENCE_AUTH.AUTH) ? 
            'constant_licence_auth_auth' : 'constant_licence_auth_un_auth')
          ):( <><Lp /></> )}
      </td>
      <td>
        { isObject(data) ? ( data.uuid ):( <><Lp /></> )}
      </td>
      <td>
        { isObject(data) ? ( data.device ):( <><Lp /></> )}
      </td>
      <td>
        { isObject(data) ? ( data.version ):( <><Lp /></> )}
      </td>
    </tr>
   );
};