/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      owner
      licenceLimit {
        items {
          id
          userId
          type
          num
          active
          updatedAt
          createdAt
          owner
        }
        nextToken
      }
      licence {
        items {
          id
          userId
          type
          period
          subscriptionExpiryDateAt
          maintenancePeriod
          acsVersionId
          licenceKey
          uuid
          active
          updatedAt
          createdAt
          owner
        }
        nextToken
      }
      username
      mailaddress
      telephoneNumber
      postalCode
      address
      companyName
      corp
      active
      updatedAt
      createdAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        licenceLimit {
          nextToken
        }
        licence {
          nextToken
        }
        username
        mailaddress
        telephoneNumber
        postalCode
        address
        companyName
        corp
        active
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const getLicence = /* GraphQL */ `
  query GetLicence($id: ID!) {
    getLicence(id: $id) {
      id
      userId
      licenceBind {
        items {
          id
          licenceId
          uuid
          device
          auth
          version
          updatedAt
          createdAt
          owner
        }
        nextToken
      }
      type
      period
      subscriptionExpiryDateAt
      maintenancePeriod
      acsVersionId
      acsVersion {
        id
        no
        version
        releaseDate
        active
        updatedAt
        createdAt
      }
      licenceKey
      uuid
      active
      updatedAt
      createdAt
      owner
    }
  }
`;
export const listLicences = /* GraphQL */ `
  query ListLicences(
    $id: ID
    $filter: ModelLicenceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLicences(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        licenceBind {
          nextToken
        }
        type
        period
        subscriptionExpiryDateAt
        maintenancePeriod
        acsVersionId
        acsVersion {
          id
          no
          version
          releaseDate
          active
          updatedAt
          createdAt
        }
        licenceKey
        uuid
        active
        updatedAt
        createdAt
        owner
      }
      nextToken
    }
  }
`;
export const getLicenceLimit = /* GraphQL */ `
  query GetLicenceLimit($id: ID!) {
    getLicenceLimit(id: $id) {
      id
      userId
      type
      num
      active
      updatedAt
      createdAt
      owner
    }
  }
`;
export const listLicenceLimits = /* GraphQL */ `
  query ListLicenceLimits(
    $id: ID
    $filter: ModelLicenceLimitFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLicenceLimits(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        type
        num
        active
        updatedAt
        createdAt
        owner
      }
      nextToken
    }
  }
`;
export const getLicenceBind = /* GraphQL */ `
  query GetLicenceBind($id: ID!) {
    getLicenceBind(id: $id) {
      id
      licenceId
      uuid
      device
      auth
      version
      updatedAt
      createdAt
      owner
    }
  }
`;
export const listLicenceBinds = /* GraphQL */ `
  query ListLicenceBinds(
    $id: ID
    $filter: ModelLicenceBindFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLicenceBinds(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        licenceId
        uuid
        device
        auth
        version
        updatedAt
        createdAt
        owner
      }
      nextToken
    }
  }
`;
export const getAcsVersion = /* GraphQL */ `
  query GetAcsVersion($id: ID!) {
    getAcsVersion(id: $id) {
      id
      no
      version
      releaseDate
      active
      updatedAt
      createdAt
    }
  }
`;
export const listAcsVersions = /* GraphQL */ `
  query ListAcsVersions(
    $id: ID
    $filter: ModelAcsVersionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAcsVersions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        no
        version
        releaseDate
        active
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const getNotice = /* GraphQL */ `
  query GetNotice($id: ID!) {
    getNotice(id: $id) {
      id
      title
      content
      active
      updatedAt
      createdAt
    }
  }
`;
export const listNotices = /* GraphQL */ `
  query ListNotices(
    $id: ID
    $filter: ModelNoticeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNotices(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        content
        active
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const listUserByUpdatedAt = /* GraphQL */ `
  query ListUserByUpdatedAt(
    $owner: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserByUpdatedAt(
      owner: $owner
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        licenceLimit {
          nextToken
        }
        licence {
          nextToken
        }
        username
        mailaddress
        telephoneNumber
        postalCode
        address
        companyName
        corp
        active
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const licenceByLicenceKey = /* GraphQL */ `
  query LicenceByLicenceKey(
    $licenceKey: String
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLicenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    LicenceByLicenceKey(
      licenceKey: $licenceKey
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        licenceBind {
          nextToken
        }
        type
        period
        subscriptionExpiryDateAt
        maintenancePeriod
        acsVersionId
        acsVersion {
          id
          no
          version
          releaseDate
          active
          updatedAt
          createdAt
        }
        licenceKey
        uuid
        active
        updatedAt
        createdAt
        owner
      }
      nextToken
    }
  }
`;
export const listLicenceByUpdatedAt = /* GraphQL */ `
  query ListLicenceByUpdatedAt(
    $userId: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLicenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLicenceByUpdatedAt(
      userId: $userId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        licenceBind {
          nextToken
        }
        type
        period
        subscriptionExpiryDateAt
        maintenancePeriod
        acsVersionId
        acsVersion {
          id
          no
          version
          releaseDate
          active
          updatedAt
          createdAt
        }
        licenceKey
        uuid
        active
        updatedAt
        createdAt
        owner
      }
      nextToken
    }
  }
`;
export const licenceBindByLicenceId = /* GraphQL */ `
  query LicenceBindByLicenceId(
    $licenceId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLicenceBindFilterInput
    $limit: Int
    $nextToken: String
  ) {
    LicenceBindByLicenceId(
      licenceId: $licenceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        licenceId
        uuid
        device
        auth
        version
        updatedAt
        createdAt
        owner
      }
      nextToken
    }
  }
`;
export const listLicenceBindByCreatedAt = /* GraphQL */ `
  query ListLicenceBindByCreatedAt(
    $licenceId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLicenceBindFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLicenceBindByCreatedAt(
      licenceId: $licenceId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        licenceId
        uuid
        device
        auth
        version
        updatedAt
        createdAt
        owner
      }
      nextToken
    }
  }
`;
