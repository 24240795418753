import React, { Component } from 'react'
import { Auth, Hub } from 'aws-amplify'

import Router from './components/routing/Router'
import UserContext from './components/UserContext'

/**
 * Appコンポーネント
 * - https://aws-amplify.github.io/amplify-js/api/classes/authenticator.html
 */
class App extends Component {
  // コンストラクタ？
  state = {
    // ユーザ
    currentUser: {},
    // ユーザの読み込み完了
    isLoaded: false
  }
  // コンポーネントがマウントされた（ツリーに挿入された）直後に呼び出されます
  componentDidMount() {
    this.updateCurrentUser()
    // Amplify Utilities Hub
    Hub.listen('auth', this);
  }
  // チャンネルが認証、もしくはペイロードイベントがサインインではない場合、
  // ユーザ情報を更新する。デフォルトはhandleStateChangeに飛ぶ
  onHubCapsule(capsule) {
    const { channel, payload } = capsule;
    if (channel === 'auth' && payload.event !== 'signIn') {
      this.updateCurrentUser()
    }
  }

  /**
   * 使用ユーザの更新（async/await構文）
   * 
   * @param {*} user 
   * @returns 
   */
  updateCurrentUser = async (user) => {
    // 引数ユーザがあるならステータスにセットして終わり
    if (user) {
      this.setState({ currentUser: user })
      return
    }

    // ユーザ認証を行う
    try {
      // aws-amplifyの認証。現在の認証済みユーザを取得する
      const user = await Auth.currentAuthenticatedUser()
      // ステータス更新。ユーザと読み込み完了
      this.setState({ currentUser: user, isLoaded: true })
    } catch (err) {
      this.setState({ currentUser: null, isLoaded: true })
    }
  }
  render() {
    return (
      // valueの中身が変更されると再Renderする
      <UserContext.Provider value={{
        // ログインユーザ
        user: this.state.currentUser,
        // ユーザの更新
        updateCurrentUser: this.updateCurrentUser,
        // 更新完了
        isLoaded: this.state.isLoaded
      }}>
        <div className="App">
          <Router />
        </div>
      </UserContext.Provider>
    );
  }
}

// Appをexport
export default App
