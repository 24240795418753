import React from 'react'

import { I18n } from 'aws-amplify'

import { Container, Row, Col } from 'react-bootstrap'
import { Header, Footer } from '../Panels'

import { Users } from './user/Users'

const Top = () => {

  return (
    <>
      <Header />
      <>
      <Container>
        <Row >
          <Col xs={12} md={12} className="mb-4 mt-3">
            <h1>{I18n.get('title_dashboard')}</h1>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row >
          {/* Notification */}
          <Col xs={12} md={12} className="mb-4 mt-3">

          </Col>
        </Row>
      </Container>

      {/* ユーザ一覧 */}
      <Users />

      </>
      <Footer />
    </>
  )
}

export default Top