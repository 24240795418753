import React, { useState, useEffect, useContext } from 'react'
import { I18n } from "aws-amplify";
import { LicenceVersionContext } from './context/LicenceVersion'

import { isStringType, isUndefined, manualUpserCase, manualLowerCase } from '../../../utils/utils'
import { const2Msg, LICENCE_PERIOD, LICENCE, SORT_DIRECTION, DATE_CONJUNCTION, ACTIVE } from '../../../utils/constants'
import { parseAsMoment } from '../../../utils/date'

import { Row, Col, Accordion, Form, Button } from 'react-bootstrap'

export const LicenceFilter = (props) => {
  // ライセンスのバージョンリスト
  const licenceVersion = useContext(LicenceVersionContext)

  const defaultFilter = {
    'active': true
  }

  const [licenceKey, setLicenceKey] = useState("")
  const [uuid, setUuid] = useState("")
  const [period, setPeriod] = useState("")
  const [maintenancePeriodStartDate, setMaintenancePeriodStartDate] = useState("")
  const [maintenancePeriodEndDate, setMaintenancePeriodEndDate] = useState("")
  const [subscriptionExpiryDateAtStartDate, setSubscriptionExpiryDateAtStartDate] = useState("")
  const [subscriptionExpiryDateAtEndDate, setSubscriptionExpiryDateAtEndDate] = useState("")

  const [updateStartDate, setUpdateStartDate] = useState("")
  const [updateEndDate, setUpdateEndDate] = useState("")
  const [createStartDate, setCreateStartDate] = useState("")
  const [createEndDate, setCreateEndDate] = useState("")

  const [active, setActive] = useState('1')
  const activeList = [
    { name: 'general_unselected', value: '0' },
    { name: 'general_active', value: '1' },
    { name: 'general_inactive', value: '2' },
  ]

  const [bind, setBind] = useState('0')
  const bindList = [
    { name: 'general_unselected', value: '0' },
    { name: 'label_licence_bind_bind', value: '1' },
    { name: 'label_licence_bind_un_bind', value: '2' },
  ]

  const [acsVersionId, setAcsVersionId] = useState('')

  // 認証がない場合
  useEffect(() => {
    if(isUnBind()) setUuid("")
  },[bind])

  useEffect(() => {
    if(isPerpetual){
      setSubscriptionExpiryDateAtStartDate("")
      setSubscriptionExpiryDateAtEndDate("")
    }
  },[period])

  /**
   * 検索条件の変更
   * - 元の状態にかかわらず、変更があったら追加読み込みを封じる
   */
   useEffect(() => {
    props.nextToken(null)
    props.nextRead(false)
  }, [
    licenceKey,
    uuid, 
    period,
    maintenancePeriodStartDate,
    maintenancePeriodEndDate, 
    subscriptionExpiryDateAtStartDate,
    subscriptionExpiryDateAtEndDate,
    updateStartDate,
    updateEndDate,
    createStartDate,
    createEndDate,
    active
  ])

  // select: 購入種別
  const createLicencePeriodSelectItems = () => {
    let items = [];
    items.push(<option key={'flp-def'} value={`0`} >{I18n.get('general_unselected')}</option>);
    Object.keys(LICENCE_PERIOD).map((key,index) => {
      items.push(<option key={'flp-' + index} value={LICENCE_PERIOD[key]} >{const2Msg(LICENCE_PERIOD[key])}</option>);
    });
    return items;
  }

  // select: 認証
  const createAuthSelectItems = () => {
    let items = [];
    bindList.map((key, index) => {
      items.push(<option key={'fls-' + index} value={key.value} >{I18n.get(key.name)}</option>);
    });
    return items;
  }

  // select: バージョン
  const createVersionSelectItems = () => {
    let items = [];
    items.push(<option key={'flv-def'} value={``} >{I18n.get('general_unselected')}</option>);
    
    if(isUndefined(licenceVersion) || licenceVersion.length === 0) return items;

    licenceVersion
      .sort((a,b) => {
        if (a[LICENCE.LABEL.UPDATED_AT] > b[LICENCE.LABEL.UPDATED_AT]) return SORT_DIRECTION.DESC;
        if (a[LICENCE.LABEL.UPDATED_AT] < b[LICENCE.LABEL.UPDATED_AT]) return SORT_DIRECTION.ASC;
        return 0;
      })
      .map((model,index) => {
      items.push(<option key={'flv-' + index} value={model.id} 
        // Activeは選択が可能である。検索なのでdisabledは使わないだろうと思われる
        disabled={!model.active}>
        {model.version}{` `}{`(${parseAsMoment(model.releaseDate).format('YYYY年MM月DD日')})`}
      </option>);
    });
    return items;
  }

  // 検索初期化
  const clear = () => {
    
    setLicenceKey("")
    setBind('0')
    setUuid("")
    setActive('1')
    setPeriod("")
    setMaintenancePeriodStartDate("")
    setMaintenancePeriodEndDate("")
    setSubscriptionExpiryDateAtStartDate("")
    setSubscriptionExpiryDateAtEndDate("")
    setAcsVersionId('')
    setUpdateStartDate("")
    setUpdateEndDate("")
    setCreateStartDate("")
    setCreateEndDate("")

    // デフォルト
    props.filter(defaultFilter)
  }

  // 検索実行
  const executeFilterLicence = () => {
    // 検索項目の整理整頓
    let cFilter = {}

    // active
    switch(active){
      case ACTIVE.ON: cFilter[LICENCE.TABLE_COLUMN.ACTIVE] = true; break;
      case ACTIVE.OFF: cFilter[LICENCE.TABLE_COLUMN.ACTIVE] = false; break;
      case ACTIVE.NONE:
      default: break;
    }

    // licenceKey
    if(isStringType(licenceKey)){
      cFilter['licenceKey'] = manualUpserCase(licenceKey)
    }

    // uuid
    if(isStringType(uuid)){
      cFilter['uuid'] = manualLowerCase(uuid)
    }

    // period
    switch(period){
      case LICENCE_PERIOD.PERPETUAL: cFilter['period'] = LICENCE_PERIOD.PERPETUAL; break;
      case LICENCE_PERIOD.SUBSCRIPTION: cFilter['period'] = LICENCE_PERIOD.SUBSCRIPTION; break;
      default: break;
    }

    // uuid: bind
    if(isStringType(bind)){
      cFilter['bind'] = bind
    }

    // updatedAt: start
    if(isStringType(updateStartDate) && updateStartDate !== ''){
      cFilter[LICENCE.TABLE_COLUMN.UPDATED_AT + 'Gt'] = updateStartDate + DATE_CONJUNCTION.START_SUFFIX
    }

    // updatedAt: end
    if(isStringType(updateEndDate) && updateEndDate !== ''){
      cFilter[LICENCE.TABLE_COLUMN.UPDATED_AT + 'Lt'] = updateEndDate + DATE_CONJUNCTION.END_SUFFIX
    }

    // createdAt: start
    if(isStringType(createStartDate) && createStartDate !== ''){
      cFilter[LICENCE.TABLE_COLUMN.CREATED_AT + 'Gt'] = createStartDate + DATE_CONJUNCTION.START_SUFFIX
    }

    // createdAt: end
    if(isStringType(createEndDate) && createEndDate !== ''){
      cFilter[LICENCE.TABLE_COLUMN.CREATED_AT + 'Lt'] = createEndDate + DATE_CONJUNCTION.END_SUFFIX
    }

    // active
    switch(active){
      case ACTIVE.ON: cFilter[LICENCE.TABLE_COLUMN.ACTIVE] = true; break;
      case ACTIVE.OFF: cFilter[LICENCE.TABLE_COLUMN.ACTIVE] = false; break;
      case ACTIVE.NONE:
      default: break;
    }

    // acsVersionId
    if(isStringType(acsVersionId)){
      cFilter[LICENCE.TABLE_COLUMN.ACS_VERSION_ID] = acsVersionId
    }

    props.filter(cFilter)
  }

  const isUnBind = () => {
    return bind === '2';
  }

  const isPerpetual = () => {
    return period === LICENCE_PERIOD.PERPETUAL
  }

  return (
    <>
      <Row>
        <Col xs={12} md={12} className="mt-1">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header bsPrefix="accordion-button-custom">
                {I18n.get('general_filter')}{` `}
              </Accordion.Header>

              <Accordion.Body>
                <Row className="mt-1">
                  <Col xs={12} md={6}>
                    <Form.Group as={Row} className="mb-3" controlId={`form-filter-licenceKey`}>
                      <Form.Label column sm={4}>
                        {I18n.get('label_licence_key')}
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control 
                          type="text"
                          disabled={props.load}
                          placeholder={`指定なし`} 
                          value={licenceKey}
                          onChange={evt => setLicenceKey(evt.target.value)} />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6} >
                    <Form.Group as={Row} className="mb-3" controlId={`form-filter-period`}>
                      <Form.Label column sm={4}>
                        {I18n.get('label_licence_period')}
                      </Form.Label>
                      <Col sm={8} >
                        <Form.Select 
                          aria-label={`form-filter-period`}
                          disabled={props.load}
                          value={period}
                          onChange={evt => setPeriod(evt.target.value)} >
                          {createLicencePeriodSelectItems()}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mt-1">
                  <Col xs={12} md={6} >
                    <Form.Group as={Row} className="mb-3" controlId={`form-filter-bind`}>
                      <Form.Label column sm={4}>
                        {I18n.get('table_column_label_licence_is_auth')}
                      </Form.Label>
                      <Col sm={8} >
                        <Form.Select 
                          aria-label={`form-filter-bind`}
                          disabled={props.load}
                          value={bind}
                          onChange={evt => setBind(evt.target.value)} >
                          {createAuthSelectItems()}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6} >
                    <Form.Group as={Row} className="mb-3" controlId={`form-filter-uuid`}>
                      <Form.Label column sm={4}>
                        {I18n.get('label_licence_uuid')}
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control 
                          type="text"
                          disabled={props.load || isUnBind()}
                          placeholder={`指定なし`} 
                          value={uuid}
                          onChange={evt => setUuid(evt.target.value)} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mt-1">
                  <Col xs={12} md={12}>
                    <Form.Group as={Row} className="mb-3" controlId={`form-filter-subscription-expiry-date-at`}>
                      <Form.Label column sm={2}>
                        {I18n.get('table_column_licence_subscription_expiry_date_at')}
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control 
                          type="date"
                          disabled={props.load || isPerpetual()}
                          value={subscriptionExpiryDateAtStartDate}
                          onChange={evt => setSubscriptionExpiryDateAtStartDate(evt.target.value)} />
                      </Col>
                      <Form.Label column sm={1} className="text-center">
                        {I18n.get('general_date_between')}
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control 
                          type="date"
                          disabled={props.load || isPerpetual()}
                          value={subscriptionExpiryDateAtEndDate}
                          onChange={evt => setSubscriptionExpiryDateAtEndDate(evt.target.value)} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mt-1">
                  <Col xs={12} md={12}>
                    <Form.Group as={Row} className="mb-3" controlId={`form-filter-maintenance-period-date`}>
                      <Form.Label column sm={2}>
                        {I18n.get('table_column_licence_maintenance_period')}
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control 
                          type="date"
                          disabled={props.load}
                          value={maintenancePeriodStartDate}
                          onChange={evt => setMaintenancePeriodStartDate(evt.target.value)} />
                      </Col>
                      <Form.Label column sm={1} className="text-center">
                        {I18n.get('general_date_between')}
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control 
                          type="date"
                          disabled={props.load}
                          value={maintenancePeriodEndDate}
                          onChange={evt => setMaintenancePeriodEndDate(evt.target.value)} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mt-1">
                  <Col xs={12} md={12}>
                    <Form.Group as={Row} className="mb-3" controlId={`form-filter-update-date`}>
                      <Form.Label column sm={2}>
                        {I18n.get('general_latest_update')}
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control 
                          type="date"
                          disabled={props.load}
                          value={updateStartDate}
                          onChange={evt => setUpdateStartDate(evt.target.value)} />
                      </Col>
                      <Form.Label column sm={1} className="text-center">
                        {I18n.get('general_date_between')}
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control 
                          type="date"
                          disabled={props.load}
                          value={updateEndDate}
                          onChange={evt => setUpdateEndDate(evt.target.value)} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mt-1">
                  <Col xs={12} md={12}>
                    <Form.Group as={Row} className="mb-3" controlId={`form-filter-create-date`}>
                      <Form.Label column sm={2}>
                        {I18n.get('general_create_date')}
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control 
                          type="date"
                          disabled={props.load}
                          value={createStartDate}
                          onChange={evt => setCreateStartDate(evt.target.value)} />
                      </Col>
                      <Form.Label column sm={1} className="text-center">
                        {I18n.get('general_date_between')}
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control 
                          type="date"
                          disabled={props.load}
                          value={createEndDate}
                          onChange={evt => setCreateEndDate(evt.target.value)} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mt-1">
                  <Col xs={12} md={6}>
                    <Form.Group as={Row} className="mb-3" controlId={`form-search-state`}>
                      <Form.Label column sm={4}>
                        {I18n.get('general_status')}
                      </Form.Label>
                      <Col sm={8} >
                        {activeList.map((radio, idx) => (
                          <Form.Check 
                            key={`usf-${idx}`}
                            className="toggleButtonInline"
                            inline
                            disabled={props.load}
                            id={`radio-${idx}`}
                            type="radio" 
                            name="group1"
                            value={radio.value}
                            checked={active === radio.value}
                            label={I18n.get(radio.name)}
                            onChange={evt => setActive(evt.currentTarget.value)} />
                        ))}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6} >
                  </Col>
                </Row>

                <Row className="mt-1">
                  <Col xs={12} md={12}>
                    <div className="d-flex justify-content-end" >
                      <Button
                        variant="secondary" 
                        disabled={props.load}
                        style={{marginRight: '5px'}}
                        onClick={evt => clear()}>
                        {I18n.get('general_clear')}
                      </Button>
                      {' '}
                      <Button
                        variant="primary" 
                        disabled={props.load}
                        onClick={evnt => executeFilterLicence()}>
                        {I18n.get('general_filter')}
                      </Button>
                    </div>
                  </Col>
                </Row>

              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </>
  );
}