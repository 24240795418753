/**
 * Windowの一番上にスクロールする
 */
export const returnTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

/**
 * 引数argがstring型の場合、小文字を大文字に変換して返す
 * @param {*} arg 
 * @returns 
 */
export const manualUpserCase = (arg) => {
  if(typeof arg === "string" || arg instanceof String){
    return arg.toUpperCase();
  }
  return arg;
}

/**
 * 引数argがstring型の場合、大文字を小文字に変換して返す
 * @param {*} arg 
 * @returns 
 */
 export const manualLowerCase = (arg) => {
  if(typeof arg === "string" || arg instanceof String){
    return arg.toLowerCase();
  }
  return arg;
}

/**
 * string型の場合trueを返す
 * @param {*} arg
 * @returns 
 */
export const isStringType = (arg) => {
  return (typeof arg === "string" || arg instanceof String);
}

/**
 * nullの場合trueを返す
 * 
 * @param {*} arg 
 * @returns 
 */
 export const isNull = (arg) => {
  return arg === null
}

/**
 * undefinedの場合trueを返す
 * 
 * @param {*} arg 
 * @returns 
 */
export const isUndefined = (arg) => {
  return (typeof arg === "undefined")
}

/**
 * オブジェクト型引数argの空チェック
 * 
 * @param {*} arg
 * @returns 存在している場合true
 */
export const isObject = (argData) => {
  return Object.keys(argData).length
}