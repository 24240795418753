import React, { useState, useEffect } from 'react'
import { I18n } from "aws-amplify";

import { isStringType } from '../../utils/utils'
import { USER, DATE_CONJUNCTION, ACTIVE } from '../../utils/constants'

import { Row, Col, Accordion, Form, Button } from 'react-bootstrap'

export const UserFilter = (props) => {
  const defaultFilter = {
    'active': true
  }

  const [username, setUsername] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [address, setAddress] = useState("")
  const [telephoneNumber, setTelephoneNumber] = useState("")
  const [mailaddress, setMailaddress] = useState("")

  const [updateStartDate, setUpdateStartDate] = useState("")
  const [updateEndDate, setUpdateEndDate] = useState("")
  const [createStartDate, setCreateStartDate] = useState("")
  const [createEndDate, setCreateEndDate] = useState("")

  const [active, setActive] = useState('1')
  const activeList = [
    { name: 'general_unselected', value: '0' },
    { name: 'general_active', value: '1' },
    { name: 'general_inactive', value: '2' },
  ]

  /**
   * 検索条件の変更
   * - 元の状態にかかわらず、変更があったら追加読み込みを封じる
   */
  useEffect(() => {
    props.nextToken(null)
    props.nextRead(false)
  }, [
    username, 
    companyName, 
    address, 
    telephoneNumber, 
    mailaddress, 
    updateStartDate,
    updateEndDate,
    createStartDate,
    createEndDate,
    active
  ])

  // 検索初期化
  const clear = () => {
    setUsername("")
    setCompanyName("")
    setAddress("")
    setTelephoneNumber("")
    setMailaddress("")
    setActive(ACTIVE.ON)
    setUpdateStartDate("")
    setUpdateEndDate("")
    setCreateStartDate("")
    setCreateEndDate("")

    props.filter(defaultFilter)
  }

  // 検索実行
  const executeFilter = () => {
    // 検索項目の整理整頓
    let cFilter = {}

    // user
    if(isStringType(username)){
      cFilter[USER.TABLE_COLUMN.USERNAME] = username
    }

    // companyName
    if(isStringType(companyName)){
      cFilter[USER.TABLE_COLUMN.COMPANY_NAME] = companyName
    }

    // address
    if(isStringType(address)){
      cFilter[USER.TABLE_COLUMN.ADDRESS] = address
    }

    // telephoneNumber
    if(isStringType(telephoneNumber)){
      cFilter[USER.TABLE_COLUMN.TELEPHONE_NUMBER] = telephoneNumber
    }

    // mailaddress
    if(isStringType(mailaddress)){
      cFilter[USER.TABLE_COLUMN.MAILADDRESS] = mailaddress
    }

    // updatedAt: start
    if(isStringType(updateStartDate) && updateStartDate !== ''){
      cFilter[USER.TABLE_COLUMN.UPDATED_AT + 'Gt'] = updateStartDate + DATE_CONJUNCTION.START_SUFFIX
    }

    // updatedAt: end
    if(isStringType(updateEndDate) && updateEndDate !== ''){
      cFilter[USER.TABLE_COLUMN.UPDATED_AT + 'Lt'] = updateEndDate + DATE_CONJUNCTION.END_SUFFIX
    }

    // createdAt: start
    if(isStringType(createStartDate) && createStartDate !== ''){
      cFilter[USER.TABLE_COLUMN.CREATED_AT + 'Gt'] = createStartDate + DATE_CONJUNCTION.START_SUFFIX
    }

    // createdAt: end
    if(isStringType(createEndDate) && createEndDate !== ''){
      cFilter[USER.TABLE_COLUMN.CREATED_AT + 'Lt'] = createEndDate + DATE_CONJUNCTION.END_SUFFIX
    }

    // active
    switch(active){
      case ACTIVE.ON: cFilter[USER.TABLE_COLUMN.ACTIVE] = true; break;
      case ACTIVE.OFF: cFilter[USER.TABLE_COLUMN.ACTIVE] = false; break;
      case ACTIVE.NONE:
      default: break;
    }
    props.filter(cFilter)
  }

  return (
    <>
      <Row>
        <Col xs={12} md={12} className="mt-1">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header bsPrefix="accordion-button-custom">
                {I18n.get('general_filter')}{` `}
              </Accordion.Header>

              <Accordion.Body>
                <Row className="mt-1">
                  <Col xs={12} md={6}>
                    <Form.Group as={Row} className="mb-3" controlId={`form-filter-username`}>
                      <Form.Label column sm={4}>
                        {I18n.get('table_column_user_username')}
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control 
                          type="text"
                          disabled={props.load}
                          placeholder={I18n.get('general_unselected')} 
                          value={username}
                          onChange={evt => setUsername(evt.target.value)} />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6} >
                    <Form.Group as={Row} className="mb-3" controlId={`form-filter-companyName`}>
                      <Form.Label column sm={4}>
                        {I18n.get('table_column_user_company_name')}
                      </Form.Label>
                      <Col sm={8} >
                        <Form.Control 
                            type="text"
                            disabled={props.load}
                            placeholder={I18n.get('general_unselected')} 
                            value={companyName}
                            onChange={evt => setCompanyName(evt.target.value)} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mt-1">
                  <Col xs={12} md={6}>
                    <Form.Group as={Row} className="mb-3" controlId={`form-filter-mailaddress`}>
                      <Form.Label column sm={4}>
                        {I18n.get('table_column_user_mailaddress')}
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control 
                          type="text"
                          disabled={props.load}
                          placeholder={I18n.get('general_unselected')} 
                          value={mailaddress}
                          onChange={evt => setMailaddress(evt.target.value)} />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6} >
                  </Col>
                </Row>

                <Row className="mt-1">
                  <Col xs={12} md={12}>
                    <Form.Group as={Row} className="mb-3" controlId={`form-filter-update-date`}>
                      <Form.Label column sm={2}>
                        {I18n.get('general_latest_update')}
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control 
                          type="date"
                          disabled={props.load}
                          value={updateStartDate}
                          onChange={evt => setUpdateStartDate(evt.target.value)} />
                      </Col>
                      <Form.Label column sm={1} className="text-center">
                        {I18n.get('general_date_between')}
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control 
                          type="date"
                          disabled={props.load}
                          value={updateEndDate}
                          onChange={evt => setUpdateEndDate(evt.target.value)} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mt-1">
                  <Col xs={12} md={12}>
                    <Form.Group as={Row} className="mb-3" controlId={`form-filter-create-date`}>
                      <Form.Label column sm={2}>
                        {I18n.get('general_create_date')}
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control 
                          type="date"
                          disabled={props.load}
                          value={createStartDate}
                          onChange={evt => setCreateStartDate(evt.target.value)} />
                      </Col>
                      <Form.Label column sm={1} className="text-center">
                        {I18n.get('general_date_between')}
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control 
                          type="date"
                          disabled={props.load}
                          value={createEndDate}
                          onChange={evt => setCreateEndDate(evt.target.value)} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mt-1">
                  <Col xs={12} md={6}>
                    <Form.Group as={Row} className="mb-3" controlId={`form-filter-state`}>
                      <Form.Label column sm={4}>
                      {I18n.get('general_status')}
                      </Form.Label>
                      <Col sm={8}>
                        {activeList.map((radio, idx) => (
                          <Form.Check 
                            key={`usf-${idx}`}
                            className="toggleButtonInline"
                            inline
                            disabled={props.load}
                            id={`radio-state-${idx}`}
                            type="radio" 
                            name="group1"
                            value={radio.value}
                            checked={active === radio.value}
                            label={I18n.get(radio.name)}
                            onChange={evt => setActive(evt.currentTarget.value)} />
                        ))}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6} >
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={12} className="mt-1">
                    <div className="text-end" >
                      <Button
                        variant="secondary" 
                        disabled={props.load}
                        style={{marginRight: '5px'}}
                        onClick={evt => clear()}>
                        {I18n.get('general_clear')}
                      </Button>
                      {' '}
                      <Button
                        variant="primary" 
                        disabled={props.load}
                        onClick={evnt => executeFilter()}>
                        {I18n.get('general_apply')}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </>
  );
}