import { API, graphqlOperation} from 'aws-amplify'
import { listUserByUpdatedAt as Users } from './queries'
import { isUndefined, isStringType } from './../../../utils/utils'

/**
 * SELECT: ユーザリスト取得
 * 
 * @param {*} argId 
 */
export async function getUsers(argOwner, {...argFilter} = {}, argNextToken = null){

  const queryArgument = Object.assign({},{ 
    owner: argOwner,
    filter: {},
    sortDirection: 'DESC',
    nextToken: argNextToken
  })

  let queryFilter = {
    and:[]
  }

  // active
  if(!isUndefined(argFilter.active)){
    queryFilter['and'].push({'active': {
      eq: argFilter.active
    }})
  }

  // username
  if(!isUndefined(argFilter.username)){
    queryFilter['and'].push({'username': {
      contains: argFilter.username
    }})
  }

  // companyName
  if(!isUndefined(argFilter.companyName)){
    queryFilter['and'].push({'companyName': {
      contains: argFilter.companyName
    }})
  }

  // address
  if(!isUndefined(argFilter.address)){
    queryFilter['and'].push({'address': {
      contains: argFilter.address
    }})
  }

  // telephoneNumber
  if(!isUndefined(argFilter.telephoneNumber)){
    queryFilter['and'].push({'telephoneNumber': {
      contains: argFilter.telephoneNumber
    }})
  }

  // mailaddress
  if(!isUndefined(argFilter.mailaddress)){
    queryFilter['and'].push({'mailaddress': {
      contains: argFilter.mailaddress
    }})
  }

  // updatedAt - gt | lt
  if(!isUndefined(argFilter.updatedAtGt) || !isUndefined(argFilter.updatedAtLt)){
    let qsu = {}
    if(!isUndefined(argFilter.updatedAtGt)) qsu['gt'] = argFilter.updatedAtGt
    if(!isUndefined(argFilter.updatedAtLt)) qsu['lt'] = argFilter.updatedAtLt
    // ModelStringKeyConditionInputで指定しているため、filterではなくArgumentで指定する
    queryArgument['updatedAt'] = qsu
  }

  // createdAt - gt | lt
  if(!isUndefined(argFilter.createdAtGt) || !isUndefined(argFilter.createdAtLt)){
    let qsc = {}
    if(!isUndefined(argFilter.createdAtGt)) qsc['gt'] = argFilter.createdAtGt
    if(!isUndefined(argFilter.createdAtLt)) qsc['lt'] = argFilter.createdAtLt
    queryFilter['and'].push({'createdAt': qsc})
  }

  // filter条件
  if(queryFilter['and'].length === 0) queryFilter = null

  queryArgument['filter'] = queryFilter

  return API.graphql(
    graphqlOperation(Users, queryArgument)
  )
}