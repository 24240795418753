// ユーザ情報のみを取得（紐付いているライセンス情報なし）
export const getSimpleUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      owner
      username
      mailaddress
      companyName
      active
      corp
      updatedAt
      createdAt
    }
  }
`;