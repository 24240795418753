import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { I18n } from 'aws-amplify'
import { deleteSuccessLicence as ToastDeleteSuccess, 
  deleteErrorLicence as ToastDeleteError} from '../utils/toast';

import { Button, Spinner, Modal, Form, Alert } from 'react-bootstrap'

import { 
  getListLicenceBind, 
  logicalDeleteLicenceBind, 
  logicalDeleteLicence } from './api/DeleteLicence'

import { const2Msg, ConstantEquals, LICENCE_TYPE } from './../utils/constants'
import { isNull, isUndefined } from './../utils/utils'

export const Delete = (props) => {
  const history = useHistory();

  // 入力値
  const CONFIRM_DELETE_WORD = "delete";

  const [show, setShow] = useState(false)
  const [load, setLoad] = useState(false)
  const [allow, setAllow] = useState(true)

  // data以外初期化
  const init = ()=>{
    setShow(false)
    setLoad(false)
    setAllow(true)
  }

  /**
   * モーダルを閉じる
   * 
   * @param {*} argShow 
   * @returns 
   */
  const close = (argShow) => {
    if(load) return;
    setShow(argShow)
    setAllow(true)
  }

  // 更新： アクティブ
  const onClickDeleteLicence = async (event) => {
    setLoad(true)

    // 段階的に削除していく
    // Note: これこそリゾルバーでやるべき
    try {
      // 認証履歴を得る
      const resBinds = await getListLicenceBind(props.licenceId)
      const parseLicenceBindIds = parseLicenceBinds(resBinds.data.LicenceBindByLicenceId.items)
      // BindsをすべてUpdate
      let bindUpdatePromises = []
      for (let i = 0; i < parseLicenceBindIds.licenceBindIds.length; i++) {
        bindUpdatePromises.push(logicalDeleteLicenceBind(parseLicenceBindIds.licenceBindIds[i]))
      }
      await Promise.all(bindUpdatePromises);
      // ラインセスをすべてUpdate
      await logicalDeleteLicence(props.licenceId)

      // 結果の画面反映
      resultSuffix()

    } 
    catch (error) {
      console.log(error)
      ToastDeleteError()
      init()
    }

  }

  // パース：ライセンス認証履歴
  const parseLicenceBinds = (argLicenceBindResponses) =>{

    const parseData = {
      done: false,
      licenceBindIds: []
    };

    // No Dataの場合
    if(argLicenceBindResponses.length === 0){
      parseData.done = true;
      return parseData;
    }

    parseData.licenceBindIds = argLicenceBindResponses.map((val) => {
      return val.id
    })

    // parseData.licenceBindIds = argLicenceBindResponses.flatMap((val) => {
    //   if((val.data.LicenceBindByLicenceId.items).length === 0) return;
    //   return (val.data.LicenceBindByLicenceId.items).map((val) => {
    //     return val.id
    //   })
    // }).filter((val) => {
    //   return !isUndefined(val)
    // })

    parseData.done = true;
    return parseData;
  }

  // 更新後処理
  const resultSuffix = () => {
    // Toastを出して
    ToastDeleteSuccess()

    // ダッシュボード画面に遷移
    history.push(({ 
      pathname:  '/user/' + props.userId
    }))
  }

  // ボタンの有効化
  const confirmInputChange = (arg) =>{
    setAllow(arg === CONFIRM_DELETE_WORD ? false : true)
  }

  return (
    <>
      <Button variant="danger" onClick={() => close(true)}>
        {I18n.get('sub_title_licence_delete')}
      </Button>

      <LicenceDeleteModal
        show={show}
        allow={allow}
        load={load}
        word={CONFIRM_DELETE_WORD}
        onClick={() => onClickDeleteLicence()}
        onHide={() => close(false)}
        onChange={(argWord) => confirmInputChange(argWord)}
      />

    </>
  )
}

/**
 * モーダル： ユーザ削除
 * @param {*} props 
 * @returns 
 */
const LicenceDeleteModal = (props) => {

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {I18n.get('sub_title_licence_delete')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {`${I18n.get('general_confirm_prefix')}
              ${props.word}
              ${I18n.get('general_confirm_suffix')}`}
          </p>
          <Form.Group className="mb-3" controlId="formConfirm">
            <Form.Control 
            type="text" 
            disabled={props.load}
            placeholder={`Enter ${props.word}`}
            onChange={(evt) => props.onChange(evt.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <>
            {
              !props.load ? ( 
                <>
                <Button variant="danger" 
                  onClick={props.onClick} 
                  disabled={props.allow}
                  >
                  {I18n.get('form_licence_delete')}
                </Button>
                {' '}
                <Button variant="secondary" onClick={props.onHide}>
                  {I18n.get('general_cancel')}
                </Button>
                </>
              ) : (
                <>
                  <Button variant="danger" disabled>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true" />{' '}
                    {I18n.get('form_licence_delete')}
                  </Button>
                  {' '}
                  <Button variant="secondary" disabled>
                    {I18n.get('general_cancel')}
                  </Button>
                </>
              )
            }
          </>
        </Modal.Footer>
      </Modal>
    </>
  );
}