import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { I18n } from 'aws-amplify'

import { Container, Row, Col, Breadcrumb, Spinner } from 'react-bootstrap'
import { Header, Footer } from '../Panels'

import { getSimpleUser } from './api/User'

import { ConstantEquals, USER, LICENCE_LIMIT, LICENCE_TYPE } from '../utils/constants'
import { ValidMessage, ValidUsername } from './Validate'

import { UserUpdatedAtContext } from './context/UserUpdatedAt'
import { LicenceLimitContext } from './context/LicenceLimit'
import { LicenceNumContext } from './context/LicenceNum'

import { Username } from './Username'
import { UpdatedAt } from './UpdatedAt'
import { Active } from './Active'
import { Corp } from './Corp'
import { Delete as UserDelete } from './Delete'
import { Detail as UserDetail } from './Detail'
import { LicenceLimit } from './components/LicenceLimit/LicenceLimit'
import { Licence } from './components/Licence/Licence'

/**
 * UserページのReact Hook版
 */
const UserDashboard = props => {
  const udRef = useRef()

  // 更新中
  const [userLoad, setUserLoad] = useState(false)

  // ユーザ詳細エラー
  const [uesrErrors, setUserErrors] = useState({})
  // ユーザ名
  const [username, setUsername] = useState(null)
  // アクティブ
  const [userActive, setUserActive] = useState(false)
  // 拡張機能
  const [userCorp, setUserCorp] = useState(null)

  // 更新日
  const [userUpdatedAt, setUserUpdatedAt] = useState(null)
  // ライセンス上限
  const [currentLicenceLimit, setCurrentLicenceLimit] = useState({
    use: 0
  })
  // ライセンスの数
  // - ライセンス上限を変更する際、この数を下回らないようにする
  const [currentLicence, setCurrentLicence] = useState({
    use: 0 
  })

  // 初回マウント時
  useEffect(() => {
    // Stateのメモリリーク対策
    let isMounted = true;
    // 取得
    // Note: 3つここで取得するか後で取得するか悩みどころさんではある
    (async() => {

      try{

        const fetchUserDetail = await getSimpleUser(props.match.params.id)

        // 取得した情報をparseする
        const parseData = parse(fetchUserDetail.data.getUser)

        if(!parseData.done){
          if (isMounted){
            setUserLoad(false)
            setUsername({});
            setUserCorp(null)
          }
          return;
        }

        // 描画
        if (isMounted){
          setUsername(parseData.username)
          setUserActive(parseData.active)
          setUserUpdatedAt(parseData.updatedAt)
          setUserCorp(parseData.corp)
          setUserErrors(parseData.errors)
          setUserLoad(true)
        }
  
      }
      catch(err){
        console.log(err)
      }

    })()
    return () => { 
      // マウントされていない場合はフラグをクリーンにする（setStateさせない）
      isMounted = false 
    };
  }, []);

  // Note: 確認用
  useEffect(() => {
    // console.log(currentLicenceLimit)
  }, [currentLicenceLimit])

  // Note: 確認用
  useEffect(() => {
    // console.log(currentLicence)
  }, [currentLicence])

  // パース：取得したユーザ情報
  const parse = (argUser) =>{

    const parseData = {
      done: false,
      errors: {},
      updatedAt: "",
      username: null,
      active: true,
      corp: null
    };

    // No Dataの場合
    if(argUser.length === 0){
      parseData.done = true;
      return parseData;
    }

    Object.entries(argUser).map(([key, value]) => {
      // ユーザ名
      if(ConstantEquals(key, USER.LABEL.USERNAME)){
        const vUsername = ValidUsername(value)
        if (!ConstantEquals(vUsername, USER.VALIDATE.CLEAR) ) {
          parseData.errors[USER.LABEL.USERNAME] = ValidMessage(vUsername);
        }else{
          parseData.username = argUser.username;
        }
      }

      // アクティブ
      if(ConstantEquals(key, USER.LABEL.ACTIVE)){
        parseData.active = argUser.active;
      }

      // 拡張機能
      if(ConstantEquals(key, USER.LABEL.CORP)){
        parseData.corp = argUser.corp;
      }

      // 更新日
      if(ConstantEquals(key, USER.LABEL.UPDATED_AT)){
        parseData.updatedAt = argUser.updatedAt;
      }

    })

    parseData.done = true;
    return parseData;
  }

  /**
   * Activeバッジの描画
   * @param {*} arg boolean
   */
  const applyUserActive = (arg) => {
    setUserActive(arg)
  }

  /**
   * 更新日の描画
   * @param {*} arg YYYY-MM-DDTHH:mm:00Z
   */
  const applyUpdatedAt = (arg) => {
    // Todo: validateするべき

    setUserUpdatedAt(arg)
  }

  /**
   * ライセンス上限数の更新
   * 
   * @param {*} argObj 
   */
  const applyLicenceLimitNum = (argObj) => {
    setCurrentLicenceLimit(argObj)
  }

  /**
   * ライセンス上限数の更新
   * 
   * @param {*} argObj 
   */
   const applyLicenceNum = (argObj) => {
    setCurrentLicence(argObj)
  }

  return (
    <>
    <Header />
    <Container>
      <Row>
        <Col xs={12} md={12} className="mt-1">
          <Breadcrumb >
            <Breadcrumb.Item active>
              <Link to="/dashboard">
                {I18n.get('title_dashboard')}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {I18n.get('title_user_detail')}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
    </Container>
    {
      !userLoad ? (
        <Container>
          <Row>
            <Col xs={12} md={12} className="mt-1 text-center">
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true" />
            </Col>
          </Row>
        </Container>
      ) : (
        <>

          <UserUpdatedAtContext.Provider value={userUpdatedAt}>
            <Container>
              <Username 
                userId={props.match.params.id}
                username={username} 
                doneUpdate={applyUpdatedAt}
                />
              <Row className="mt-1">
                <Col xs={12} md={6} >
                  {
                    userActive ? (
                      <span className="badge bg-success">{I18n.get('general_active')}</span>
                    ) : (
                      <span className="badge bg-danger">{I18n.get('general_inactive')}</span>
                    )
                  }{' '}
                  <UpdatedAt updatedAt={userUpdatedAt} />
                </Col>
                <Col xs={12} md={6} >
                  <div className="text-end" >
                    <Active 
                      userId={props.match.params.id}
                      active={userActive}
                      doneUpdateActive={applyUserActive}
                      doneUpdatedAt={applyUpdatedAt} />
                    {` `}
                    <UserDelete 
                      userId={props.match.params.id} />
                  </div>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col xs={12} md={8}className="mt-1">
                  <UserDetail
                    userId={props.match.params.id}
                    doneUpdate={applyUpdatedAt}
                  />
                </Col>

                <Col xs={12} md={4}>
                  <Row>
                    <Col xs={12} md={12} className="mt-1">
                      {/* 
                        Todo: Contextのvalueが書き換わった場合、context以下が全て再描画される
                        useMemoに置き換えたほうがパフォーマンスはあがる。memoで囲う内容がLimitになるが...
                        参考: https://qiita.com/soarflat/items/b154adc768bb2d71af21
                        参考2: https://qiita.com/soarflat/items/b9d3d17b8ab1f5dbfed2     
                      */}
                      <LicenceNumContext.Provider value={currentLicence}>
                        <LicenceLimit 
                          userId={props.match.params.id}
                          applyLicenceLimitNum={applyLicenceLimitNum}
                          doneUpdatedAt={applyUpdatedAt} />
                      </LicenceNumContext.Provider>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12} className="mt-3">
                      <Corp 
                        corp={userCorp} />
                    </Col>
                  </Row>
                </Col> 

              </Row>
              <hr />

              <LicenceLimitContext.Provider value={currentLicenceLimit}>
                <Licence 
                  userId={props.match.params.id}
                  applyLicenceNum={applyLicenceNum}
                  doneUpdatedAt={applyUpdatedAt} />
              </LicenceLimitContext.Provider>
            </Container>
          </UserUpdatedAtContext.Provider>
        </>
      )
    }
    <Footer />
    </>
  );
}

export default UserDashboard