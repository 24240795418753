import React, { useState, useEffect, useContext } from 'react'
import { I18n } from "aws-amplify";

import { Row, Col, Card, Form, Placeholder } from 'react-bootstrap'
import { PencilSquare, XLg } from 'react-bootstrap-icons';
import './licence.css';

import { putSuccessLicence as ToastPutSuccess, putErrorLicence as ToastPutError} from './../utils/toast';

import { ConstantEquals, LICENCE, LICENCE_PERIOD } from './../utils/constants'
import { getDateNextY } from './../utils/date'
import { isObject, isUndefined } from './../utils/utils'
import { ValidMessage, ValidLicenceType, ValidLicencePeriod, ValidSubscriptionExpiryDateAt, ValidAcsVersionId, ValidMaintenancePeriod } from './utils/Validate'
import { putLicence } from './api/Licence'

import { LicenceKey } from './form/LicenceKey'
import { LicenceType as LicenceTypeForm } from './form/LicenceType'
import { LicencePeriod as LicencePeriodForm } from './form/LicencePeriod'
import { SubscriptionExpiryDateAt } from './form/SubscriptionExpiryDateAt'
import { LicenceVersion as LicenceVersionForm } from './form/LicenceVersion'
import { MaintenancePeriod } from './form/MaintenancePeriod'
import { SubmitButton } from './form/SubmitButton'

export const Detail = (props) => {

  const defaultDate = getDateNextY(1)

  const minDate = getDateNextY(0)
  const maxDate = getDateNextY(5)

  // 編集モード
  const [edit, setEdit] = useState(false)
  // 更新中
  const [load, setLoad] = useState(false)
  // ライセンス情報
  const [data, setData] = useState({})
  // 入力：変更
  const [input, setInput] = useState({})
  // 入力：変更検知
  const [change, setChange] = useState(false)
  // 入力：エラー
  const [errors, setErrors] = useState({})
  // 選択されている購入種別（表示切替のみ）
  const [perpetual, setPerpetual] = useState(true)

  // 初回マウント時
  useEffect(() => {
    // Stateのメモリリーク対策
    let isMounted = true;

    // 取得した情報をparseする
    const parseData = parseLicence(props.data)

    if(!parseData.done){
      if (isMounted) setData({});
      return;
    }

    // 描画
    if (isMounted){

      // 『買い切り』の場合、subscriptionExpiryDateAtにmaintenancePeriodの値を初期値として設定する
      if(ConstantEquals(LICENCE_PERIOD.PERPETUAL, parseData.licence.period))
        parseData.licence.subscriptionExpiryDateAt = parseData.licence.maintenancePeriod

      setData(parseData.licence)
      setErrors(parseData.errors)
    }

    return () => { 
      isMounted = false 
    };
  }, []);

  useEffect(() => {
    setPerpetual(ConstantEquals(LICENCE_PERIOD.PERPETUAL, data.period))
  }, [data]);

  // data以外初期化
  const init = ()=>{
    setEdit(false)
    setLoad(false)
    setInput({})
    setChange(false)
    setErrors({})
  }

  // パース：取得データ
  const parseLicence = (argLicenceData) => {
    const parseData = {
      done: false,
      errors: {},
      licence:{
        active: false,
        licenceKey: null,
        period: null,
        subscriptionExpiryDateAt: null,
        type: null,
        updatedAt: null,
        uuid: null,
        acsVersionId: null,
        acsVersion: {}
      }
    };

    const resValid = inputValidate(argLicenceData);
    if(!resValid.isValid){
      parseData.errors = resValid.errors;
      return parseData;
    }

    // parseする
    parseData.licence = {
      active: argLicenceData.active,
      licenceKey: argLicenceData.licenceKey,
      period: argLicenceData.period,
      subscriptionExpiryDateAt: argLicenceData.subscriptionExpiryDateAt,
      maintenancePeriod: argLicenceData.maintenancePeriod,
      type: argLicenceData.type,
      updatedAt: argLicenceData.updatedAt,
      uuid: argLicenceData.uuid,
      acsVersionId: argLicenceData.acsVersionId,
      acsVersion: argLicenceData.acsVersion
    }

    // 異常なし
    parseData.done = true;
    return parseData;
  }

  // 入力変更
  const inputChange = (key, value) => {
    let cInput = input
    cInput[key] = value

    // エラーの一時解除
    let cErrors = errors
    if(typeof cErrors[key] !== "undefined"){
      delete cErrors[key]
    }

    setInput(cInput)
    setErrors(cErrors)
    setChange(true)
  }

  // 入力変更：購入種別
  const inputChangeByPeriod = (value) => {
    setPerpetual(ConstantEquals(LICENCE_PERIOD.PERPETUAL, value))

    let cInput = input
    cInput[LICENCE.LABEL.PERIOD] = value

    // 継続購入 -> 買い切りの場合、有効期限を空にする
    if(ConstantEquals(LICENCE_PERIOD.PERPETUAL, value)){
      cInput[LICENCE.LABEL.MAINTENANCE_PERIOD] = data.subscriptionExpiryDateAt
      cInput[LICENCE.LABEL.SUBSCRIPTION_EXPIRY_DATE_AT] = ""
     }
    // 買い切り -> 継続購入の場合、有効期限に保守期限を設定する
    else if(ConstantEquals(LICENCE_PERIOD.SUBSCRIPTION, value)){
      cInput[LICENCE.LABEL.SUBSCRIPTION_EXPIRY_DATE_AT] = data.maintenancePeriod
    }

    // エラーの一時解除
    let cErrors = errors
    if(typeof cErrors[LICENCE.LABEL.PERIOD] !== "undefined")
      delete cErrors[LICENCE.LABEL.PERIOD]
    if(typeof cErrors[LICENCE.LABEL.MAINTENANCE_PERIOD] !== "undefined")
      delete cErrors[LICENCE.LABEL.MAINTENANCE_PERIOD]
    if(typeof cErrors[LICENCE.LABEL.SUBSCRIPTION_EXPIRY_DATE_AT] !== "undefined")
      delete cErrors[LICENCE.LABEL.SUBSCRIPTION_EXPIRY_DATE_AT]

    setInput(cInput)
    setErrors(cErrors)
    setChange(true)
  }

  // 更新
  const onSubmitClick = (event) => {

    // formデフォルトの挙動をキャンセル
    event.preventDefault();
    // イベントの伝播を止める
    event.stopPropagation();

    if(!Object.keys(input).length){
      return;
    }

    const valid = inputValidate(input)
    if(!valid.isValid){
      setErrors(valid.errors)
      return;
    }

    let param = JSON.parse(JSON.stringify(input))
    param['id'] = props.licenceId

    // 更新する
    changeLicence(param)
  }

  // 取得後と変更内容の整合性確認
  const validateLicenceData = (arg) => {

    let cInput = arg;

    const res = {
      isValid: true, 
      errors: {}
    }

    if(isUndefined(arg)) return res;

    // ライセンス種別
    const vLicenceType = ValidLicenceType(cInput.type)
    if (!isUndefined(cInput.type) && !ConstantEquals(vLicenceType, LICENCE.VALIDATE.CLEAR) ) {
      res.isValid = false;
      res.errors["type"] = ValidMessage(vLicenceType);
    }

    // ライセンス購入種別
    const vLicencePeriod = ValidLicencePeriod(cInput.period)
    if (!isUndefined(cInput.period) && !ConstantEquals(vLicencePeriod, LICENCE.VALIDATE.CLEAR) ) {
      res.isValid = false;
      res.errors["period"] = ValidMessage(vLicencePeriod);
    }

    // ライセンス期限
    const vSubscriptionExpiryDateAt = ValidSubscriptionExpiryDateAt(cInput.subscriptionExpiryDateAt, cInput.period)
    if (!isUndefined(cInput.subscriptionExpiryDateAt) && !ConstantEquals(vSubscriptionExpiryDateAt, LICENCE.VALIDATE.CLEAR) ) {
      res.isValid = false;
      res.errors["subscriptionExpiryDateAt"] = ValidMessage(vSubscriptionExpiryDateAt);
    }

    // 保守期限
    const vMaintenancePeriod = ValidMaintenancePeriod(cInput.maintenancePeriod, cInput.period, cInput.subscriptionExpiryDateAt)
    if (!isUndefined(cInput.maintenancePeriod) && !ConstantEquals(vMaintenancePeriod, LICENCE.VALIDATE.CLEAR) ) {
      res.isValid = false;
      res.errors["maintenancePeriod"] = ValidMessage(vMaintenancePeriod);
    }

    // ACSバージョン
    const vAcsVersionId = ValidAcsVersionId(cInput.acsVersionId)
    if (!ConstantEquals(vAcsVersionId, LICENCE.VALIDATE.CLEAR) ) {
      res.isValid = false;
      res.errors[LICENCE.LABEL.ACS_VERSION_ID] = ValidMessage(vAcsVersionId);
    }

    return res;
  }

  // 入力確認：ライセンス詳細
  const inputValidate = (argLicence) =>{

    let cInput = argLicence;

    const res = {
      isValid: true,
      errors: {}
    };

    if(isUndefined(cInput)) return res;

    // ライセンス種別
    const vLicenceType = ValidLicenceType(cInput.type)
    if (!isUndefined(cInput.type) && !ConstantEquals(vLicenceType, LICENCE.VALIDATE.CLEAR) ) {
      res.isValid = false;
      res.errors[LICENCE.LABEL.TYPE] = ValidMessage(vLicenceType);
    }

    // ライセンス購入種別
    const vLicencePeriod = ValidLicencePeriod(cInput.period)
    if (!isUndefined(cInput.period) && !ConstantEquals(vLicencePeriod, LICENCE.VALIDATE.CLEAR) ) {
      res.isValid = false;
      res.errors[LICENCE.LABEL.PERIOD] = ValidMessage(vLicencePeriod);
    }

    // 有効期限
    const vSubscriptionExpiryDateAt = ValidSubscriptionExpiryDateAt(cInput.subscriptionExpiryDateAt, cInput.period)
    if (!isUndefined(cInput.subscriptionExpiryDateAt) && !ConstantEquals(vSubscriptionExpiryDateAt, LICENCE.VALIDATE.CLEAR) ) {
      res.isValid = false;
      res.errors[LICENCE.LABEL.SUBSCRIPTION_EXPIRY_DATE_AT] = ValidMessage(vSubscriptionExpiryDateAt);
    }

    // 保守期限
    const vMaintenancePeriod = ValidMaintenancePeriod(cInput.maintenancePeriod, cInput.period, cInput.subscriptionExpiryDateAt)
    if (!isUndefined(cInput.maintenancePeriod) && !ConstantEquals(vMaintenancePeriod, LICENCE.VALIDATE.CLEAR) ) {
      res.isValid = false;
      res.errors[LICENCE.LABEL.MAINTENANCE_PERIOD] = ValidMessage(vMaintenancePeriod);
    }

    return res;
  }

  // Put：ライセンス詳細
  const changeLicence = async (argParam) => {
    setLoad(true)

    try {
      // 一括登録と更新
      const updateLicenceDetail = await putLicence(argParam);
      // 結果の画面反映
      changeSuffix(updateLicenceDetail)
    } 
    catch (error) {
      ToastPutError()
      init()
    }

    return;
  }

  // 追加・更新後処理
  const changeSuffix = (argRes) => {
    const parseData = parseLicence(argRes.data.updateLicence)
    if(!parseData.done){
      ToastPutError()
      init()
      return;
    }

    // 『買い切り』の場合、subscriptionExpiryDateAtにmaintenancePeriodの値を初期値として設定する
    if(ConstantEquals(LICENCE_PERIOD.PERPETUAL, parseData.licence.period))
      parseData.licence.subscriptionExpiryDateAt = parseData.licence.maintenancePeriod

    setData(parseData.licence)
    ToastPutSuccess()
    // 初期状態にする
    init()

    // 親コンポーネントに最終更新日の更新値を通知する
    props.doneUpdate(parseData.licence.updatedAt)
  }

  // 編集モードの切り替え
  const toggle = (arg) => {
    setPerpetual(ConstantEquals(LICENCE_PERIOD.PERPETUAL, data.period))
    setInput({})
    setChange(false)
    setErrors({})
    setEdit(arg)
  }

  return (
    <>
      <Card>
        <Card.Header>
          <Row>
            <Col sm={12} md={8}>
            { I18n.get( edit ? 
              'sub_title_licence_information_change' : 
              'sub_title_licence_information' )}
            </Col>
            <Col sm={12} md={4} className="d-flex align-items-center justify-content-end">
            { edit ? ( 
              <XLg style={{cursor: 'pointer'}} color="gray" size={16} onClick={evt => toggle(false)} />
            ):( 
              <PencilSquare style={{cursor: 'pointer'}} color="gray" size={16} onClick={evt => toggle(true)} />
            )}
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Form noValidate onSubmit={onSubmitClick}>

            { isObject(data) ? (
              <LicenceKey 
                edit={false}
                required
                value={data.licenceKey} />
            ) : (
              <Placeholder as="h3" animation="glow">
                <Placeholder.Button xs={12} md={12} variant="secondary" />
              </Placeholder>
            )}

            { isObject(data) ? (
              <LicencePeriodForm 
                  edit={edit}
                  load={load}
                  help=""
                  required
                  controlKey={LICENCE.LABEL.PERIOD}
                  label={I18n.get('label_licence_period')}
                  value={data.period}
                  error={errors[LICENCE.LABEL.PERIOD]}
                  onChange={evt => inputChangeByPeriod(evt.target.value)} /> 
            ) : (
              <Placeholder as="h3" animation="glow">
                <Placeholder.Button xs={12} md={12} variant="secondary" />
              </Placeholder>
            )}

            { perpetual ? (
              <></>
            ) : (
              isObject(data) ? (
                <SubscriptionExpiryDateAt 
                  edit={edit}
                  label={`${I18n.get('label_licence_subscription_expiry_date_at')}/${I18n.get('label_licence_maintenance_period')}`}
                  input={data.subscriptionExpiryDateAt} 
                  error={errors[LICENCE.LABEL.SUBSCRIPTION_EXPIRY_DATE_AT]} 
                  load={load}
                  onChange={(value) => {
                    inputChange(LICENCE.LABEL.SUBSCRIPTION_EXPIRY_DATE_AT, value)
                    inputChange(LICENCE.LABEL.MAINTENANCE_PERIOD, value)
                  }}
                  minDate={minDate}
                  maxDate={maxDate} />
              ) : (
                <Placeholder as="h3" animation="glow">
                  <Placeholder.Button xs={12} md={12} variant="secondary" />
                </Placeholder>
              )
            )}

            { !perpetual ? (
              <></>
            ) : (
              <MaintenancePeriod
                edit={edit}
                load={load}
                label={I18n.get('label_licence_maintenance_period')}
                required
                input={data.maintenancePeriod} 
                error={errors.maintenancePeriod} 
                onChange={(value) => inputChange(LICENCE.LABEL.MAINTENANCE_PERIOD, value)} 
                minDate={minDate}
                maxDate={maxDate} />
            )}

            { edit && (
              <div className="d-flex justify-content-end" >
                <SubmitButton isLoading={load} disabled={!change} />
              </div>
            )}
          </Form>
        </Card.Body>
      </Card>
    </>
  )

}