import { API, graphqlOperation} from 'aws-amplify'
import { getLicence as Licence, listAcsVersions } from './../../../graphql/queries'
import { getLicenceSimple as LicenceSimple, listLicenceByUpdatedAtSimple as Licences } from './queries'
import { createLicence, updateLicence } from './../../../graphql/mutations'
import { updateLicenceActive } from './mutations'
import { isUndefined, isStringType } from './../../utils/utils'

/**
 * Update: ライセンス更新
 * 
 * @param {*} argInput 
 * @returns 
 */
export async function putLicence(argInput) {
  return API.graphql(
    graphqlOperation(updateLicence,
      { 
          input: argInput
      })
  )
}

/**
 * get: ACSバージョン（保守期間）
 */
export async function getListForAcsVersion(){
  return API.graphql(graphqlOperation(listAcsVersions))
}

/**
 * Update: ライセンス認証解除
 * 
 * @param {*} argInput 
 * @returns 
 */
 export async function putLicenceBind(argInput) {
  return API.graphql(
    graphqlOperation(updateLicence,
      { 
          input: argInput
      })
  )
}

/**
 * SELECT: ライセンス取得
 * 
 * @param {*} argId 
 */
export async function getLicence(argId){
  return API.graphql(
    graphqlOperation(Licence, { 
      id: argId
    })
  )
}

/**
 * SELECT: ライセンス取得
 * - 認証履歴を除く
 * 
 * @param {*} argId 
 */
export async function getLicenceSimple(argId){
  return API.graphql(
    graphqlOperation(LicenceSimple, { 
      id: argId
    })
  )
}

/**
 * Update: ライセンスアクティブ
 * 
 * @param {*} argInput
 */
 export const putLicenceActive = async(argInput) =>{
  return API.graphql(
    graphqlOperation(updateLicenceActive, { 
        input: argInput
  }))
}