import React from 'react'

import { parseAsMoment } from './../utils/date'

export const UpdatedAt = (props) => {

  return (
      !props.licenceUpdatedAt ? (
        <>Loading...</>
      ) : (
        <>
          <span>最終更新日：</span>
          <span>{parseAsMoment(props.licenceUpdatedAt).format('YYYY年MM月DD日 HH:mm')}</span>
        </>
      )

  );
}