import { API, graphqlOperation} from 'aws-amplify'
import { listLicenceLimits as ListLimits } from '../../../../../graphql/queries'
import { createLicenceLimit, updateLicenceLimit } from '../../../../../graphql/mutations'

/**
 * SELECT: ライセンス管理数取得
 * 
 * @param {*} argUserId 
 */
export async function listLicenceLimit(argUserId){
  let filter = {
    userId: {eq: argUserId}
  };
  return API.graphql({
      query: ListLimits, 
      variables: { filter: filter }
  })
}

/**
 * Insert: ライセンス管理数
 * 
 * @param {*} argId 
 * @param {*} argNum 
 */
export async function postLicenceLimit(argUserId, argType, argNum){
  return API.graphql(
    graphqlOperation(createLicenceLimit, { 
        input: {
          'userId': argUserId,
          'type': argType,
          'num': argNum,
          'active': true
        }
  }))
}

/**
 * Update: ライセンス管理数
 * 
 * @param {*} argId 
 * @param {*} argNum 
 */
export async function putLicenceLimit(argId, argNum){
  return API.graphql(
    graphqlOperation(updateLicenceLimit, { 
        input: {
          'id': argId,
          'num': argNum
        }
  }))
}